export const mloc709 = {
  id: 'MLOC-709',
  name: 'Cardiovascular Institute of the South - Gray',
  description:
    'Cardiovascular Institute Of The South Meridian is a group practice with 1 location. Currently, Cardiovascular Institute Of The South Meridian specializes in Cardiovascular Disease, Internal Medicine and Nurse Practitioner with 3 physicians.',
  status: '07-Event Ready (ES)',
  address1: '191 Innovation Court',
  address2: 'Suite B',
  city: 'Gray',
  state: 'LA',
  zip: '70359',
  category: 'Commercial',
  type: 'Office Based Lab',
  latitude: '29.67925',
  longitude: '-90.77781',
  photo: 'mloc-709.png',
  speciality: 'Orthopedics',
}
