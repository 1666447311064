export default function StickyMenu(selector = '', scrollPoint = 100) {
  document.addEventListener('scroll', () => {
    const element = document.querySelector(selector)
    if (element) {
      if (
        document.body.scrollTop > scrollPoint ||
        document.documentElement.scrollTop > scrollPoint
      ) {
        element.classList.add('fixed-header')
      } else {
        element.classList.remove('fixed-header')
      }
    }
  })
}
