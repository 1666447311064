import React from 'react'
import styles from './FeaturedSection.module.scss'

const FeaturedSection = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>Featured On</div>
      <div className={styles.seenOuter}>
        <div className={styles.logoOuter}>
          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/1.png" alt="Picture of the logo1" />
            </a>
          </div>
          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/2.png" alt="Picture of the logo1" />
            </a>
          </div>

          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/3.png" alt="Picture of the logo1" />
            </a>
          </div>

          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/4.png" alt="Picture of the logo1" />
            </a>
          </div>

          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/5.png" alt="Picture of the logo1" />
            </a>
          </div>
          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/6.png" alt="Picture of the logo1" />
            </a>
          </div>
          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/7.png" alt="Picture of the logo1" />
            </a>
          </div>
          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/8.png" alt="Picture of the logo1" />
            </a>
          </div>

          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/1.png" alt="Picture of the logo1" />
            </a>
          </div>
          <div className={styles.imgSeenOuter}>
            <a href="" target="_blank">
              <img src="/FeaturedOn/2.png" alt="Picture of the logo1" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedSection
