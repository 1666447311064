export const mloc1931 = {
  id: 'MLOC-1931',
  name: 'Legent Interventional Pain Center',
  description:
    'Legent Interventional Pain Center (LEGENT OUTPATIENT SURGERY EULESS, LLC) is a Ambulatory Surgery Center in Euless, Texas. Ambulatory surgery centers (or outpatient surgery centers) are health care facilities where surgical procedures not requiring an overnight hospital stay are performed.',
  status: '07-Event Ready (ES)',
  address1: '461 Westpark Way',
  address2: null,
  city: 'Euless',
  state: 'TX',
  zip: '76040',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '32.83189',
  longitude: '-97.11947',
  photo: 'mloc-1931.png',
  speciality: 'Spine',
}
