import { specialties } from './../../../utils/specialty'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'

const data = [
  `Dr. James J. Yue specializes in the treatment of spinal disorders.`,
  `Dr. Yue is the immediate past co-chief of the orthopaedic spine surgery section at the Yale School of Medicine which he directed from 2002-2017.`,
  `He has been the Principal Investigator/Co-Investigator on a number of FDA and non FDA clinical trials for disc replacement, motion sparing, and endoscopic spine surgery clinical trials.`,
  `He has published and presented over 100 scientific articles and abstracts and 2 major spinal surgery textbooks. He is a journal reviewer for several international spine surgery journals.`,
  `He is currently an Associate Professor of Surgery in the Frank H. Netter Medical School at Quinnipiac University.`,
]
const specialty = specialties.spine.name
const id = 'yue'
export const yueData = {
  firstName: 'James',
  lastName: 'Yue',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'JAMES YUE, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  shortVideoCard: getInstructorCardPicture(id),

  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
