import React from 'react'
import styles from './ScienceStats.module.scss'
import CountUpStat from '../countUp'

function ScienceStats() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Immertec by the Numbers</div>
      </div>
      <div className={styles.statsContainer}>
        <CountUpStat fontColor="#3B6CB3" value={1} label={'NIH GRANT'} />
        <CountUpStat fontColor="#3B6CB3" value={3} label={'PUBLICATIONS'} />
        <CountUpStat fontColor="#3B6CB3" value={4} label={'RESEARCH COLLABORATIONS'} />
      </div>
    </div>
  )
}

export default ScienceStats
