import React from 'react'
import Header1 from './components/Header1/index'
import ImmersiveHero from './components/Immersive2dViewer/Immersive2dViewer'
import CardSection from './components/CardSection/CardSection'
import Features from './components/Features/Features'
import AwarenessPlatform from './components/AwarenessPlatform'
import TestimonialBg from './components/TestimonialBg/index'
import ImmersiveTraining from './components/immersiveTraining'
import Footer from './components/Footer/Footer'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import BurgerMenu from './components/Header/burgerMenu'
import { cdnUrl } from './constants/enum'


function Immersive2dPage() {
  const [width] = useWindowSize()
  const featureData = [
    {
      title: 'Hassle free',
      image: 'hassleIcon.png',
      cardDes: [
        'Easy event management and hosting.',
        'Join events with a simple passcode.',
        'Dedicated user portal for seamless access.',
      ],
    },
    {
      title: 'The best views',
      image: '/eye.png',
      cardDes: [
        '180° main view of the OR.',
        'Effortlessly pan and navigate the main view.',
        'Up to four simultaneous HD medical feeds.',
      ],
    },
    {
      title: 'Collaboration',
      image: 'colab.png',
      cardDes: [
        'Real-time video communication.',
        'Annotate medical feeds.',
        'Show presentation slides.',
      ],
    },
    {
      title: 'Medical feeds',
      image: '/feed.png',
      cardDes: [
        'Show up to four simultaneous HD medical feeds.',
        'Easily move or expand any feed as needed.',
        'Focus on what’s most important to you.',
      ],
    },
    {
      title: 'OR Equipment',
      image: '/or-equipment.png',
      cardDes: [
        'Connect with any OR equipment (C-Arms, scopes, Ultrasound, etc.).',
        'Support 3D medical equipment.',
        'Support AR technology from third parties.',
      ],
    },
    {
      title: 'Security',
      image: '/security.png',
      cardDes: [
        'SOC2 certification for enhanced security and trust.',
        'Compliance with privacy policies.',
        'Robust authentication to guarantee authorized access.',
      ],
    },
  ]
  const cardData = [
    {
      title: 'Watch live cases from anywhere',
      desc: 'Stay connected to your surgical training, proctoring, teaching, or learning from a surgeon, whether at home or on-the-go, with the ease of desktop, laptop, or tablet accessibility.',
      video:
        `${cdnUrl}/videos/solutions/IMMERSIVE_2D_VIEWER_MAP_ZOOM_IN.mp4`,
    },
    {
      title: 'Focus on what matters',
      desc: 'Experience personalized surgical viewing with a 180° panoramic OR perspective and the ability to access up to 4 medical feeds, presentations, or intricate camera angles concurrently.',
      image: '/FocusCardImage.png',
    },
    {
      title: 'Collaborate with peers',
      desc: 'Engage in real-time collaboration with OR surgeons and remote colleagues, while utilizing annotation features to highlight crucial information seamlessly.',
      image: '/newcollaborateCardImg.png',
    },
  ]
  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header1 /> : <MobileHeader />}
      <ImmersiveHero
        title={'Immersive 2D Viewer'}
        heroImage={'/newVideoFrame.png'}
        video={`${cdnUrl}/videos/2d_viewer/endovascular.mp4`}
      />
      <CardSection data={cardData} />
      <Features
        data={featureData}
        heading="Viewing experience tailor-made for Healthcare to ensure viewers never miss anything important"
      />
      <TestimonialBg />
      <ImmersiveTraining img={'/trainingImmersive2d.png'} />
      <AwarenessPlatform />

      <Footer />
    </div>
  )
}

export default Immersive2dPage
