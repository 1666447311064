import React from 'react'
import styles from './solutionhero.module.scss'
import { cdnUrl } from '../../constants/enum'
import { Link } from 'react-router-dom'

function SolutionHero() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <video autoPlay playsInline loop muted width="100%">
            <source src={`${cdnUrl}/videos/solutions/SOLUTIONS_COVER.mp4`} type="video/mp4" />
          </video>
        </div>
        <div className={styles.textGradient}></div>
        <div className={styles.radialGradient}></div>
        <div className={styles.circularGradient}></div>
        <div className={styles.mradialGradient}></div>
        <div className={styles.mcircularGradient}></div>
        <div className={styles.textContainer}>
          <h2>
            Immertec
            <br /> <span>Solutions</span>
          </h2>
          <p>
            A technology enabled healthcare platform that covers all the need of healthcare
            professionals and medical device companies.
          </p>
          <div className={styles.button}>
            <Link to="/request-a-demo">Request a demo</Link>
          </div>
        </div>
        <div className={styles.Mbutton}>
          <Link to="/request-a-demo">Request a demo</Link>
        </div>
      </div>
    </>
  )
}

export default SolutionHero
