import React from 'react'
import Footer from './components/Footer/Footer'
import PolicyText from './components/PolicyText/PolicyText'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import Header from './components/Header/Header'

function PrivacyPolicy() {
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const [width] = useWindowSize()

  return (
    <div>
      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}
      <PolicyText />
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
