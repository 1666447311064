import React from 'react'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import ImmersiveSurgical from './components/ImmersiveSurgical/ImmersiveSurgical'
import MedicalProfessionals from './components/MedicalProfessionals/MedicalProfessionals'
import HowImmersiveWorks from './components/HowImmersiveWorks/HowImmersiveWorks'
import ExperienceTraining from './components/ExperienceTraining/ExperienceTraining'
import VideoScrollEffect from './components/VideoScrollEffect/VideoScrollEffect'
import FeaturedSection from './components/FeaturedSection/FeaturedSection'
import SurgeonsTrain from './components/SurgeonsTrain/SurgeonsTrain'
import WhyTrain from './components/WhyTrain/WhyTrain'
import BurgerMenu from './components/Header/burgerMenu'
import useCheckMobileScreen from './utils/getPlatform'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'

function ProductPage() {
  const platform = useCheckMobileScreen()
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const [width] = useWindowSize()

  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}

      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      <ImmersiveSurgical />
      <MedicalProfessionals />
      <HowImmersiveWorks />
      <ExperienceTraining />
      {platform === 'desktop' && <VideoScrollEffect videoUrl="/videos/scroll-video.mp4" />}
      <WhyTrain />
      <SurgeonsTrain />
      <FeaturedSection />
      <Footer />
    </div>
  )
}

export default ProductPage
