import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'

const data = [
  `Dr. Craig Walker completed his pre-medicine collegiate degree in two years and received the Pre-Medicine Award from Nicholls State University in Thibodaux, Louisiana. He earned his doctorate from the LSU School of Medicine in New Orleans, Louisiana. Dr. Walker continued his training with a cardiology fellowship at Ochsner Foundation Hospital in New Orleans and a subsequent research fellowship at Harvard Medical School in Boston, Massachusetts.`,
  `Dr. Walker is board certified in internal medicine, cardiovascular disease and interventional cardiology. He is a fellow of the American College of Cardiology, the American College of Physicians, the American Society of Cardiovascular Interventionists, the International College of Angiology, the Society for Cardiac Angiography and Interventions, the American College of Chest Physicians and the Council on Clinical Cardiology.`,
  `Dr. Walker remains committed to education and serves as Clinical Professor of Medicine at LSU and Tulane Medical Schools. He serves as Clinical Editor of Vascular Disease Management and is on the editorial board of many other vascular-based journals, including the Journal of Endovascular Therapy, Endovascular Today and Global Vascular Digest. Dr. Walker is on the Board of Directors of the Outpatient Endovascular and Interventional Society (OEIS) and has served as an associate director of multiple conferences throughout the U.S. and the world. He is also on the Scientific Board of Directors for many of the world's largest peripheral interventional device companies.`,
  `Clinical Professor of Medicine - LSU School of Medicine, New Orleans, LA`,
  ` Clinical Professor of Medicine - Tulane University School of Medicine, New Orleans, LA`,
  `Founder and Chairman, New Cardiovascular Horizons Annual Conference`,
  `Founder and Chairman Emeritus, Horizons International Peripheral Group`,
  `Chairman, New Cardiovascular Horizons Regional Meetings`,
  `Clinical Editorial Board Member, HIPG Global Vascular Digest`,
  `Clinical Editor- Vascular Disease Management`,
]
const specialty = specialties.endovascular.name
const id = 'walker'
export const walkerData = {
  firstName: 'Craig',
  lastName: 'Walker',
  suffix: 'MD, FACC, FACA, FACP, FSCAI, FASCI, FCCP, FICA',
  id,
  title: 'CRAIG WALKER, MD, FACC, FACA, FACP, FSCAI, FASCI, FCCP, FICA',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  shortVideoCard: getInstructorCardPicture(id),

  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
