export const mloc1938 = {
  id: 'MLOC-1938',
  name: 'Lennar Foundation Medical Center',
  description:
    'The Lennar Foundation Medical Center is a 200,000-square-foot outpatient center on the University of Miami’s (UM) Coral Gables campus that provides easy health care access to leading physicians for students, faculty, staff, and surrounding communities.\nThe Lennar Foundation Medical Center delivers specialty care by the renowned Sylvester Comprehensive Cancer Center, Bascom Palmer Eye Institute (ranked the No. 1 eye hospital in the nation for 17 years by U.S. News & World Report), and UM’s Sports Medicine Institute.',
  status: '06-Event Prep (ES)',
  address1: '5555 Ponce de Leon Blvd',
  address2: null,
  city: 'Coral Gables',
  state: 'FL',
  zip: '33146',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '25.7134',
  longitude: '-80.28012',
  photo: 'mloc-1938.png',
  speciality: 'Spine',
}
