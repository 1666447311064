import React from 'react'
import styles from './ThreeItems.module.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Slider from 'react-slick'
import PartnerLogo from '../ResearchPartners/PartnerLogo'

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon className={styles.ArrowFont} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon className={styles.ArrowFont} />
    </div>
  )
}

function ThreeItems() {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    // adaptiveHeight: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,

    responsive: [
      {
        breakpoint: 1600,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
          // adaptiveHeight: true,
        },
      },
      {
        breakpoint: 1340,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
          // adaptiveHeight: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
          // adaptiveHeight: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
          // adaptiveHeight: true,
          // mobileFirst:true,
        },
      },
    ],
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.textContainer}>
        Immertec is made up of passionate dreamers, analytical craftsmen and women, progressive
        leaders and detailed analysts who are looking for a place to learn and grow. Whether it’s in
        person or remote (#hybrid), we work hard doing the things we love; always having fun. Our
        culture is everything to us.
      </div>
      <div className={styles.images}>
        <div className={styles.Image}>
          <img src="/Benefits/6.png" />
        </div>
        <div className={styles.Image}>
          <img src="/Benefits/5.png" />
        </div>
        <div className={styles.Image}>
          <img src="/Benefits/4.png" />
        </div>
        <div className={styles.Image}>
          <img src="/Benefits/1.png" />
        </div>
        <div className={styles.Image}>
          <img src="/Benefits/2.png" />
        </div>
        <div className={styles.Image}>
          <img src="/Benefits/3.png" />
        </div>
      </div>

      <div className={styles.sliderWidthM}>
        <Slider {...settings}>
          <div className={styles.Image}>
            <img src="/BenefitsM/2.png" />
          </div>
          <div className={styles.Image}>
            <img src="/BenefitsM/3.png" />
          </div>
          <div className={styles.Image}>
            <img src="/BenefitsM/4.png" />
          </div>
          <div className={styles.Image}>
            <img src="/BenefitsM/5.png" />
          </div>
          <div className={styles.Image}>
            <img src="/BenefitsM/6.png" />
          </div>
          <div className={styles.Image}>
            <img src="/BenefitsM/7.png" />
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default ThreeItems
