import React from 'react'
import styles from './Footer.module.scss'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <>
      <div className={styles.footerContainer}>
        <div className={styles.mainContainer}>
          <div className={styles.column1}>
            <div className={styles.footerLogo}>
              <img src="/icons/invertedLogo.svg" alt="" />
            </div>
            <div className={styles.followusText}>Follow Us</div>
            <div className={styles.socialIconContainer}>
              <a href="https://www.facebook.com/IMMERTEC" target="blank">
                <div className={styles.socialIcon}>
                  <img src="/footer_facebook.svg" alt="" />
                </div>
              </a>

              <a href="https://twitter.com/immertec" target="blank">
                <div className={styles.socialIcon}>
                  <img src="/footer_twitter.svg" alt="" />
                </div>
              </a>

              <a href="https://www.instagram.com/immertec" target="blank">
                <div className={styles.socialIcon}>
                  <img src="/footer_instagram.svg" alt="" />
                </div>
              </a>

              <a href="https://www.linkedin.com/company/immertec" target="blank">
                <div className={styles.socialIcon}>
                  <img src="/footer_linkedin.svg" alt="" />
                </div>
              </a>
            </div>
            <div className={styles.footerTradeMark}>
              © 2022 Immersive Tech Inc. All Rights Reserved.
            </div>
          </div>

          <div className={styles.column2}>
            <div className={styles.columnTitle}>Platform</div>
            <div className={styles.columnItemContainer}>
              <Link to="/product">
                <div className={styles.columnItem}>Product</div>
              </Link>

              <Link to="/science">
                <div className={styles.columnItem}>The Science</div>
              </Link>
            </div>
          </div>

          <div className={styles.column3}>
            <div className={styles.columnTitle}>Company</div>
            <div className={styles.columnItemContainer}>
              <Link to="/mab">
                <div className={styles.columnItem}>
                  Medical Advisory <br /> Board
                </div>
              </Link>
              <Link to="/team">
                <div className={styles.columnItem}>About</div>
              </Link>

              <Link to="/careers">
                <div className={styles.columnItem}>Careers</div>
              </Link>
              <Link to="/request-a-demo">
                <div className={styles.columnItem}>Request a Demo</div>
              </Link>
            </div>
          </div>

          <div className={styles.column4}>
            <div className={styles.columnTitle}>More</div>
            <div className={styles.columnItemContainer}>
              <Link to="/terms-of-service">
                <div className={styles.columnItem}>Terms of Service</div>
              </Link>

              <Link to="/privacy-policy">
                <div className={styles.columnItem}>Privacy Policy</div>
              </Link>
              <Link to="/policy/fcoi">
                <div className={styles.columnItem}>Financial Conflict of Interest Policy</div>
              </Link>
            </div>
          </div>

          <div className={styles.column5}>
            <div className={styles.columnTitle}>Downloads</div>
            <div className={styles.columnItemContainer}>
              <div className={styles.columnItemlist}>
                <Link to="/doctorsaying"></Link>
              </div>
              <div className={styles.appImageContainer}>
                <a href="https://apps.apple.com/us/app/medoptic/id1517703273">
                  <img style={{ height: '100%', width: '100%' }} src="/icons/AppStore.svg" alt="" />
                </a>
              </div>
              <div className={styles.appImageContainer}>
                <a href="https://play.google.com/store/apps/details?id=com.immertec.medoptic.mobile">
                  <img
                    style={{ height: '100%', width: '100%' }}
                    src="/icons/GooglePlay.svg"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className={styles.footerTradeMarkM}>
              Copyright 2022 Immersive Tech, Inc.
              <br />
              All Rights Reserved.
              <div className={styles.imageM}>
                <div className={styles.appImageContainerM}>
                  <a href="https://apps.apple.com/us/app/medoptic/id1517703273">
                    <img
                      style={{ height: '100%', width: '100%' }}
                      src="/icons/AppStore.svg"
                      alt=""
                    />
                  </a>
                </div>
                <div className={styles.appImageContainerM}>
                  <a href="https://play.google.com/store/apps/details?id=com.immertec.medoptic.mobile">
                    <img
                      style={{ height: '100%', width: '100%' }}
                      src="/icons/GooglePlay.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerMContainer}>
        <div className={styles.column1}>
          <div className={styles.footerLogo}>
            <img src="/icons/invertedLogo.svg" alt="" />
          </div>
          <div className={styles.followusText}>Follow Us</div>
          <div className={styles.socialIconContainer}>
            <a href="/https://www.facebook.com/IMMERTEC">
              <div className={styles.socialIcon}>
                <img src="/footer_facebook.svg" alt="" />
              </div>
            </a>
            <div className={styles.socialIcon}>
              <img src="/footer_twitter.svg" alt="" />
            </div>
            <div className={styles.socialIcon}>
              <img src="/footer_instagram.svg" alt="" />
            </div>
            <div className={styles.socialIcon}>
              <img src="/footer_linkedin.svg" alt="" />
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.leftCol}>
            <div className={styles.column3}>
              <div className={styles.columnTitle}>Product</div>
              <div className={styles.columnItemContainer}>
                <Link to="/">
                  <div className={styles.columnItem}>How it Works</div>
                </Link>
              </div>
            </div>
            <div className={styles.column3}>
              <div className={styles.columnTitle}>Legal</div>
              <div className={styles.columnItemContainer}>
                <Link to="/">
                  <div className={styles.columnItem}>Legal Services</div>
                </Link>
                <Link to="/">
                  <div className={styles.columnItem}>Privacy Policy</div>
                </Link>
                <Link to="/policy/fcoi">
                  <div className={styles.columnItem}>Financial Conflict of Interest Policy</div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.rightCol}>
            <div className={styles.columnTitle}>Company</div>
            <div className={styles.columnItemContainer}>
              <Link to="/">
                <div className={styles.columnItem}>About</div>
              </Link>
              <Link to="/">
                <div className={styles.columnItem}>FAQ</div>
              </Link>
              <Link to="/">
                <div className={styles.columnItem}>Press</div>
              </Link>
              <Link to="/">
                <div className={styles.columnItem}>Careers</div>
              </Link>
              <Link to="/request-a-demo">
                <div className={styles.columnItem}>Request a Demo</div>
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.footerTradeMark}>
          © 2022 Immersive Tech Inc. All Rights Reserved.
        </div>
      </div>
    </>
  )
}

export default Footer
