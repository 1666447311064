import React, { useState } from 'react'
import styles from './ContactCard.module.scss'
// import OutlineButton from "../OutlineButton";
import { ButtonBase } from '@mui/material'
import getUrlForm from '../../utils/getFormUrl'

function ContactCard({ heading, middleText, bottomText, buttonText }) {
  const [BtnHover, setBtnHover] = useState(false)
  const handleOnHover = () => {
    setBtnHover(!BtnHover)
  }
  return (
    <div className={styles.mainContainer} onMouseEnter={handleOnHover} onMouseLeave={handleOnHover}>
      <div className={styles.textContainer}>
        <div className={styles.cardHeading}>{heading}</div>
        <div className={styles.cardText}>{middleText}</div>

        <div className={styles.cardSubText}>{bottomText}</div>
        <div className={styles.btnContainer}>
          <a href={getUrlForm('chat')}>
            <ButtonBase
              className={styles.container}
              style={{
                width: '12.984375vw',
                height: ' 2.5vw',
                transition: ' all 0.5s ease',
                background: BtnHover ? '#3B6CB3' : '#ffff',
                borderRadius: '8px',
                fontSize: '1.0416666666666667vw',
                fontFamily: 'Gilroy-Medium',
                color: BtnHover ? '#ffff' : '#243746',
                border: '1px solid #243746',
              }}
            >
              {buttonText}
            </ButtonBase>
          </a>
        </div>

        <div className={styles.MbtnContainer}>
          <a href={getUrlForm('chat')}>
            <ButtonBase
              className={styles.container}
              style={{
                width: '250px',
                height: '40px',
                transition: ' all 0.5s ease',
                background: BtnHover ? '#3B6CB3' : '#ffff',
                borderRadius: '8px',
                fontSize: '16px',
                fontFamily: 'Gilroy-Medium',
                color: BtnHover ? '#ffff' : '#243746',
                border: BtnHover ? null : '1px solid #243746',
              }}
            >
              {buttonText}
            </ButtonBase>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactCard
