import React, { useState } from 'react'
import styles from './Header.module.scss'
import { Link } from 'react-router-dom'
import CustomDropDown from '../CustomDropDown'

function Header({ handleOnChange }) {
  return (
    <nav style={{ position: 'sticky', top: 0, background: '#fff', zIndex: 10000 }}>
      <div className={styles.mainContainer}>
        <div className={styles.headerContainer}>
          <Link to="/">
            <div className={styles.logoContainer}>
              <img src="/headerLogoVector.png" alt="" />
            </div>
          </Link>

          <div className={styles.navigationContainer}>
            <Link to="/solutions">
              <div className={styles.navigationItem}>Solutions</div>
            </Link>
            <CustomDropDown
              name={'About'}
              optionsToMap={[
                { title: 'The Science', link: '/science' },
                { title: 'Instructors', link: '/instructors' },
                { title: 'Locations', link: '/locations' },
                { title: 'Medical Advisory Board', link: '/mab' },
                { title: 'Team', link: '/team' },
                { title: 'Careers', link: '/careers' },
              ]}
            />
            <Link to="/request-a-demo">
              <div className={styles.navbarHighlightItem}>Request a Demo</div>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
