import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getLongInstructorVideoLink,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'
const data = [
  `Dr. Jeffrey Carr, MD is an Interventional Cardiology Specialist in Tyler, TX and has over 33 years of experience in the medical field. He graduated from University of California At Los Angeles medical school in 1988. He is affiliated with medical facilities CHRISTUS Mother Frances Hospital - Tyler and UT Health Tyler.`,
]
//Use this template to add more instructors
const specialty = specialties.endovascular.name
const id = 'carr'
export const carrData = {
  firstName: 'Jeff',
  lastName: 'Carr',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'JEFFREY G. CARR, MD, FACC, FSCAI',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileLink: `/Instructor/${id}`,
  profileCover: getInstructorProfileCover(id),
  shortVideoCard: getInstructorCardPicture(id),

  events: getInstructorEvents(id),
}
