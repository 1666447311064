import React from 'react'
import styles from './MemberDetails.module.scss'

function Michael() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.memberImageContainer}>
        <img style={{ width: ' 12.8125vw', height: '12.8125vw' }} src="/wheeler.png" />
      </div>

      <div className={styles.mobileContainer}>
        <div className={styles.imageM}>
          <img style={{ width: ' 100px', height: '100px' }} src="/wheeler.png" />
        </div>
        <div className={styles.DetailsM}>
          <div className={styles.memberNameM}>Matt Wheeler</div>

          <div className={styles.memberRoleM}>CRO</div>
          <div className={styles.socialIconsM}>
            <img src="/icons/facebook.png" />
            <img src="/icons/instagram.png" />
            <img src="/icons/linkedin.png" />
          </div>
        </div>
      </div>
      <div className={styles.memberDetails}>
        <div className={styles.memberName}>Matt Wheeler</div>
        <div className={styles.memberText1}>
          Matt Wheeler is an exceptional business professional with a wealth of experience in the IT
          sales industry, spanning over two decades. He is widely recognized for his visionary
          leadership, entrepreneurial spirit, and remarkable sales abilities. As a native of Tampa,
          Florida, Matt has honed a range of skills and attributes that have enabled him to guide
          multiple organizations through successful mergers, acquisitions, and IPOs.
        </div>
        <div className={styles.memberQuote}>
          "Matt enjoys lightning pace start-ups where he can apply his experience with business
          scaling, hyper-growth, and getting cutting-edge technology into customers’ hands."
        </div>
        <div className={styles.memberText2}>
          As Chief Revenue Officer of Immertec, Matt is laser-focused on expanding the company's
          global reach and ensuring that its innovative solutions are accessible to medical
          professionals worldwide. Matt has a deep-seated belief in Immertec's ability to
          revolutionize the medical training industry and transform how companies operate their
          businesses globally.
        </div>
        <div className={styles.memberText2}>
          Under Matt's leadership, Immertec is making strides in the medical industry, driving
          innovation and growth while addressing critical issues facing medical professionals
          worldwide. His expertise in sales and business development is critical in driving revenue
          growth and expanding the company's footprint.
        </div>{' '}
        <div className={styles.memberText2}>
          Matt is also known for his ability to build and lead high-performance teams. He is
          committed to fostering a culture of collaboration, innovation, and excellence at Immertec,
          creating an environment where employees feel supported and empowered to achieve their full
          potential.
        </div>{' '}
        <div className={styles.memberText2}>
          Matt is a true visionary and a proven sales leader. His expertise, coupled with his
          passion for innovation and growth, is instrumental in driving Immertec's success in the
          medical industry and beyond.
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.memberRole}>CRO</div>
        <div className={styles.socialIcons}>
          <img src="/icons/facebook.png" />
          <img src="/icons/instagram.png" />
          <img src="/icons/linkedin.png" />
        </div>
      </div>
    </div>
  )
}

export default Michael
