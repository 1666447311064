import React, { useContext, useState, useEffect, useRef } from 'react'
import { dataForCoverSlider } from '../constants/data/coverSliderData'
import shuffle from '../utils/getArrayRandomized'
import useCheckMobileScreen from '../utils/getPlatform'
import { useResizeObserver } from '../hooks/useResizeObserver'

const HomeCourselContext = React.createContext()

export function useHomeCourselContext() {
  return useContext(HomeCourselContext)
}

export function HomeCourselStateProvider({ children }) {
  const [categories] = useState(Object.keys(dataForCoverSlider))
  const [shuffledDoctors, setShuffledDoctor] = useState([])
  const [currentCover, setCurrentCover] = useState(null)
  const [currentCoverTextIndex, setCurrentCoverTextIndex] = useState(null)

  const sliderRef = useRef()
  const sliderChangeRef = useRef()

  const [width] = useResizeObserver(sliderRef)

  const platform = useCheckMobileScreen()

  const handleChangeOnCurrentCover = () => {
    setCurrentCover(shuffledDoctors[currentCoverTextIndex])
  }

  const handleOnCategoryTabClick = (_event, value) => {
    if (value) {
      const gotoIndex = shuffledDoctors.findIndex((item) => item.category === value)
      console.log(gotoIndex, value, shuffledDoctors)
      sliderRef.current.swiper.slideToLoop(gotoIndex, 0)
      if (platform === 'desktop') scrollToTop(sliderChangeRef)
    }
  }

  const handleSetActiveSlider = (index) => {
    setCurrentCoverTextIndex(index)
    setCurrentCover(shuffledDoctors[index])
  }

  const scrollToTop = () => {
    // TODO: Figure out why this jumps while going over the videos
    if (platform === 'desktop')
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    else {
      window.scrollTo({
        top: 30,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    let arr = []
    shuffle(categories).map((category) => {
      arr.push(...shuffle(dataForCoverSlider[category]))
    })
    setShuffledDoctor(arr)
  }, [])

  useEffect(() => {
    if (shuffledDoctors.length > 0) {
      width <= 640 && width >= 480
        ? setCurrentCover(shuffledDoctors[1])
        : width <= 480 && width >= 320
        ? setCurrentCover(shuffledDoctors[2])
        : setCurrentCover(shuffledDoctors[0])
    }
  }, [shuffledDoctors, width])

  return (
    <HomeCourselContext.Provider
      value={{
        categories,
        shuffledDoctors,
        currentCover,
        currentCoverTextIndex,
        sliderRef,
        sliderChangeRef,
        handleSetActiveSlider,
        handleOnCategoryTabClick,
        handleChangeOnCurrentCover,
      }}
    >
      {children}
    </HomeCourselContext.Provider>
  )
}
