export const mloc64 = {
  id: 'MLOC-64',
  name: 'AdventHealth Carrollwood',
  description:
    'We’re a unified network of care dedicated to helping patients feel whole in every aspect of their health and wellness. At AdventHealth, our patients’ whole health matters. That’s why we’ve built a network of care with world-class expertise that supports every aspect of our patients’ lives — at every age and stage.   We offer comprehensive medical and surgical care as well as many specialized services. Our hospital, in the heart of the Carrollwood community of Tampa, has undergone extensive growth and renovation. We offer a comfortable 120-bed facility with friendly staff and a quaint atmosphere.',
  status: '10-Inactive (Sales/ES/SI)',
  address1: '7171 N. Dale Mabry Highway',
  address2: null,
  city: 'Tampa',
  state: 'FL',
  zip: '33614',
  category: 'Commercial',
  type: 'Hospital',
  latitude: '28.01346',
  longitude: '-82.50412',
  photo: 'mloc-64.png',
  speciality: 'Endovascular',
}
