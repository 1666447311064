import './App.css'
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import React from 'react'
import { useRoutes, BrowserRouter } from 'react-router-dom'
import CareerPage from './CareerPage'
import ErikMaltais from './components/MemberDetails/ErikMaltais'
// import Jim from './components/MemberDetails/Jim'
import Jon from './components/MemberDetails/Jon'
import ContactPage from './ContactPage'
import Home from './Home'

import InstructorDetailsPage from './InstructorDetailsPage'
import LoadingScreen from './LoadingScreen'
import Locations from './Locations'
import MabPage from './MabPage'
import MemberPage from './MemberPage'
import PrivacyPolicy from './PrivacyPolicy'
import ProductPage from './ProductPage'
import { HomeCourselStateProvider } from './providers/HomeCourselProvider'
import { MuteStateProvider } from './providers/MuteContextProvider'
import SciencePage from './SciencePage'
import TeamsPage from './TeamsPage'
import TOS from './TOS'
import NavigationScroll from './providers/NavigationScroll'
import InstructorsPage from './Instructors'
import Demo from './Demo'
import SucessDemo from './SucessDemo'
import Solutions from './Solutions'
import Immersive2dPage from './Immersive2dPage'
import VRViewer from './VRViewer'
import DataAnalytics from './DataAnalytics'
import RecordingLibrary from './RecordingLibrary'
import PDFViewer from './PdfViewer'

const router = {
  path: '/',
  element: <NavigationScroll />,
  children: [
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/solutions',
      element: <Solutions />,
    },
    {
      path: '/request-a-demo',
      element: <Demo />,
    },
    {
      path: '/success/request-a-demo',
      element: <SucessDemo />,
    },
    {
      path: '/instructors',
      element: <InstructorsPage />,
    },
    {
      path: '/locations',
      element: <Locations />,
    },
    {
      path: '/loading',
      element: <LoadingScreen />,
    },
    {
      path: '/instructor/:id',
      element: <InstructorDetailsPage />,
    },
    {
      path: '/team',
      element: <TeamsPage />,
    },
    {
      path: '/member',
      element: <MemberPage />,
    },
    {
      path: '/erik',
      element: (
        <MemberPage>
          <ErikMaltais />
        </MemberPage>
      ),
    },
    {
      path: '/jon',
      element: (
        <MemberPage>
          <Jon />
        </MemberPage>
      ),
    },
    // {
    //   path: '/jim',
    //   element: (
    //     <MemberPage>
    //       <Jim />
    //     </MemberPage>
    //   ),
    // },
    // {
    //   path: '/michael',
    //   element: (
    //     <MemberPage>
    //       <Jim />
    //     </MemberPage>
    //   ),
    // },
    {
      path: '/product',
      element: <ProductPage />,
    },
    {
      path: '/contact',
      element: <ContactPage />,
    },
    {
      path: '/science',
      element: <SciencePage />,
    },
    {
      path: '/careers',
      element: <CareerPage />,
    },
    {
      path: '/mab',
      element: <MabPage />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/terms-of-service',
      element: <TOS />,
    },

    {
      path: '/immersive-2d-viewer',
      element: <Immersive2dPage />,
    },
    {
      path: '/vr-viewer',
      element: <VRViewer />,
    },
    {
      path: '/data-analytics',
      element: <DataAnalytics />,
    },
    {
      path: '/recording-library',
      element: <RecordingLibrary />,
    },
    {
      path: '/top-exec-summit/:filename',
      element: <PDFViewer />,
    },
    {
      path: '/policy/:filename',
      element: <PDFViewer />,
    },
  ],
}

function Routes() {
  return useRoutes([router])
}

function App() {
  return (
    <BrowserRouter basename={''}>
      <MuteStateProvider>
        <HomeCourselStateProvider>
          <Routes />
        </HomeCourselStateProvider>
      </MuteStateProvider>
    </BrowserRouter>
  )
}

export default App
