import React from 'react'
import styles from './ImmensePower.module.scss'

function ImmensePower() {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <img className={styles.image} src="icons/ImmensePower.png" />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.itemContainer}>
          <div className={styles.comma}>
            <img src="icons/invertedCommas.png" style={{ height: '100%', width: '100%' }} />
          </div>
          <div className={styles.textContainer}>
            There is immense power when a group of people with similar interest gets together to
            work toward the same goals
          </div>

          <div className={styles.commaEnd}>
            <img src="icons/invertedCommas.png" />
          </div>
          <div className={styles.authorName}>Idowu Koyenikan</div>
        </div>
      </div>
    </div>
  )
}

export default ImmensePower
