import React from 'react'
import Slider from 'react-slick'
import styles from './ResearchPartners.module.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import PartnerLogo from './PartnerLogo'
import ResearchCard from './ResearchCard/ResearchCard'
import ResearchCardSlider from './ResearchCardSlider/ResearchCardSlider'

let data = [
  { id: 1, img: 'Brandlogos/1.png' },
  { id: 2, img: 'Brandlogos/2.png' },
  { id: 3, img: 'Brandlogos/3.png' },
  { id: 4, img: 'Brandlogos/4.png' },
  { id: 5, img: 'Brandlogos/5.png' },
  { id: 6, img: 'Brandlogos/6.png' },
]

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon className={styles.ArrowFont} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon className={styles.ArrowFont} />
    </div>
  )
}

function ResearchPartners() {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    // adaptiveHeight: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,

    responsive: [
      {
        breakpoint: 1600,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 1340,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
          adaptiveHeight: true,
          // mobileFirst:true,
        },
      },
    ],
  }
  return (
    <div className={styles.mainContainer}>
      <div>
        <div className={styles.heading}>Our Research Partners</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={styles.mainSliderWidth}>
          <div className={styles.overlay1}></div>
          <div className={styles.overlay2}></div>
          <Slider {...settings}>
            {data.map((item, index) => (
              <PartnerLogo item={item} />
            ))}
          </Slider>
        </div>
      </div>
      <ResearchCardSlider />
    </div>
  )
}

export default ResearchPartners
