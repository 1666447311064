import React from 'react'
import styles from './ContactUs.module.scss'
import ContactCard from '../ContactCard/ContactCard'
import ProgressiveImage from '../ProgressiveImage'
let data = [
  {
    id: '1',
    heading: 'Sales',
    middleText: 'Get in touch with our sales team to get started with our platform.',
    bottomText: 'Learn more about scaling your professional education program.',
    buttonText: 'Contact Sales',
  },
]

function ContactUs() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.heroContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.textContainer}>
            <div className={styles.heading}>Contact Us</div>
            <div className={styles.subText}>Have questions? We are ready to help.</div>
          </div>
          <div className={styles.cards}>
            {data.map((item, index) => (
              <ContactCard
                key={`${item.heading}_${index}`}
                heading={item.heading}
                middleText={item.middleText}
                bottomText={item.bottomText}
                buttonText={item.buttonText}
              />
            ))}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <ProgressiveImage
            src={'/Contact.jpeg'}
            placeholder={'/ContactProgressiveImage.jpeg'}
            alt={''}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactUs
