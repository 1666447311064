export const mloc269 = {
  id: 'MLOC-269',
  name: 'AdventHealth Celebration',
  description:
    'AdventHealth Celebration, (formerly Florida Hospital Celebration Health), is an acute care hospital in Celebration, Florida. The hospital is part of the eight-unit AdventHealth Florida network, which itself is part of the network of 17 hospitals comprising the AdventHealth-Florida Division. The 315,000-square-foot (29,300 m2) 220-bed acute-care facility serves visitors to the Walt Disney World Resort, and is the community hospital for Greater Orlando and a major tertiary referral hospital for Central Florida.',
  status: '10-Inactive (Sales/ES/SI)',
  address1: '400 Celebration Pl',
  address2: null,
  city: 'Kissimmee',
  state: 'FL',
  zip: '34747',
  category: 'Commercial',
  type: 'Hospital',
  latitude: '28.32818',
  longitude: '-81.54052',
  photo: 'mloc-269.png',
  speciality: 'Interventional Spine',
}
