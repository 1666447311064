import React from 'react'
import styles from './RoundedImage.module.scss'

function RoundedImage({ roundImage, style }) {
  return (
    <div style={style} className={styles.roundImage}>
      <img src={roundImage} />
    </div>
  )
}

export default RoundedImage
