import { specialties } from '../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'

const data = [
  `Dr. Mohammad Raja, MD is an Interventional Cardiology Specialist in El Paso, TX. He is affiliated with medical facilities such as Las Palmas Medical Center and Sierra Medical Center.`,
  `Dr. Laiq Raja is an interventional cardiologist who is board certified in the treatment of peripheral vascular disease. He specializes in the treatment of patients with critical limb ischemia, which is a severe form of PAD. He has over 20 years experience in treating patients with these conditions and is one of the country's foremost experts in the treatment of severe critical limb ischemia and amputation prevention. He is the director and one of the founders of PULSE Amputation Prevention Centers in El Paso, Texas, which is a unique program focusing on a team approach, working closely with your other physicians to treat circulation problems in the legs and wound care treatment to heal severe foot wounds and infection in order to prevent amputations.`,
  `Dr. Raja is involved in many clinical trials and is a national thought leader in the treatment of PAD and critical limb ischemia. He has trained over 300 physicians nationwide in advanced techniques for the treatment of critical limb ischemia and has been published in numerous national journals for his work in this area. PULSE Amputation Prevention Centers is a multi-disciplinary treatment program that is focused on preventing amputations, treating PAD and critical limb ischemia early and aggressively to improve patient's outcomes and quality of life.`,
]
const specialty = specialties.endovascular.name
const id = 'raja'
export const rajaData = {
  firstName: 'Laiq',
  lastName: 'Raja',
  middleName: undefined,
  suffix: 'MD, FACC, FSCAI',
  id,
  title: 'M. LAIQ RAJA, MD, FACC, FSCAI',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  shortVideoCard: getInstructorCardPicture(id),

  events: getInstructorEvents(id),
}
