import React, { useState, useRef, useEffect } from 'react'
import useFullscreenStatus from '../../utils/SetElementFullScreen'
import styles from './hoverVideoPlayer.module.scss'

function HoverVideoPlayer({
  hoverOverlay,
  pausedOverlay,
  videoSrc,
  muted,
  focused,
  fullscreen,
  setFullscreenAvailable,
}) {
  const videoRef = useRef()
  const [isHovered, setIsHovered] = useState(false)
  let interval = useRef(null)
  let isFullscreen, setIsFullscreen

  useEffect(() => {
    if (fullscreen && setIsFullscreen) {
      setIsFullscreen()
    } else if (isFullscreen) {
      setIsFullscreen()
    }
  }, [fullscreen])

  useEffect(() => {
    try {
      // eslint-disable-next-line prettier/prettier
      // [isFullscreen, setIsFullscreen] = useFullscreenStatus(videoRef)
      // TODO: change this to true after fixing the fullscreen issue.
      isFullscreen = false
      setIsFullscreen = undefined
      setFullscreenAvailable(false)
    } catch (e) {
      isFullscreen = false
      setIsFullscreen = undefined
    }

    if (videoRef.current) {
      if (isHovered || focused) {
        videoRef.current.play()
        return
      }
      videoRef.current.pause()
    }
  }, [videoRef.current, focused, isHovered])

  const handleOnMouseEnter = () => {
    clearTimeout(interval.current)
    setIsHovered(true)
  }

  const handleOnMouseLeave = () => {
    interval.current = setTimeout(() => {
      setIsHovered(false)
    }, 500)
  }

  return (
    <div
      className={styles.videoContainer}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className={styles.hoverOverlayContainer}>{hoverOverlay}</div>

      <div className={`${styles.pausedOverlayContainer} ${(focused || isHovered) && styles.blind}`}>
        {pausedOverlay}
      </div>
      <video className={styles.video} playsInline ref={videoRef} loop muted={muted}>
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  )
}

export default HoverVideoPlayer
