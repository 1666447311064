import React from 'react'
import Footer from './components/Footer/Footer'
// import Header from './components/Header/Header'
import Introduction from './components/Introduction/Introduction'
import DoctorsList from './components/DoctorsList/DoctorsList'
import BurgerMenu from './components/Header/burgerMenu'
import SCYPTP from './components/SCYPTP'
import AreYouInterested from './components/AreYouInterestedBanner'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import Header from './components/Header/Header'

function MabPage() {
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const [width] = useWindowSize()

  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}

      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      <Introduction />
      <DoctorsList />
      {/* <AreYouInterested
        bannerTitle={'Join the Immertec instructor team'}
        btnTitle={'Apply Here'}
        btnUrl={'host'}
        bgcolor={'#F6F7FB'}
      /> */}
      <SCYPTP />
      <Footer />
    </div>
  )
}

export default MabPage
