export const mloc109 = {
  id: 'MLOC-109',
  name: 'Orthopedic Foundation BioSkills Lab NYC (OFALS)',
  description:
    'The Orthopaedic Foundation is a not-for-profit organization that improves quality of life through cutting edge research and education for the prevention and treatment of musculoskeletal diseases, with a focus on orthopaedic and sports injuries.',
  status: '10-Inactive (Sales/ES/SI)',
  address1: '345 E 37th St #312',
  address2: null,
  city: 'New York',
  state: 'NY',
  zip: '10016',
  category: 'Pilot',
  type: 'Bioskills Lab',
  latitude: '27.9441',
  longitude: '-82.45601',
  photo: 'mloc-109.png',
  speciality: 'Orthopedics',
}
