import React from 'react'
import styles from './hero.module.scss'
import { cdnUrl } from '../../constants/enum'
import { Link } from 'react-router-dom'
function Hero() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <video autoPlay playsInline loop muted width="100%">
            <source src={`${cdnUrl}/videos/other/cover_video.mp4`} type="video/mp4" />
          </video>
        </div>
        <div className={styles.textGradient}></div>
        <div className={styles.radialGradient}></div>
        <div className={styles.circularGradient}></div>
        <div className={styles.mradialGradient}></div>
        <div className={styles.mcircularGradient}></div>
        <div className={styles.textContainer}>
          <h2>
            Immersive
            <br /> <span>Training & Awareness</span>
            <br /> Platform
          </h2>
          <p>
            Healthcare solutions featuring live streaming of surgical procedures in VR and Immersive
            2D, on-demand recordings, and data analytics.
          </p>
          <div className={styles.button}>
            <Link to="/request-a-demo">Request a demo</Link>
          </div>
        </div>
        <div className={styles.Mbutton}>
          <Link to="/request-a-demo">Request a demo</Link>
        </div>
      </div>
    </>
  )
}

export default Hero
