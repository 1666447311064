export const mloc217 = {
  id: 'MLOC-217',
  name: 'Cantor Spine Center',
  description:
    'Cantor Spine Center, now merged with The Paley Orthopedic & Spine Institute, is excited to be an in-network provider for almost all insurance plans – making it easier for more patients to access the highest level of spine care. At Cantor Spine Center, Dr. Jeffrey Cantor and the highly specialized medical team are committed to providing patients unmatched care and expertise in a very personalized way.',
  status: '07-Event Ready (ES)',
  address1: '3000 Bayview Dr.',
  address2: '#100',
  city: 'Fort Lauderdale',
  state: 'FL',
  zip: '33306',
  category: 'Pilot',
  type: 'Surgery Center',
  latitude: '26.16614',
  longitude: '-80.10878',
  photo: 'mloc-217.png',
  speciality: 'Endovascular',
}
