export const mloc543 = {
  id: 'MLOC-543',
  name: 'New Hampshire Neurospine Institute',
  description:
    'Our team approach brings together the expertise of numerous medical and surgical disciplines to provide you with the best treatment options available. The goal of the New Hampshire NeuroSpine Institute is to help you regain the quality of your life. \n\nResearch has shown that complex health problems such as back pain, cancer, asthma, diabetes and heart disease are best treated by centers of excellence that direct all their time and resources at solving these important health care issues. As a recognized "Center of Excellence" our solution to back pain, neck pain and brain disorders is a multi-disciplinary team approach that combines the expertise of the core medical, surgical and rehabilitation disciplines. The patient then benefits from an unbiased approach that represents the best of all treatment options.',
  status: '06-Event Prep (ES)',
  address1: '4 Hawthorne Drive',
  address2: null,
  city: 'Bedford',
  state: 'NH',
  zip: '3110',
  category: 'Commercial',
  type: 'Hospital',
  latitude: '42.9411',
  longitude: '-71.4668',
  photo: 'mloc-543.png',
  speciality: 'Spine',
}
