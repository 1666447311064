export const mloc319 = {
  id: 'MLOC-319',
  name: 'Emory University School of Medicine',
  description:
    'Research Facility for Interventional Radiology - Emory University School of Medicine',
  status: '10-Inactive (Sales/ES/SI)',
  address1: '1364 E Clifton Rd NE',
  address2: null,
  city: 'Atlanta',
  state: 'GA',
  zip: '30322',
  category: 'Research Collaboration',
  type: 'Hospital',
  latitude: '33.78384',
  longitude: '-84.32362',
  photo: 'mloc-319.png',
  speciality: 'Interventional Spine',
}
