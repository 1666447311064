import { specialties } from './specialty'

export default function groupLocationsByType(list, groupBy) {
  const groups = list.reduce((groups, item) => {
    const group = groups[item[groupBy]] || []
    group.push(item)
    groups[item[groupBy]] = group
    return groups
  }, {})

  let specialityToMap = Object.keys(specialties).map((item) => specialties[item].label)

  let groupswithsubgroup = {}
  Object.keys(groups).map((group) => {
    let temp2 = {}
    specialityToMap.map((speciality) => {
      temp2[speciality] = groups[group].filter((item) => item.speciality === speciality)
    })
    groupswithsubgroup[group] = temp2
  })
  return groupswithsubgroup
}
