export const mloc181 = {
  id: 'MLOC-181',
  name: 'Baylor Surgical Hospital at Fort Worth',
  description:
    'As the largest not-for-profit​ healthcare system in Texas and one of the largest in the United States, Baylor Scott & White Health was born from the 2013 combination of Baylor Health Care System and Scott & White Healthcare. Today, Baylor Scott & White includes 52 hospitals, more than 800 patient care sites, more than 7,300 active physicians, over 49,000 employees and the Scott &​ White Health Plan.',
  status: '07-Event Ready (ES)',
  address1: '1800 Park Pl Ave',
  address2: null,
  city: 'Fort Worth',
  state: 'TX',
  zip: '76110',
  category: 'Research & Development',
  type: 'Surgery Center',
  latitude: '32.72726',
  longitude: '-97.34698',
  photo: 'mloc-181.png',
  speciality: 'Endovascular',
}
