export const mloc1 = {
  id: 'MLOC-1',
  name: "St. Mary's Medical Center",
  description:
    'St. Mary’s Medical Center is a 464-bed acute care hospital located at 901 45th Street in West Palm Beach. The hospital has been serving the medical and health care needs of the greater West Palm Beach area for more than 70 years.   St. Mary’s Medical Center offers a broad array of adult and pediatric specialty services that include: a 24/7 adult and pediatric emergency and trauma center; the Institute for Advanced Orthopedics, the Rehabilitation Institute, the Memory Disorders Center, the Kaplan Cancer Center, the Wound Healing and Hyperbaric Center, the Schwartz Kidney Dialysis Center, and the Institute for Mental Health.   Along with 24/7 dedicated Pediatric Emergency and Trauma care, St. Mary’s Medical Center offers some of the most specialized pediatric services that include limb reconstruction and lengthening at the Paley Advanced Limb Lengthening Institute; pediatric cardiology and cardiovascular surgery, including the congenital heart surgery program; pediatric oncology program which includes membership into Children’s Oncology Group - a National Cancer Institute supported clinical trials group, pediatric neurosurgery; and pediatric orthopedics.   St. Mary’s Medical Center is fully accredited by the Joint Commission, the nation’s oldest and largest hospital accreditation agency. The hospital has won many prestigious awards, including the Tenet Circle of Excellence award three out of the last four years.',
  status: '07-Event Ready (ES)',
  address1: '901 45th Street',
  address2: null,
  city: 'West Palm Beach',
  state: 'FL',
  zip: '33407',
  category: 'Commercial',
  type: 'Hospital',
  latitude: '26.75674',
  longitude: '-80.06406',
  photo: 'mloc-1.png',
  speciality: 'Endovascular',
}
