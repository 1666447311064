import React, { useState } from 'react'
import { Marker } from 'react-map-gl'
import { locationsChips } from '../../../../constants/data'

export const Markers = ({
  latitude,
  longitude,
  type,
  id,
  handleOnMarkerHovered,
  handleOnMarkerUnHovered,
  handleOnLocationClick,
}) => {
  return (
    <div
      onMouseEnter={() => handleOnMarkerHovered(id)}
      onMouseLeave={handleOnMarkerUnHovered}
      onClick={() => handleOnLocationClick(id)}
    >
      <Marker offsetLeft={-10} latitude={+latitude} longitude={+longitude}>
        <img style={{ height: '25%', width: '25%' }} src={locationsChips[type].icon} />
      </Marker>
    </div>
  )
}
