import { specialties } from '../../../utils/specialty'
import { cdnUrl } from '../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorCardPicture,
  getInstructorEvents,
} from '../../../utils/getAssetLink'

const data = [
  `Dr. Elder graduated from the American University of the Caribbean School of Medicine in Coral Gables, Florida. He then completed his residency at Wayne State University in Detroit, Michigan, and two fellowships in cardiology and interventional cardiology at Detroit Medical Center.`,
  `A recognized expert in the field of cardiology, Dr. Elder has developed many clinical trials. Over the years, he has also performed research on endovascular procedures and complex vascular cases. He serves as an assistant clinical professor of medicine at Wayne State University's School of Medicine and Michigan State University College of Human Medicine in East Lansing. `,
  `Dr. Elder is a highly requested speaker at international and national medical conferences. He was named Wayne State University School of Medicine's Teacher of the Year and Mentor of the Year, Crain's Healthcare Hero, Hour Magazine's Top Doctor, and has earned a People's Choice Award for Compassionate Doctor Recognition. `,
]

const specialty = specialties.endovascular.name
const id = 'elder'
export const elderData = {
  firstName: 'Mahir',
  lastName: 'Elder',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'MAHIR ELDER, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  profileCover: getInstructorProfileCover(id),
  category: specialty,
  shortVideoCard: getInstructorCardPicture(id),

  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
