import React from 'react'
import RoundedImage from '../RoundedImage/RoundedImage'
import styles from './MemberDetails.module.scss'

function Jon() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.memberImageContainer}>
        <img style={{ width: ' 12.8125vw', height: '12.8125vw' }} src="/jon.png" />
      </div>

      <div className={styles.mobileContainer}>
        <div className={styles.imageM}>
          <img style={{ width: ' 100px', height: '100px' }} src="/jon.png" />
        </div>
        <div className={styles.DetailsM}>
          <div className={styles.memberNameM}>Jon Clagg</div>

          <div className={styles.memberRoleM}>CTO</div>
          <div className={styles.socialIconsM}>
            <img src="/icons/facebook.png" />
            <img src="/icons/instagram.png" />
            <img src="/icons/linkedin.png" />
          </div>
        </div>
      </div>

      <div className={styles.memberDetails}>
        <div className={styles.memberName}>Jon Clagg</div>
        <div className={styles.memberText1}>
          Jon Clagg is the co-founder and CTO of Immertec. He is a visionary full-stack developer
          with over a decade of experience in automation and telecommunications. In 2012, Jon
          received one of the early access developer kits from the Oculus Kickstarter campaign. He
          started as an enthusiast, but his ambition quickly grew. Even at this early stage of the
          industry, Jon had two profound intuitions about the future of VR. Similar to the TV,
          internet, and cellphone industries, this industry would be led by companies that focus on
          creating real-world business value first. He also knew VR was going to be much more than
          video games. This intuition motivated him to leave his 6-figure job and commit fully to
          developing his vision over the next nine years.
          <br /> <br /> After leveraging the many reports from Goldman Sachs, Citi Bank, and a
          myriad of other SEC-compliant industry reports, Jon identified training as the best and
          most obvious way to create this business value. Diving deep into what it took to develop
          3D training content in VR, it was immediately evident that the current methods were too
          slow, laborious, and subsequently lacked scalability.{' '}
        </div>
        <div className={styles.memberQuote}>
          "Jon knew that creating scalable business value through training in VR would require
          technology to create 3D content and connect people at the speed of business."{' '}
        </div>
        <div className={styles.memberText2}>
          This was the basis of the IP he built, allowing companies to disrupt the way they train.
          Companies leveraging this technology can avoid large development or production teams and
          provide customers and employees the ability to train in VR from real-world, real-time
          training opportunities.
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.memberRole}>CTO</div>
        <div className={styles.socialIcons}>
          <img src="/icons/facebook.png" />
          <img src="/icons/instagram.png" />
          <img src="/icons/linkedin.png" />
        </div>
      </div>
    </div>
  )
}

export default Jon
