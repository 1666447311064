import Grid from '@mui/material/Grid'
import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import LeftArrow from './components/leftArrow'
import RightArrow from './components/rightArrow'
import styles from './physicians.module.scss'

import FullScreenVideo from '../Fullscreen/FullScreenVideo'
import PausedOverlay from './components/pausedOverlay.js'
// import { dataForCoverSlider } from '../../constants/data/coverSliderData'
import 'swiper/components/effect-coverflow/effect-coverflow.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import { specialties } from '../../utils/specialty'

let settings = {
  dots: false,
  speed: 500,

  slidesToShow: 4,
  infinite: false,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },

    {
      breakpoint: 900,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 3,
        infinite: true,
      },
    },
  ],
  nextArrow: <LeftArrow />,
  prevArrow: <RightArrow />,
}

function Physician({ instructors }) {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const currentSlider = (instructorLength, filteredInstructor) => {
    if (instructorLength) {
      if (instructorLength >= 4) {
        return (
          <div className={styles.swiperSliderContainer} id="exploreInstructor" key={instructors.id}>
            <Swiper
              loop={true}
              key={instructors.id}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 2,
                },
                600: {
                  slidesPerView: 3,
                  // spaceBetween: 40,
                },

                900: {
                  slidesPerView: 4,
                  // spaceBetween: 60,
                },
                1100: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              preventClicks={true}
            >
              {filteredInstructor?.map((elem) => (
                <SwiperSlide key={elem.id}>
                  <div
                    key={elem.id}
                    // onMouseEnter={handleOnMobileHoverEnter}
                    // onMouseLeave={handleOnMobileHoverLeave}
                    className={styles.itemSlider}
                  >
                    <FullScreenVideo
                      video={elem.shortVideoLink}
                      id={elem.id}
                      route={elem.profileLink}
                      PausedOverlay={
                        <PausedOverlay
                          image={elem.shortVideoCard}
                          name={elem.title}
                          subTitle={specialties[elem.category]?.label}
                          icon={specialties[elem.category].greenIcon}
                        />
                      }
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div ref={navigationPrevRef}>
              <RightArrow />
            </div>
            <div className="swiper-button-next-btn-2" ref={navigationNextRef}>
              <LeftArrow />
            </div>
          </div>
        )
      }
      if (instructorLength === 3) {
        return (
          <div
            className={styles.swiperSliderContainer3}
            key={instructors.id}
            id="exploreInstructor3"
          >
            <Swiper
              loop={true}
              key={instructors.id}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 2,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 3,
                  // spaceBetween: 40,
                },

                900: {
                  slidesPerView: 3,
                  // spaceBetween: 60,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
              preventClicks={true}
            >
              {filteredInstructor?.map((elem) => (
                <SwiperSlide key={elem.id}>
                  <div
                    key={elem.id}
                    // onMouseEnter={handleOnMobileHoverEnter}
                    // onMouseLeave={handleOnMobileHoverLeave}
                    className={styles.itemSlider}
                  >
                    <FullScreenVideo
                      video={elem.shortVideoLink}
                      id={elem.id}
                      route={elem.profileLink}
                      PausedOverlay={
                        <PausedOverlay
                          image={elem.shortVideoCard}
                          name={elem.title}
                          subTitle={specialties[elem.category]?.label}
                          icon={specialties[elem.category].greenIcon}
                        />
                      }
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div ref={navigationPrevRef}>
              <RightArrow />
            </div>
            <div className="swiper-button-next-btn-2" ref={navigationNextRef}>
              <LeftArrow />
            </div>
          </div>
        )
      }
      if (instructorLength === 2) {
        return (
          <div
            className={styles.swiperSliderContainer2}
            key={instructors.id}
            id="exploreInstructor2"
          >
            <Swiper
              loop={true}
              key={instructors.id}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 2,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                  // spaceBetween: 40,
                },

                900: {
                  slidesPerView: 2,
                  // spaceBetween: 60,
                },
                1100: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              preventClicks={true}
            >
              {filteredInstructor?.map((elem) => (
                <SwiperSlide key={elem.id}>
                  <div
                    key={elem.id}
                    // onMouseEnter={handleOnMobileHoverEnter}
                    // onMouseLeave={handleOnMobileHoverLeave}
                    className={styles.itemSlider}
                  >
                    <FullScreenVideo
                      video={elem.shortVideoLink}
                      id={elem.id}
                      route={elem.profileLink}
                      PausedOverlay={
                        <PausedOverlay
                          image={elem.shortVideoCard}
                          name={elem.title}
                          subTitle={specialties[elem.category]?.label}
                          icon={specialties[elem.category].greenIcon}
                        />
                      }
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div ref={navigationPrevRef}>
              <RightArrow />
            </div>
            <div className="swiper-button-next-btn-2" ref={navigationNextRef}>
              <LeftArrow />
            </div>
          </div>
        )
      }
      if (instructorLength === 1) {
        return (
          <div className={styles.sliderWidth}>
            <Grid xs={10} md={8} lg={7.5} id="instructor-slider">
              <Slider {...settings} key={instructors.id} className="instructor-slider">
                {filteredInstructor?.map((elem) => (
                  <div
                    key={elem.id}
                    className={styles.sliderItemContainer}
                    // onMouseEnter={handleOnMobileHoverEnter}
                    // onMouseLeave={handleOnMobileHoverLeave}
                  >
                    <div className={styles.itemSlider} key={elem.id}>
                      <FullScreenVideo
                        video={elem.shortVideoLink}
                        id={elem.id}
                        route={elem.profileLink}
                        PausedOverlay={
                          <PausedOverlay
                            image={elem.shortVideoCard}
                            name={elem.title}
                            subTitle={specialties[elem.category]?.label}
                            icon={specialties[elem.category].greenIcon}
                          />
                        }
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </Grid>
          </div>
        )
      }
    }
  }

  const sliderRef = currentSlider(instructors?.length, instructors)

  return (
    <div className={styles.container}>
      <div className={styles.sectionTitle}>Explore instructors in this location</div>
      <div className={styles.sliderItemContainer}>{sliderRef}</div>
    </div>
  )
}

export default Physician
