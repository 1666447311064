export const mloc352 = {
  id: 'MLOC-352',
  name: 'MERI Genesis',
  description:
    'As a non-profit teaching and training bioskills lab, the Medical Education & Research Institute (MERI) supports essential medical training for the greater good.\n\nOur central U.S. location and off-site availability allow us to facilitate state-of-the-art, hands-on educational courses for physicians, nurses, and other medical professionals from across the country and around the world. Our unique ability to coordinate all aspects of training is made possible in part by our exclusive partnership with the Genesis Legacy Whole Body Donation Foundation.',
  status: '07-Event Ready (ES)',
  address1: '44 South Cleveland',
  address2: null,
  city: 'Memphis',
  state: 'TN',
  zip: '38104',
  category: 'Pilot',
  type: 'Bioskills Lab',
  latitude: '35.13749',
  longitude: '-90.01635',
  photo: 'mloc-352.png',
  speciality: 'Urology',
}
