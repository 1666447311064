export const mloc1963 = {
  id: 'MLOC-1963',
  name: 'Ozark Regional Vein and Artery Center',
  description:
    'We take great pride in the fact that we are the only practice in Northwest Arkansas that provides all the highest available quality vein treatment, and we focus only on vein treatment.\nOzark Regional Vein & Artery Center has grown from five employees in 2013 to 45+ employees today.\nOzark Regional Vein & Artery Center believes in continuing medical education for our professional staff and strives to be up to date on the latest treatment options to provide patients with the best quality care. We are ecstatic to offer a more comprehensive overall experience with the addition of arterial care.',
  status: '07-Event Ready (ES)',
  address1: '5433 W Walsh Ln',
  address2: null,
  city: 'Rogers',
  state: 'Arkansas',
  zip: '72758',
  category: 'Commercial',
  type: 'Office Based Lab',
  latitude: '36.31126',
  longitude: '-94.18989',
  photo: 'mloc-1963.png',
  speciality: 'Orthopedics',
}
