import React from 'react'
import styles from './LocationInterestedBanner.module.scss'
import ContainedButton from '../ContainedButton'
import getUrlForm from '../../utils/getFormUrl'
import { Link } from 'react-router-dom'

function LocationInterestedBanner() {
  return (
    <div className={styles.container} style={{ background: '#FFF' }}>
      <div className={styles.itemContainer}>
        <div className={styles.textContainer}>Are you interested in contacting this location?</div>
        <div className={styles.Dbutton}>
          <Link to="/request-a-demo">
            <ContainedButton
              customStyles={{
                width: '12.03125vw',
                height: '2.998389694041868vw',
                backgroundColor: '#47D7AC',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Bold',
                fontSize: '1.0416666666666667vw',
                fontWeight: '100',
                color: '#243746',
              }}
              label={'Request a Demo'}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LocationInterestedBanner
