import React, { useState, useEffect } from 'react'
import styles from './ReadyToLearnMore.module.scss'
// import HoverVideoPlayer from 'react-hover-video-player';
import HoverVideoPlayer from '../HoverVideoPlayer'
import OutlineButton from '../OutlineButton'
import HoverOverlay from './component/hoverOverlay'
import { cdnUrl } from '../../constants/enum'
import { useInView } from 'react-intersection-observer'
import { useMute } from '../../providers/MuteContextProvider'

function ReadyToLearnMore({ onClick: sendToComponent }) {
  // const [muted, setMuted] = useState(true);
  const { muted, onMuteClick } = useMute()

  const cultureVideoPath = `${cdnUrl}/other/culturevideo.mp4`
  // const onMuteClick = () => {
  //   setMuted(!muted);
  // };

  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const { ref, inView, entry } = useInView({
    threshold: 0.7,
  })

  useEffect(() => {
    setIsVideoPlaying(inView)
  }, [inView])

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer} ref={ref}>
        <HoverVideoPlayer
          className={styles.hoverPlayerClass}
          videoSrc={cultureVideoPath}
          focused={isVideoPlaying}
          muted={muted}
          pausedOverlay={
            <img
              src="/icons/ReadyToLearnMore.png"
              style={{ height: '101%', width: '100%' }}
              alt={''}
            />
          }
          hoverOverlay={<HoverOverlay onMuteClick={onMuteClick} muted={muted} />}
        />
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.Text}>Ready to learn more?</div>
        <div className={styles.btnContainer}>
          <OutlineButton
            customStyles={{
              height: '2.9166666666666665vw',
              width: '21.822916666666668vw',
            }}
            textStyles={{
              fontFamily: 'Gilroy-Medium',
              fontSize: '1.0416666666666667vw',
              lineHeight: '1.25vw',
              textAlign: 'center',
              color: ' #47D7AC',
            }}
            label={'Take Me To Your Open Roles'}
            handleClick={sendToComponent}
          />
        </div>
        <div className={styles.MbtnContainer}>
          <OutlineButton
            customStyles={{ height: '41px', width: '303px' }}
            textStyles={{
              fontFamily: 'Gilroy-Medium',
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'center',
              color: ' #47D7AC',
            }}
            label={'Take Me To Your Open Roles'}
          />
        </div>
      </div>
    </div>
  )
}

export default ReadyToLearnMore
