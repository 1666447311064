import React, { useRef } from 'react'
import AboutIntro from './components/AboutIntro'
import AreYouInterested from './components/AreYouInterestedBanner'
import EcsSection from './components/EcsSection'
import FAQS from './components/FAQS/FAQS'
import Footer from './components/Footer/Footer'
import BurgerMenu from './components/Header/burgerMenu'
import Header from './components/Header/Header'
import InstructorSlider from './components/instructorSlider'
import SCYPTP from './components/SCYPTP'
import useCheckMobileScreen from './utils/getPlatform'
import { withRouter } from './utils/withRouter'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'

function InstructorDetailsPage({ router: { params } }) {
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const upcomingEventsReferenceDesktop = useRef()
  const upcomingEventsReferenceMobile = useRef()
  const platform = useCheckMobileScreen()

  const upcomingEventsClick = () => {
    if (platform == 'mobile')
      upcomingEventsReferenceMobile.current.scrollIntoView({ behavior: 'smooth' })
    else upcomingEventsReferenceDesktop.current.scrollIntoView({ behavior: 'smooth' })
  }

  const { id } = params
  const [width] = useWindowSize()

  return (
    <div>
      <BurgerMenu />
      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}

      <InstructorSlider instructorId={id} sendToComponent={upcomingEventsClick} />
      <AboutIntro instructorId={id} reference={upcomingEventsReferenceDesktop} />
      <EcsSection instructorId={id} reference={upcomingEventsReferenceMobile} />
      {/* <Physicians instructorId={id} /> */}
      {/* <AreYouInterested
        bannerTitle={'Join the Immertec instructor team'}
        btnTitle={'Apply Here'}
        btnUrl={'host'}
        bgcolor={'#F6F7FB'}
      /> */}
      <SCYPTP />
      <FAQS />
      <Footer />
    </div>
  )
}

export default withRouter(InstructorDetailsPage)
