import React from 'react'
import styles from './TOStext.module.scss'

function TOStext() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.Heading}>Terms of Services</div>

      <div className={styles.textContainer}>
        <div className={styles.mainText}>IMMERSIVE TECH INC.</div>
        <div className={styles.mainText}>END USER LICENSE TERMS</div>
        <p className={styles.subText}>
          These Immersive Tech Inc. End User License Terms (these <strong>“Terms”</strong>) govern
          your access and use of the surgical training software, mobile application, and the live
          surgical feeds made available therein (the<strong> “Licensed Application”</strong>)
          provided by Immersive Tech Inc. in connection with the agreement between us and the
          institution with which you are employed or otherwise affiliated (your “Institution”)
          authorizing you to access and use the Licensed Application.{' '}
          <strong>
            By creating an account or by using the Licensed Application, you agree to these Terms,
            including the mandatory arbitration provision and class action waiver in Section 11. If
            you do not agree to these Terms, do not use the Licensed Application
          </strong>
          . These Terms are effective as of the date you first access the Licensed Application and
          remain effective until your access to the Licensed Application has been terminated under
          the terms of the agreement between us and your Institution authorizing you to access and
          use the Licensed Application. Notwithstanding any terms to the contrary in that agreement,
          we reserve the right to modify the Licensed Application or to suspend or stop providing
          all or portions of the Licensed Application at any time. You also have the right to stop
          using the Licensed Application at any time. We are not responsible for any loss or harm
          related to your inability to access or use the Licensed Application, including, without
          limitation, if your inability to access or use the Licensed Application results from
          termination or expiration of the agreement between us and your Institution authorizing you
          to access and use the Licensed Application. Please contact your Institution if your access
          or use of the Licensed Application is suspended or terminated.
        </p>
        <div className={styles.mainText}>1. Privacy</div>
        <p className={styles.subText}>
          We will collect personal information from you in connection with your use of the Licensed
          Application. Any personal information you provide is subject to the terms of our Privacy
          Policy. Please refer to our Privacy Policy for more information about how we collect, use,
          and disclose personal information about you.
        </p>
        <div className={styles.mainText}>2. Accounts</div>
        <p className={styles.subText}>
          You need to register for an account to access the Licensed Application. You must provide
          accurate account information and promptly update this information if it changes. You also
          must maintain the security of your account and promptly notify us if you discover or
          suspect that someone has accessed your account without your permission. You are
          responsible for all activities that occur in connection with your account.{' '}
        </p>
        <div className={styles.mainText}>3. Third-Party Services </div>
        <p className={styles.subText}>
          The Licensed Application integrates with certain third-party products, services, websites,
          links, content, or applications (“Third-Party Services”). By using the Licensed
          Application, you grant us permission to access Third-Party Services on your device for us
          to provide the Licensed Application’s functionality to you. We integrate Third-Party
          Services into the Licensed Application solely as a convenience to you. Your dealings or
          correspondence with third parties and your use of or interaction with any Third-Party
          Services are solely between you and the third party. We do not control or endorse, and
          make no representations or warranties regarding, any Third-Party Services, and your access
          to and use of such Third-Party Services is at your own risk.{' '}
        </p>
        <div className={styles.mainText}>4. Patient Privacy </div>
        <p className={styles.subText}>
          You will not use the Licensed Application in a manner that violates patient privacy rights 
          or compromises the confidentiality of patient information. This includes, but is not limited 
          to, the unauthorized use, disclosure, or recording of personal health information encountered 
          through live surgeries or other medical content provided via the Licensed Application. 
          
          You agree to adhere to all applicable laws and regulations governing the privacy, security, 
          and confidentiality of medical and health information, including but not limited to the 
          Health Insurance Portability and Accountability Act (HIPAA) in the United States, or similar 
          legislation in other jurisdictions. You are responsible for implementing and maintaining 
          appropriate safeguards to protect the confidentiality, integrity, and availability of such 
          sensitive health information and for ensuring that any use of patient data in connection with 
          the Licensed Application is conducted in a lawful and ethical manner. Violation of this clause 
          may result in immediate termination of your access to the Licensed Application and may subject 
          you to legal penalties and liabilities.
        </p>
        <div className={styles.mainText}>5. Restrictions </div>
        <p className={styles.subText}>
          You will not violate any applicable law, contract, intellectual property right, or other
          third-party right or commit a tort, and you are solely responsible for your conduct while
          using the Licensed Application. You will not:
          <ul style={{ margin: '30px' }}>
            <li>
              modify, disclose, alter, translate, or create derivative works of the Licensed
              Application (or any components thereof)
            </li>
            <li>
              license, sublicense, resell, distribute, lease, rent, lend, transfer, assign, or
              otherwise dispose of the Licensed Application (or any components thereof)
            </li>
            <li>
              disassemble, decompile, or reverse engineer the software components of the Licensed
              Application
            </li>
            <li>
              use the Licensed Application to store or transmit infringing, libelous, or otherwise
              unlawful or tortious material, or to store or transmit material in violation of
              third-party privacy rights
            </li>
            <li>
              use the Licensed Application to store or transmit any viruses, software routines or
              other code designed to permit unauthorized access, disable, erase, or otherwise harm
              software, hardware, or data, or to perform any other harmful actions
            </li>

            <li>copy, frame, or mirror any part or content of the Licensed Application </li>
            <li>
              build a competitive product or service, or copy any features or functions of the
              Licensed Application
            </li>
            <li>
              attempt to gain unauthorized access to the Licensed Application or their related
              systems or networks
            </li>
            <li>
              remove, alter, or obscure any proprietary notices in or on the Licensed Application,
              including copyright notices
            </li>
            <li>
              interfere in any manner with any other user’s enjoyment of the Licensed Application,
              or
            </li>
            <li>cause or permit any third party to do any of the foregoing.</li>
          </ul>
        </p>
        <div className={styles.mainText}>6. Application Data</div>
        <p className={styles.subText}>
          We may collect, use, reproduce, display, aggregate, modify and create derivative works of
          data and content related to your use of the Licensed Application, including data and
          content input into the Licensed Application by you and technical and usage data (
          <strong>“Application Data”</strong>) to permit us to understand, improve and provide the
          Licensed Application and related data analytics and reporting to you and for other
          Immertec business purposes subject to the licenses below. You grant to Immertec:{' '}
          <ul style={{ margin: '30px' }}>
            <li>
              a perpetual, irrevocable, worldwide, non-exclusive license to use, reproduce,
              aggregate (including with other users’ data), publish, display and distribute any
              anonymous information or data derived from Application Data for any purpose, and{' '}
            </li>
            <li>
              a perpetual, irrevocable, worldwide, non-exclusive license to use, reproduce, publish,
              display and distribute any information or data derived from Application Data in any
              manner on behalf of you, your Institution, or your Institution’s designees and as may
              be necessary for us to provide you and your Institution with the Licensed Application
              and/or other services, including, without limitation, additional services as may be
              requested by you or your Institution and agreed upon by Immertec.
            </li>
          </ul>
          You may not create, post, upload, store, or share any Application Data that violates these
          Terms or for which you do not have all the rights necessary to grant us the license
          described above. You represent and warrant that any Application Data posted by you, and
          our use of such Application Data as permitted by these Terms, will not violate any rights
          of or cause injury to any person or entity. Although we have no obligation to screen, edit
          or monitor Application Data, we may delete or remove Application Data at any time and for
          any reason with or without notice{' '}
        </p>
        <div className={styles.mainText}>7. Ownership; Limited License</div>
        <p className={styles.subText}>
          The Licensed Application, including the text, graphics, images, photographs, videos,
          illustrations and other content contained therein, are owned by us or our licensors and
          are protected under both United States and foreign laws. Except as explicitly stated in
          these Terms, all rights in and to the Licensed Application are reserved by us or our
          licensors. Subject to your compliance with these Terms, you are hereby granted a limited,
          nonexclusive, nontransferable, non-sublicensable, revocable license to access and use the
          Licensed Application for your own personal, noncommercial use. Any use of the Licensed
          Application other than as specifically authorized herein, without our prior written
          permission, is strictly prohibited, will terminate the license granted herein, and will
          violate our intellectual property rights.{' '}
        </p>
        <div className={styles.mainText}>8. Trademarks </div>
        <p className={styles.subText}>
          The Licensed Application name and logo and the look and feel of the Licensed Application
          are our trademarks and may not be copied, imitated, or used, in whole or in part, without
          our prior written permission. All other trademarks, registered trademarks, product names,
          and company names or logos mentioned on the Licensed Application are the property of their
          respective owners. Reference to any products, services, processes, or other information by
          trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply
          endorsement, sponsorship or recommendation by us.
        </p>
        <div className={styles.mainText}>9. Feedback</div>
        <p className={styles.subText}>
          Any suggestions, comments, or other feedback provided by you to us with respect to The
          Licensed Application or us (collectively, “Feedback”) will constitute our confidential
          information. We will be free to use, disclose, reproduce, license, and otherwise
          distribute and exploit the Feedback provided to it as we see fit, entirely without
          obligation or restriction of any kind, on account of intellectual property rights or
          otherwise.{' '}
        </p>
        <div className={styles.mainText}>10. Indemnification </div>
        <p className={styles.subText}>
          You agree, at your sole expense, to defend, indemnify and hold us (and our directors,
          officers, employees, consultants, and agents) harmless from and against any and all actual
          or threatened suits, actions, proceedings (at law or in equity), claims, damages,
          payments, deficiencies, fines, judgments, settlements, liabilities, losses, costs, and
          expenses (including, but not limited to, reasonable attorneys’ fees, costs, penalties,
          interest, and disbursements) that we (or our directors, officers, employees, consultants,
          or agents, individually or collectively) incur and are caused by, arise out of, result
          from, are attributable to, or are in any way incidental to any of your conduct or any
          actual or alleged breach of any of your obligations under these Terms.{' '}
        </p>
        <div className={styles.mainText}>11. Disclaimers and Limitation of Liability</div>
        <p className={styles.subText}>
          YOUR USE OF THE LICENSED APPLICATION AND ANY THIRD-PARTY SERVICES IS AT YOUR SOLE RISK.
          THE LICENSED APPLICATION AND SUCH THIRD-PARTY SERVICES ARE PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS. WE DISCLAIM ALL WARRANTIES AND REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL
          OR WRITTEN) WITH RESPECT TO THE LICENSED APPLICATION OR ANY THIRD-PARTY SERVICES, WHETHER
          ALLEGED TO ARISE BY OPERATION OF LAW, BY REASON OF CUSTOM OR USAGE IN THE TRADE, BY COURSE
          OF DEALING, OR OTHERWISE, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR
        </p>
        <p className={styles.subText}>
          ANY PURPOSE, NON-INFRINGEMENT, AND CONDITION OF TITLE. TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW, WE WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INCIDENTAL,
          SPECIAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHATSOEVER ARISING OUT OF
          OR RELATED TO THE LICENSED APPLICATION OR ANY THIRD-PARTY SERVICES, HOWEVER CAUSED,
          REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE,
          WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY, OR OTHER
          THEORY), EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.{' '}
        </p>
        <p className={styles.subText}>
          IN NO EVENT WILL OUR AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THE LICENSED
          APPLICATION EXCEED ONE HUNDRED U.S. DOLLARS (USD $100.00).{' '}
        </p>
        <div className={styles.mainText}>12. Dispute Resolution; Binding Arbitration </div>
        <p className={styles.subText}>
          <strong>
            PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN
            DISPUTES AND CLAIMS WITH US AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US
            UNLESS YOU OPT OUT OF ARBITRATION BY FOLLOWING THE INSTRUCTIONS SET FORTH BELOW. NO
            CLASS OR REPRESENTATIVE ACTIONS OR ARBITRATIONS ARE ALLOWED UNDER THIS ARBITRATION
            AGREEMENT. IN ADDITION, ARBITRATION PRECLUDES YOU FROM SUING IN COURT OR HAVING A JURY
            TRIAL.{' '}
          </strong>{' '}
        </p>
        <p className={styles.subText}>
          In the event of any controversy or claim arising out of or relating in any way to these
          Terms or The Licensed Application, each of us agrees to consult and negotiate with the
          other and, recognizing our mutual interests, try to reach a solution satisfactory to both
          parties. If we do not reach a settlement within a period of 60 days, then either of us
          may, by notice to the other, demand mediation under the mediation rules of the American
          Arbitration Association in Tampa, Florida.
        </p>
        <p className={styles.subText}>
          We both give up our right to litigate our disputes and may not proceed to arbitration
          without first trying mediation, but neither party is required to arbitrate any dispute in
          which either party seeks equitable and other relief from the alleged unlawful use of
          copyrights, trademarks, trade names, logos, trade secrets or patents. Except as otherwise
          required under applicable law, each of us intends and agrees (a) not to assert class
          action or representative action procedures and agrees that they will not apply in any
          arbitration involving the other, (b) not to assert class action or representative action
          claims against the other in arbitration or otherwise, and (c) that each of us will only
          submit individual claims in arbitration and will not seek to represent the interests of
          any other person or entity.
        </p>
        <p className={styles.subText}>
          If a settlement is not reached within 60 days after service of a written demand for
          mediation, any unresolved controversy or claim will be resolved by arbitration in
          accordance with the rules of the American Arbitration Association before a single
          arbitrator in Tampa, Florida. The language of all proceedings and filings will be English.
          The arbitrator will render a written opinion including findings of fact and law and the
          award and/or determination of the arbitrator will be binding on the parties, and their
          respective administrators and assigns, and will not be subject to appeal. Judgment may be
          entered upon the award of the arbitrator in any court of competent jurisdiction.{' '}
        </p>
        <p className={styles.subText}>
          The expenses of the arbitration will be shared equally by the parties unless the
          arbitrator determines that the expenses will be otherwise assessed, and the prevailing
          party may be awarded its attorneys’ fees and expenses by the arbitrator. It is the intent
          of the parties that, barring extraordinary circumstances, arbitration proceedings will be
          concluded within 90 days from the date the arbitrator is appointed. The arbitrator may
          extend this time limit only if failure to do so would unduly prejudice the rights of the
          parties. Failure to adhere to this time limit will not constitute a basis for challenging
          the award. Consistent with the expedited nature of arbitration, pre-hearing information
          exchange will be limited to the reasonable production of relevant, non-privileged
          documents, carried out expeditiously.{' '}
        </p>
        <p className={styles.subText}>
          <strong>
            You have the right to opt-out of binding arbitration within 30 days of the date you
            first accepted the terms of this arbitration agreement by sending an email to{' '}
          </strong>
          <a href="mailto:legal@immertec.com">legal@immertec.com</a>. In order to be effective, the
          opt-out notice must include your full name and address and clearly indicate your intent to
          opt-out of binding arbitration. By opting out of binding arbitration, you are agreeing to
          resolve disputes in accordance with Section 12 of these Terms.
        </p>
        <p className={styles.subText}>
          If any portion of this arbitration agreement is found to be unenforceable or unlawful for
          any reason, (i) the unenforceable or unlawful provision will be severed from these Terms,
          (ii) severance of the unenforceable or unlawful provision will have no impact whatsoever
          on the remainder of this arbitration agreement or the parties’ ability to compel
          arbitration of any remaining claims on an individual basis pursuant to this arbitration
          agreement, and (iii) to the extent that any claims must, therefore, proceed on a class,
          collective, consolidated, or representative basis, such claims will be litigated in a
          civil court of competent jurisdiction and not in arbitration, and the parties agree that
          litigation of those claims will be stayed pending the outcome of any individual claims in
          arbitration. Further, if any part of this arbitration agreement is found to prohibit an
          individual claim seeking public injunctive relief, that provision will have no effect to
          the extent such relief is allowed to be sought out of arbitration, and the remainder of
          this arbitration agreement will be enforceable.{' '}
        </p>
        <div className={styles.mainText}>13. Miscellaneous </div>
        <p className={styles.subText}>
          Any dispute arising from these Terms and your use of the Licensed Application will be
          governed by and construed and enforced in accordance with the laws of the State of
          Florida, except to the extent preempted by U.S. federal law, without regard to conflict of
          law rules or principles (whether of the State of Florida or any other jurisdiction) that
          would cause the application of the laws of any other jurisdiction. Any dispute between the
          parties that is not subject to arbitration or cannot be heard in small claims court will
          be resolved in the state or federal courts of the State of Florida and the United States,
          respectively, sitting in Hillsborough County, Florida.{' '}
        </p>
        <p className={styles.subText}>
          These Terms constitute the entire agreement between the parties regarding your access to
          and use of the Licensed Application. If any provision or part of a provision of these
          Terms is unlawful, void, or unenforceable, that provision or part of the provision is
          deemed severable from these Terms and does not affect the validity and enforceability of
          any remaining provisions. Notwithstanding any other provisions of these Terms, Sections 4
          through 13 of these Terms survive any expiration or termination of these terms. Our
          failure to exercise or enforce any right or provision of these Terms will not operate as a
          waiver of such right or provision. The section titles in these Terms are for convenience
          only and have no legal or contractual effect. Except as otherwise provided herein, these
          Terms are intended solely for the benefit of the parties and are not intended to confer
          third-party beneficiary rights upon any other person or entity.{' '}
        </p>
        <p className={styles.subText}>
          You agree that communications and transactions between us may be conducted electronically.
          These Terms may not be transferred, assigned or delegated by you, by operation of law or
          otherwise, without our prior written consent, and any attempted transfer, assignment or
          delegation without such consent will be void and without effect. We may freely transfer,
          assign or delegate these Terms or The Licensed Application, in whole or in part, without
          your prior written consent.{' '}
        </p>
        <p className={styles.subText}>
          If you are a California resident, you may have these Terms mailed to you electronically by
          sending a letter to 220 W 7th Ave STE210, Tampa, FL 33602 with your electronic mail
          address and a request for these Terms. Under California Civil Code Section 1789.3,
          California Website users are entitled to the following specific consumer rights notice:
          The Complaint Assistance Unit of the Division of Consumer Services of the California
          Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite
          S-202, Sacramento, California 95834, or by telephone at (800) 952-5210.{' '}
        </p>
        <div className={styles.mainText}>14. Additional Terms Applicable to Mobile Devices </div>
        <p className={styles.subText}>
          The following terms apply if you install, access, or use the Licensed Application on any
          device that contains the iOS mobile operating system (the<strong> “iOS App”</strong>)
          developed by Apple Inc. (<strong>“Apple”</strong>).{' '}
          <ul style={{ margin: '30px' }}>
            <li>
              Acknowledgment. You acknowledge that these Terms are concluded solely between you and
              us, and not with Apple, and we, not Apple, are solely responsible for the iOS App and
              the content thereof. You further acknowledge that the usage rules for the iOS App are
              subject to any additional restrictions set forth in the Usage Rules for the Apple iOS
              App Store Terms of Service as of the date you download the iOS App, and in the event
              of any conflict, the Usage Rules in the App Store will govern if they are more
              restrictive. You acknowledge and agree that you have had the opportunity to review the
              Usage Rules.{' '}
            </li>
            <li>
              Scope of License. The license granted to you is limited to a non-transferable license
              to use the iOS App on any iPhone, iPod touch, or iPad that you own or control as
              permitted by the Usage Rules set forth in the Apple App Store Terms of Service.{' '}
            </li>
            <li>
              Maintenance and Support. The parties acknowledge that Apple has no obligation
              whatsoever to furnish any maintenance and support services with respect to the iOS
              App.{' '}
            </li>
            <li>
              Warranty. You acknowledge that Apple is not responsible for any product warranties,
              whether express or implied by law, with respect to the iOS App. In the event of any
              failure of the iOS App to conform to any applicable warranty, you may notify Apple,
              and Apple will refund the purchase price, if any, paid to Apple for the iOS App by
              you; and to the maximum extent permitted by applicable law, Apple will have no other
              warranty obligation whatsoever with respect to the iOS App. The parties acknowledge
              that to the extent that there are any applicable warranties, any other claims, losses,
              liabilities, damages, costs or expenses attributable to any failure to conform to any
              such applicable warranty would be our sole responsibility. However, you understand and
              agree that in accordance with these Terms, we have disclaimed all warranties of any
              kind with respect to the iOS App, and therefore, there are no warranties applicable to
              the iOS App.{' '}
            </li>
            <li>
              Product Claims. The parties acknowledge that as between Apple and us, we, not Apple,
              are responsible for addressing any claims relating to the iOS App or your possession
              and/or use of the iOS App, including, but not limited to (a) product liability claims,
              (b) any claim that the iOS App fails to conform to any applicable legal or regulatory
              requirement, and (c) claims arising under consumer protection or similar legislation.{' '}
            </li>
            <li>
              Intellectual Property Rights. The parties acknowledge that, in the event of any
              third-party claim that the iOS App or your possession and use of the iOS App infringe
              that third party’s intellectual property rights, we, and not Apple, will be solely
              responsible for the investigation, defense, settlement and discharge of any such
              intellectual property infringement claim to the extent required under these Terms.{' '}
            </li>
            <li>
              Legal Compliance. You represent and warrant that (a) you are not located in a country
              that is subject to a U.S. Government embargo, or that has been designated by the U.S.
              Government as a “terrorist supporting” country, and (b) you are not listed on any U.S.
              Government list of prohibited or restricted parties.{' '}
            </li>
            <li>
              Developer Name and Address. Any questions, complaints, or claims with respect to the
              iOS App should be directed to:{' '}
              <strong>
                Immersive Tech Inc. 220 W 7th Ave STE210, Tampa, FL 33602 support@immertec.com
              </strong>
            </li>
            <li>
              Third-Party Terms of Agreement. You agree to comply with any applicable third-party
              terms when using The Licensed Application.{' '}
            </li>
            <li>
              Third-Party Beneficiary. The parties acknowledge and agree that Apple, and Apple’s
              subsidiaries, are third-party beneficiaries of these Terms, and that, upon your
              acceptance of these Terms, Apple will have the right (and will be deemed to have
              accepted the right) to enforce these Terms against you as a third-party beneficiary
              thereof.{' '}
            </li>
            <li>
              The following terms apply if you install, access or use the Licensed Application on
              any device that contains the Android mobile operating system (the “Android App”)
              developed by Google, Inc. (“Google”):{' '}
            </li>
            <li>
              You acknowledge that these Terms are between you and us only, and not with Google.{' '}
            </li>
            <li>
              Your use of the Android App must comply with Google’s then-current Android Market
              Terms of Service.{' '}
            </li>
            <li>
              Google is only a provider of the Android Market where you obtained the Android App.
              We, and not Google, are solely responsible for the Android App and The Licensed
              Application and content available thereon. Google has no obligation or liability to
              you with respect to the Android App or these Terms.{' '}
            </li>
            <li>
              You acknowledge and agree that Google is a third-party beneficiary to these Terms as
              they relate to the Android App.
            </li>
          </ul>
        </p>
      </div>
    </div>
  )
}

export default TOStext
