export const mloc1926 = {
  id: 'MLOC-1926',
  name: 'Coloplast HQ',
  description:
    'Miami, FL - Coral Gables Surgery Center provides cost-effective, efficient, and safe alternatives for outpatient surgery in the Miami, Florida area.',
  status: '09-Out of Service (ES)',
  address1: '1601 West River Road North',
  address2: null,
  city: 'Minneapolis',
  state: 'MN',
  zip: '55411',
  category: 'Pilot',
  type: 'Office Based Lab',
  latitude: '44.99494',
  longitude: '-93.27743',
  photo: 'mloc-1926.png',
  speciality: 'Orthopedics',
}
