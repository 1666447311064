import React from 'react'
import styles from './excitement.module.scss'

function ExcitementTrade() {
  return (
    <div className={styles.container}>
      <div className={styles.subcontainer}>
        <div className={styles.imageContainer}>
          <img src="/ets.png" alt="" />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.column1}>Generate excitement at trade shows</div>
          <div className={styles.column2}>
            Using VR and Immersive 2D allows our customers to create a memorable experience for
            visitors during trade-shows and conferences.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExcitementTrade
