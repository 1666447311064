import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstructorCardPicture,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorEvents,
} from '../../../utils/getAssetLink'

const data = [
  `Ranjith Ramasamy is the director of Male Reproductive Medicine and Surgery as well as an associate professor in Department of Urology at the Miller School of Medicine at the University of Miami Health System. As a urologist and microsurgeon, Dr. Ramasamy specializes in the treatment of disorders of male infertility and sexual dysfunction. He is an expert in vasectomy reversal and penile prosthesis.`,
  `Dr. Ramasamy completed his urology residency training at Weil Cornell Medical College and New York-Presbyterian Hospital. He then completed a National Institutes of Health-sponsored fellowship in Male Reproductive medicine and Surgery at Baylor College of Medicine. In addition to his clinical accolades, Dr. Ramasamy has been integral to the advancement of male reproductive medicine and surgery. To date, he has published over 100 manuscripts in peer-reviewed journals and several book chapters; he currently serves on the editorial board of Andrology, Asian Journal of Andrology and Urology as well as as an editorial reviewer for medical journals such as Journal of Urology, Fertility and Sterility, European Urology and British Journal of Urology. Furthermore, he has been invited to lecture at numerous international conferences including the American Urological Association, Chinese, Malaysian and Indian Society of Assisted Reproduction.`,
  `Outside of clinical medicine and academic research, Dr. Ramasamy has made a significant effort to share his wealth of knowledge and serve as a mentor for future urologists. He created ‘Urology’ an app used by trainees across the world to help prepare for urology board examinations. As a physician, Dr. Ramasamy maintains a dedication to patient care, academia, and the training of future urologists by directing a fellowship program in andrology.`,
  `Dr. Ramasamy is currently leading important clinical trials at the University of Miami Health System for:
    Male Fertility/Infertility Treatment
    Erectile Dysfunction Treatment
    Low Testosterone Treatments`,
]

const specialty = specialties.urology.name
const id = 'ramasamy'
export const ramasamyData = {
  firstName: 'Ranjith',
  lastName: 'Ramasamy',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'Ranjith Ramasamy, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  shortVideoCard: getInstructorCardPicture(id),

  events: getInstructorEvents(id),
}
