import React from 'react'
import styles from './Immersive2DCardInverted.module.scss'

function Immersive2DCardInverted({ title, desc, img, video }) {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.leftContainer}>
          {video ? (
            <video autoPlay playsInline loop muted width="100%">
              <source src={video} type="video/mp4" />
            </video>
          ) : (
            <img src={img} alt="" />
          )}
          {/* <div className={styles.subtitle}>Data analytics</div> */}

          {/* <div className={styles.btnContainer}>
            <div className={styles.btnText}>Find out more</div>
            <img src="/sra.png" alt="" />
          </div> */}
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{desc}</div>
        </div>
      </div>
    </div>
  )
}

export default Immersive2DCardInverted
