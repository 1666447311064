import React from 'react'
import Slider from 'react-slick'
import styles from './StartupHumbled.module.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import PartnerLogo from '../ResearchPartners/PartnerLogo'

let data = [
  { id: 1, img: 'Brandlogos/1.png' },
  { id: 2, img: 'Brandlogos/2.png' },
  { id: 3, img: 'Brandlogos/3.png' },
  { id: 4, img: 'Brandlogos/4.png' },
  { id: 5, img: 'Brandlogos/5.png' },
  { id: 6, img: 'Brandlogos/6.png' },
  { id: 7, img: 'Brandlogos/7.png' },
  // { id: 8, img: 'Brandlogos/8.png' },
  // { id: 9, img: 'Brandlogos/9.png' },
  // { id: 10, img: 'Brandlogos/10.png' },
]

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon className={styles.arrowClass} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon className={styles.arrowClass} />
    </div>
  )
}

function StartupHumbled() {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    // adaptiveHeight: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,

          // centerMode: true,
        },
      },
    ],
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>
        Our team has research collaborations with the world's leading institutions
      </div>
      <div className={styles.sliderContainer} style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={styles.sliderSubContainer}>
          <div>
            <Slider {...settings}>
              {data.map((item, index) => (
                <PartnerLogo item={item} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartupHumbled
