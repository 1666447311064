import React from 'react'
import styles from './imc.module.scss'
import Card from './slider/components/card'
import Slider from 'react-slick'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

let data = [
  {
    id: 1,
    image: '/IVM/1.png',
    title: '01  - Subjective User Reactions',
    description:
      'We survey research participants to determine if the training is favorable, engaging and relevant to their jobs. We do this with a mix of in-person questioning or online surveys. These surveys are issued shortly after the event. ',
  },
  {
    id: 2,
    image: '/IVM/2.png',
    title: '02 - Measured User Learning - Immediate Retention',
    description:
      'We evaluate and measure the degree to which participants retained the intended knowledge, skills, attitude, confidence and commitment based on their participation in the training as compared to other 2D collaboration. ',
  },

  {
    id: 3,
    image: '/IVM/3.png',
    title: '03 - Transfer to On-The-Job Use',
    description:
      'We determine to what degree did participants apply what they learned during training when they are back on the job. After a training, we observe participants in an on-the-job use case to determine the retention and success of Immertec’s platform for skills transfer. ',
  },

  {
    id: 4,
    image: '/IVM/4.png',
    title: '04 - Results',
    description:
      'We holistically review the data collected during research to determine the organizational level changes from the use of Immertec’s technology including morale, cost and time savings.',
  },
]

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon className={styles.ArrowFont} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon className={styles.ArrowFont} />
    </div>
  )
}

function IMC() {
  const settings = {
    className: 'center',
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    slidesToScroll: 1,
    adaptiveHeight: true,

    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    afterChange: function (index) { },
  }
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Immertec’s Validation Model</div>
        <div className={styles.subTitle}>
          Immertec brings professionals together in real-time to learn the latest in medical
          innovation without travel.. Immertec brings professionals together in real-time to learn
          the latest in medical innovation without travel.
        </div>
      </div>
      <div className={styles.mainSliderContainer}>
        <div className={styles.sliderContainer}>
          <Slider {...settings} className={'imc_slider'}>
            {data.map((item, index) => (
              <Card key={index} item={item} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default IMC
