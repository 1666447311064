import { style } from '@mui/system'
import React, { useState, useRef } from 'react'
import LeadershipTeam from './LeadershipTeam'
import styles from './LeadershipTeam.module.scss'
import { isMobile } from 'react-device-detect'
import ErikMaltais from '../MemberDetails/ErikMaltais'
import Jon from '../MemberDetails/Jon'
// import Jim from '../MemberDetails/Jim'
// import Michael from '../MemberDetails/Michael'
import _scrollTo from '../../utils/scrollTo'
import Wheeler from '../MemberDetails/Wheeler'

let teamData = [
  {
    id: 1,
    img: '/erik.png',
    fname: 'Erik Maltais',

    role: 'CEO',
    width: '120px',
    height: '120px',
  },
  {
    id: 2,
    img: '/Leaders/Jon.png',
    fname: 'Jon Clagg',

    role: 'CTO',
    width: '120px',
    height: '120px',
  },
  // {
  //   id: 3,
  //   img: '/Leaders/Michael.png',
  //   fname: 'Michael Morin',

  //   role: 'CRO',
  //   width: '120px',
  //   height: '120px',
  // },
  // {
  //   id: 3,
  //   img: '/Leaders/wheeler.png',
  //   fname: 'Matt Wheeler',
  //   role: 'CRO',
  //   width: '120px',
  //   height: '120px',
  // },
]

const teamDataPanel = [
  {
    id: 1,
    component: <ErikMaltais />,
  },
  {
    id: 2,
    component: <Jon />,
  },
  // {
  //   id: 3,
  //   component: <Michael />,
  // },
  // {
  //   id: 4,
  //   component: <Wheeler />,
  // },
]

function LeaderShipTeam() {
  const [currentLeader, setCurrentLeader] = useState(null)
  const teamRef = useRef()
  const handleOnTeamLeaderClick = (id) => {
    if (currentLeader === id) {
      setCurrentLeader(null)
      return
    }
    setCurrentLeader(id)
    _scrollTo(teamRef, -40)
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.LeaderSec}>
        <div className={styles.Title}>Our Leadership Team</div>
        <div className={styles.teamContainer}>
          {teamData.map((leader, index) => (
            <LeadershipTeam
              key={index}
              leader={leader}
              pointer={true}
              onClick={() => {
                handleOnTeamLeaderClick(leader.id)
              }}
              customStyles={{
                leaderNameCss: {
                  width: isMobile ? '75px' : '6.875vw',
                  height: isMobile ? ' 45px' : '1.0416666666666667vw',
                  fontSize: isMobile ? '16px' : '1.4583333333333333vw',
                  fontFamily: 'Gilroy-Medium',
                  lineHeight: isMobile ? '15px' : '1.5625vw',
                  color: '#243746 ',
                  cursor: 'pointer',
                },
                leaderRoleCss: {
                  width: isMobile ? '120px' : '8.4375vw',
                  fontSize: isMobile ? '10px' : '0.78125vw',
                  marginTop: isMobile ? '0px' : '2.65625vw',
                  height: isMobile ? '10px' : '1.25vw',
                  fontFamily: 'Gilroy-Bold',
                  fontWeight: '100',
                  lineHeight: isMobile ? '12px' : '0.9375vw',
                  color: '#000000 ',
                  cursor: 'pointer',
                },
              }}
            />
          ))}
        </div>
      </div>
      <div ref={teamRef}>
        {currentLeader && teamDataPanel?.find((item) => item.id === currentLeader).component}
      </div>
    </div>
  )
}

export default LeaderShipTeam
