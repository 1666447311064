import React from 'react'
import styles from './JoinUsSlider.module.scss'
import ProgressiveImage from '../ProgressiveImage'

function JoinUsSlider({ item }) {
  return (
    <div className={styles.imageContainer}>
      <ProgressiveImage
        src={item.img}
        placeholder={item.progressiveImage}
        alt={''}
        className={styles.joinUsSlider}
      />
    </div>
  )
}

export default JoinUsSlider
