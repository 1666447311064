import React, { useEffect } from 'react'
import styles from './tabsbar.module.scss'
import { Tabs, Tab } from '@mui/material'
import { styled } from '@mui/material/styles'
import { specialties } from '../../../utils/specialty'
// import { tabsIcons, whiteTabsIcon } from '../../../constants/data'

const AntTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#47D7AC',
    height: '5px',
  },
  width: '100%',
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: '10vw',
  flex: 1,
  color: 'rgba(0, 0, 0)',
  fontFamily: ['-apple-system', 'Gilroy-Medium'].join(','),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',
  height: '5.208333333333333vw',
  borderRight: '1px solid #F5F5F5',
  fontSize: '0.8333333333333334vw',
  background: '#fff',
  '&.Mui-selected': {
    color: '#000',
    fontStyle: 'normal',
    fontSize: '0.8333333333333334vw',
    fontWeight: 1000,
  },
  '&.Mui-focusVisible': {},

  '&.MuiTab-root:last-child': {
    borderRight: 'none',
  },
}))

function TabBar(props) {
  const { activeTab, tabs, handleOnTabClick } = props
  const [value, setValue] = React.useState(null)

  useEffect(() => {
    setValue(activeTab)
  }, [activeTab])

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>SELECT A SPECIALTY</div>
      </div>
      <AntTabs value={value} onChange={handleOnTabClick}>
        {tabs?.map((item, index) => (
          <AntTab
            icon={
              <img
                src={value === item ? specialties[item]?.greenIcon : specialties[item]?.svgIcon}
                height={40}
                alt=""
              />
            }
            key={`${item}_${index}`}
            iconPosition="start"
            value={item}
            label={specialties[item]?.label}
          />
        ))}
      </AntTabs>
    </div>
  )
}

export default TabBar
