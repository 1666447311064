export const mloc489 = {
  id: 'MLOC-489',
  name: 'Leeds University-UK',
  description:
    'The University, established in 1904, is one of the largest higher education institutions in the UK. Renowned globally for the quality of our teaching and research.',
  status: '07-Event Ready (ES)',
  address1: 'Woodhouse',
  address2: null,
  city: 'Leeds',
  state: null,
  zip: null,
  category: 'Research Collaboration',
  type: 'Bioskills Lab',
  latitude: '53.80293',
  longitude: '-1.55709',
  photo: 'mloc-489.png',
  speciality: 'Urology',
}
