import React, { useEffect, useState } from 'react'
import styles from './ecs.module.scss'
import EcsSliderCard from './ecsSliderCard'
import ContainedButton from '../ContainedButton'
import Slider from 'react-slick'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import useCheckMobileScreen from './../../utils/getPlatform'
import getUrlForm from '../../utils/getFormUrl'
import { instructorsData } from '../../constants/data/instructorData'


function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon className={styles.ArrowClass} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon className={styles.ArrowClass} />
    </div>
  )
}

function EcsSection({ instructorId, reference }) {
  // const platform = useCheckMobileScreen()
  // pull in instructor. Needed when we make these dynamic.
  // const instructor = instructorData[instructorId]
  // const eventImagePath = `/EventsComingSoon/${platform}/${instructorId}/`
  // const allEventsImagePath = `/EventsComingSoon/${platform}/`
  // // Arrows will hide themselves on desktop as we only have 2 events.
  // let data = []
  const [events, setEvents] = useState([])

  useEffect(() => {
    if (instructorId) {
      setEvents(instructorsData[instructorId].events)
      return
    }
    setEvents(instructorsData.map((instructor) => instructor.events))
  }, [])

  // console.log(events)

  // const ids = [
  //   'walker',
  //   'raja',
  //   'michael',
  //   'mustapha',
  //   'carr',
  //   'khosla',
  //   'stephens',
  //   'giuffrida',
  //   'rastogi',
  //   'murthi',
  //   'cheung',
  //   'abboud',
  //   'grady',
  //   'asghar',
  //   'yue',
  //   'shufflebarger',
  // ]

  // If no instructor ID is present then just show all instructors for now.
  //TODO: Make the cards using only a background.
  // if (!instructorId) {
  // ids.forEach((id) => {
  //   const map = [
  //     {
  //       id: `${id}1`,
  //       img: `${allEventsImagePath}${id}/1.png`,
  //     },
  //     {
  //       id: `${id}2`,
  //       img: `${allEventsImagePath}${id}/2.png`,
  //     },
  //   ]
  //   data.push(...map)
  // })
  // } else {
  // const map = [
  //   {
  //     id: `${instructorId}1`,
  //     img: `${eventImagePath}1.png`,
  //   },
  //   {
  //     id: `${instructorId}2`,
  //     img: `${eventImagePath}2.png`,
  //   },
  // ]
  // data.push(...map)
  // }
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,

          // arrows: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }

  return (
    // reference for scrolling to ecs section in mobile
    <div ref={reference} className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Events Coming Soon</div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={styles.overlay1} />
        <div className={styles.overlay2} />
        <div className={styles.sliderContainer}>
          <Slider {...settings}>
            {events.map((item, index) => (
              <EcsSliderCard key={`${item.id}_${index}`} item={item} />
            ))}
          </Slider>
        </div>
      </div>
      <div className={styles.btnContainer}>
        <div className={styles.btnSubContainer}>
          <a href={getUrlForm('chat')}>
            <ContainedButton
              customStyles={{
                height: '2.998389694041868vw',
                width: '12.03125vw',
                backgroundColor: '#47D7AC',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Medium',
                fontSize: '1.0416666666666667vw',
                alignItems: 'center',
                fontWeight: 900,

                textAlign: 'center',
                color: '#243746',
              }}
              label={'Sponsor Event'}
            />
          </a>
        </div>
        <div className={styles.MbtnSubContainer}>
          <a href={getUrlForm('chat')}>
            <ContainedButton
              customStyles={{
                height: '40px',
                width: '303px',
                backgroundColor: '#47D7AC',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Medium',
                fontSize: '16px',
                alignItems: 'center',
                fontWeight: 900,

                textAlign: 'center',
                color: '#243746',
              }}
              label={'Sponsor Event'}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default EcsSection
