import React from 'react'
import MabCard from '../MabCard/MabCard'
import styles from './DoctorsList.module.scss'

let mabData = [
  // {
  //   id: 1,
  //   image: '/mabPage/mab1.png',
  //   title: 'Jihad A. Mustapha, MD, FACC, FSCAI',
  //   subtitle: 'Endovascular',
  //   icon: '/icons/heartIcon.png',
  //   page: '/Instructor/mustapha',
  // },

  {
    id: 2,
    image: '/mabPage/mab2.png',
    title: 'Craig Walker, MD',
    subtitle: 'Endovascular',
    icon: '/icons/heartIcon.png',
    page: '/Instructor/walker',
  },
  {
    id: 3,
    image: '/mabPage/mab3.png',
    title: 'Paul Michael, MD, FSCAI',
    subtitle: 'Endovascular',
    icon: '/icons/heartIcon.png',
    page: '/Instructor/michael',
  },
  {
    id: 9,
    image: '/mabPage/mab9.png',
    title: 'Jeffery G. Carr, MD',
    subtitle: 'Endovascular',
    icon: '/icons/heartIcon.png',
    page: '/Instructor/carr',
  },
  {
    id: 4,
    image: '/mabPage/mab4.png',
    title: 'Chris O` Grady, MD',
    subtitle: 'Orthopedics',
    icon: '/mabPage/MabIcons/Orthopedics.png',
    page: '/Instructor/grady',
  },
  {
    id: 5,
    image: '/mabPage/mab5.png',
    title: 'Emilie V. Cheung, MD, FAAOS',
    subtitle: 'Orthopedics',
    icon: '/mabPage/MabIcons/Orthopedics.png',
    page: '/Instructor/cheung',
  },

  {
    id: 6,
    image: '/mabPage/mab6.png',
    title: 'Jahangir "John" Asghar, MD ',
    subtitle: 'Spine',
    icon: '/mabPage/MabIcons/Spine.png',
    page: '/Instructor/asghar',
  },
  {
    id: 7,
    image: '/mabPage/mab7.png',
    title: 'James Yue, MD',
    subtitle: 'Spine',
    icon: '/mabPage/MabIcons/Spine.png',
    page: '/Instructor/yue',
  },
  {
    id: 8,
    image: '/mabPage/mab8.png',
    title: 'Nathan Kow, MD',
    subtitle: 'Urology',
    icon: '/mabPage/MabIcons/Urology.png',
    // TODO: Add page for Kow
    page: '/mab',
  },
]

function DoctorsList() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainText}>
        These prestigious physicians bring their unique perspectives and expertise to inform the
        strategic direction and success of the organization. They are trailblazing pioneers who are
        helping increase the adoption of immersive peer-to-peer collaboration.
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={styles.cardList}>
          {mabData.map((item, index) => (
            <MabCard key={`${item.title}_${index}`} item={item} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DoctorsList
