import React from 'react'
import styles from './leftArrow.module.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={`${styles.ArrowContainer}`} onClick={onClick}>
      <ChevronRightIcon />
    </div>
  )
}

export default LeftArrow
