import React from 'react'
import styles from './PlatformSupport.module.scss'

function PlatformSupport() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.textContainer}>
        <div className={styles.mainText}>
          Immertec's platform is supported by a strong research team that continually evaluates the
          efficacy of our technology.
        </div>
        <div className={styles.subText}>
          Our team is driven by the potential to improve patient outcomes by helping doctors
          collaborate and learn from anywhere.
        </div>
      </div>
    </div>
  )
}

export default PlatformSupport
