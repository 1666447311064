import { specialties } from '../../../utils/specialty'
import { cdnUrl } from '../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorCardPicture,
  getInstructorProfileCover,
  getInstructorEvents,
} from '../../../utils/getAssetLink'
//Use this template to add more instructors
const data = [
  `Marc Dean, MD is a board-certified otolaryngologist, practicing full time in private practice in Fort Worth, Texas, specializing in ear and sinus disease and disorders of the eustachian tube.`,
  `He attended Baylor University for his undergraduate studies in bioinformatics, attended Texas Tech for his medical training and completed his residency program at LSU-Shreveport. Dr. Dean served as assistant professor for three additional years at LSU-Shreveport and remains on the LSU facility as a clinical professor.`,
  `Otolaryngology/Head and Neck Surgery (HNS), Dr. Dean completed an internship in general surgery and his residency at Louisiana State University Health Science Center, where he now serves as assistant professor in Otolaryngology/HNS.`,
  `As President and CEO of the Otorhinologic Research Institute, a non-profit organization focusing on the development of new treatments for ear, sinus and eustachian tube disorders, Dr. Dean hopes to raise the awareness of these conditions in local medical communities around the world. In that capacity, he has taught, lectured and lead mission trips to multiple countries providing free information to doctors and necessary medical treatment to those in need. New endeavors include the development of a cochlear implant program in northern Iraq and a head and neck program in Vietnam.`,
]
const specialty = specialties.otolaryngology.name
const id = 'dean'
export const deanData = {
  firstName: 'Marc',
  lastName: 'Dean',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'MARC DEAN, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileLink: `/Instructor/${id}`,
  profileCover: getInstructorProfileCover(id),
  events: getInstructorEvents(id),
  shortVideoCard: getInstructorCardPicture(id),
}
