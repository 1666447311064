export const mloc1940 = {
  id: 'MLOC-1940',
  name: 'Vascular Institute of Chattanooga',
  description:
    'Saving Limbs and Renewing Lives. Their mission is to provide exceptional care by a compassionate team.  Their goal is to exceed patient expectations with an excellent experience at our centers.  We strive to understand patient needs and requirements, developing a patient-physician solution that will allow the best outcome.  This is accomplished through a willing staff that has dedicated their careers to delivering unparalleled care. ',
  status: '07-Event Ready (ES)',
  address1: ' 2358 Lifestyle Way #100',
  address2: null,
  city: 'Chattanooga',
  state: 'TN',
  zip: '37421',
  category: 'Commercial',
  type: 'Office Based Lab',
  latitude: '35.04231',
  longitude: '-85.15021',
  photo: 'mloc-1940.png',
  speciality: 'Orthopedics',
}
