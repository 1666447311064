// import instructor from '../../class/instructor'
import { abboudData } from './instructorData/abboud'
import { asgharData } from './instructorData/asghar'
import { carrData } from './instructorData/carr'
import { cheungData } from './instructorData/cheung'
import { deanData } from './instructorData/dean'
import { giuffridaData } from './instructorData/giuffrida'
import { gradyData } from './instructorData/grady'
import { khoslaData } from './instructorData/khosla'
import { michaelData } from './instructorData/michael'
import { murthiData } from './instructorData/murthi'
// import { mustaphaData } from './instructorData/mustapha'
import { panditData } from './instructorData/pandit'
import { rajaData } from './instructorData/raja'
import { rastogiData } from './instructorData/rastogi'
import { shufflebargerData } from './instructorData/shufflebarger'
import { stephensData } from './instructorData/stephens'
import { walkerData } from './instructorData/walker'
import { yueData } from './instructorData/yue'
import { smithData } from './instructorData/smith'
import { ramasamyData } from './instructorData/ramasamy'
import { peritoData } from './instructorData/perito'
import { carrionData } from './instructorData/carrion'
import { kowData } from './instructorData/kow'
import { coricData } from './instructorData/coric'
import { quaidooData } from './instructorData/quaidoo'
import { ballardData } from './instructorData/ballard'
import { elderData } from './instructorData/elder'
import { knollData } from './instructorData/knoll'
import { poeData } from './instructorData/poe'

 export const instructors = [
  abboudData,
  asgharData,
  carrData,
  cheungData,
  deanData,
  giuffridaData,
  gradyData,
  khoslaData,
  michaelData,
  murthiData,
  // mustaphaData,
  panditData,
  rajaData,
  rastogiData,
  shufflebargerData,
  stephensData,
  walkerData,
  yueData,
  smithData,
  ramasamyData,
  // peritoData,
  carrionData,
  kowData,
  coricData,
  quaidooData,
  ballardData,
  elderData,
  knollData,
  poeData,
]

export const instructorsData = {}

const createData = () => {
  instructors.map((instruc) => {
    instructorsData[instruc.id] = instruc
  })
}

createData()
