import React from 'react'
import styles from './pausedOverlay.module.scss'

function PausedOverlay({ image, name, subTitle, icon }) {
  return (
    <div className={styles.container}>
      <img src={image} className={styles.cardImage} alt="" />
      <div className={styles.cardLogo}>
        <img src={icon} className={styles.cardLogoImage} alt="" />
      </div>
      <div className={styles.textContainer}>
        <p className={styles.cardTitle}>{name}</p>
        <p className={styles.cardSubTitle}>{subTitle}</p>
      </div>
    </div>
  )
}

export default PausedOverlay
