import React, { useState } from 'react'
import styles from './user.module.scss'

function UserBtn() {
  const [displayDetails, setDisplayDetails] = useState(false)

  const handleOnHover = () => {
    setDisplayDetails(!displayDetails)
  }

  return (
    <div className={styles.container} onMouseEnter={handleOnHover} onMouseLeave={handleOnHover}>
      <div className={styles.pulseButton}>
        <img src={'/team.png'} alt="" />
      </div>
      <div
        className={`${styles.detailsContainer} ${
          displayDetails ? styles.diplay : styles.displayNone
        }`}
      >
        <span className={styles.iconContainer}>
          <img src={'/team.png'} alt="" />
        </span>
        <div className={styles.highlightText}>Conferencing</div>
        <div className={styles.detailsText}>
          Video conferencing capabilities that allow up to 50 attendees to communicate in real-time
        </div>
      </div>
    </div>
  )
}

export default UserBtn
