export const mloc931 = {
  id: 'MLOC-931',
  name: 'Palm Beach Heart & Vascular',
  description:
    'At Palm Beach Heart & Vascular, our dedicated team of professionals have joined together to provide you and your family with high-quality, comprehensive care in a welcoming, modern environment. We are committed to providing personalized healthcare using cutting-edge technology and effective, innovative solutions to improve the quality of life for each and every one of our patients. In this way, we are able to deliver the best possible heart and vascular care. We believe that patients should be treated wholistically and that they and their families should feel empowered when working with our team.',
  status: '07-Event Ready (ES)',
  address1: '2609 W Woolbright Rd',
  address2: 'Ste 4',
  city: 'Boynton Beach',
  state: 'FL',
  zip: '33436',
  category: 'Commercial',
  type: 'Office Based Lab',
  latitude: '26.51368',
  longitude: '-80.09135',
  photo: 'mloc-931.png',
  speciality: 'Orthopedics',
}
