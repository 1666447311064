import React, { useRef, useEffect } from 'react'
import styles from './customMobileTabs.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'

function FocusOnSelect(props) {
  const mobileTabsRef = useRef(null)

  useEffect(() => {
    if (props.activeTab) {
      mobileTabsRef.current.swiper.slideToLoop(props.currentIndex, 0)
    }
  }, [props.activeTab])

  return (
    <div id="homeCourselMTabs">
      <Swiper
        slidesPerView={'auto'}
        centeredSlides={true}
        loop={false}
        ref={mobileTabsRef}
        slideToClickedSlide={true}
        spaceBetween={10}
        freeMode={true}
      >
        {props.tabsForTeam?.map((item) => (
          <SwiperSlide>
            <div
              className={`${props.currentIndex === item.value ? styles.activeTab : styles.tab}`}
              onClick={() => {
                props.onChangeTab(item.value)
              }}
            >
              {item.label}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default FocusOnSelect
