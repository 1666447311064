import React from 'react'
import styles from './InvertedsolutionCard.module.scss'
import { Link } from 'react-router-dom'
import { cdnUrl } from '../../constants/enum'

function InvertedSolutionCard() {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.rightContainer}>
          <div className={styles.subTitle}>Immersive 2D Viewer</div>
          <div className={styles.title}>Viewing experience tailor-made for Healthcare</div>
          <div className={styles.description}>
            Our Immersive 2D Viewer gives was created to ensure viewers won’t miss anything
            important.
          </div>
          <Link to="/immersive-2d-viewer">
            <div className={styles.btnContainer}>
              <div className={styles.btnText}>Find out more</div>
              <img src="/sra.png" alt="" />
            </div>
          </Link>
        </div>
        <div className={styles.leftContainer}>
          <video autoPlay playsInline loop muted width="100%">
            <source
              src={`${cdnUrl}/videos/solutions/SOLUTIONS_VIEWING_EXPERIENCE.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  )
}

export default InvertedSolutionCard
