export const mloc91 = {
  id: 'MLOC-91',
  name: 'DJO Dallas',
  description:
    'Based in Dallas, TX and with more than five thousand employees in more than a dozen facilities around the world, DJO Global is dedicated to enabling people to live their lives to the fullest by providing intelligent medical devices and services from injury prevention to rehabilitation.',
  status: '07-Event Ready (ES)',
  address1: '2900 Lake Vista Drive',
  address2: null,
  city: 'Lewisville',
  state: 'TX',
  zip: '75067',
  category: 'Pilot',
  type: 'Company HQ Lab',
  latitude: '32.99084',
  longitude: '-96.96193',
  photo: 'mloc-91.png',
  speciality:"Endovascular"
}
