import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorCardPicture,
  getInstructorEvents,
} from '../../../utils/getAssetLink'
const data = [
  `Dr. Emilie Cheung specializes in surgery related to the shoulder and elbow, including shoulder replacement surgery, complex revision surgery, fracture fixation, arthroscopy, rotator cuff repair, and elbow reconstruction. She has been in practice at Stanford since 2006, where she helps to train the next generation of orthopedic surgeons.`,

  `Her research focuses on clinical outcomes following revision of total shoulder replacements, revision of total elbow replacements, and treatment of complications following shoulder and elbow reconstruction procedures. Her publications include those in the Journal of Bone and Joint Surgery, Journal of Shoulder and Elbow Surgery, Journal of the American Academy of Orthopedic Surgeons, and Clinical Orthopedics and Related Research.`,

  `A Board-certified orthopedic surgeon, she presents and often lectures at regional and national orthopedic surgery conferences and has written numerous textbook chapters in the field of shoulder and elbow surgery.`,

  `Dr. Cheung is a Fellow of the American Academy of Orthopaedic Surgery, Member of the prestigious American Shoulder Elbow Society, Member of the Association of Clinical Elbow and Shoulder Surgeons, and Member of the Mayo Elbow Club. She completed her orthopedic surgery residency at Drexel University. She completed her fellowship in shoulder and elbow surgery at the Mayo Clinic.`,

  `She was voted "One of the Top 65 Orthopedic Surgeons" in 2017 and "One of the Top 70 Female Orthopedic Surgeons to Know" in 2011, nominated by other orthopedic surgeons in the Beckers Orthopedic Review.`,
]

const specialty = specialties.orthopedics.name
const id = 'cheung'
export const cheungData = {
  firstName: 'Emilie',
  lastName: 'Cheung',
  middleName: 'V.',
  suffix: 'MD, FAAOS',
  id,
  title: 'EMILIE V. CHEUNG, MD, FAAOS',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  shortVideoCard: getInstructorCardPicture(id),

  category: specialty,
  profileLink: `/Instructor/${id}`,
  profileCover: getInstructorProfileCover(id),
  events: getInstructorEvents(id),
}
