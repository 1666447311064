import { useEffect, useState } from 'react'
/**
 * Checks if user is on desktop or mobile.
 * Returns a string of mobile or desktop
 * @returns {String}
 */

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return width <= 900 ? 'mobile' : 'desktop'
}

export default useCheckMobileScreen

