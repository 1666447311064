import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorCardPicture,
  getInstructorProfileCover,
  getInstructorEvents,
} from '../../../utils/getAssetLink'

const data = [
  ` Nearly 20 years ago Dr. Perito developed a boutique practice in Coral Gables, Florida treating Men’s Health issues with Erectile Dysfunction being his primary focus.  Dr. Perito’s techniques and surgical pearls are derived from these 20 years of IPP experience having now performed over 8,000 penile implant cases.  During this time, Dr. Perito’s practice has gained a global presence as a treatment center for men with erectile dysfunction and has also become the #1 training facility where doctors from around the world come to learn his minimally invasive technique (The Perito Implant™).  With over 500 penile implant cases per year, Dr. Perito performs more penile implants than any country other than the US.  Recently, Dr. Perito developed the first safe and efficacious office-based treatment for penile girth enhancement.  This novel technique has been shown to have impressive and durable cosmetic results.`,
  `Dr. Perito attended Emory University for undergraduate training, University of Maryland Medical School, University of Miami/Jackson Memorial Hospital for general surgery and his urology residency and a one-year fellowship at Flinder’s University, South Australia.  He is a board-certified member of the American Urological Association, Sexual Medicine Society, Society of Prosthetic Surgeons, and a fellow of the American College of Surgeons.`,
]

const specialty = specialties.urology.name
const id = 'perito'
export const peritoData = {
  firstName: 'Paul',
  lastName: 'Perito',
  middleName: undefined,
  suffix: 'MD,FACS',
  id,
  title: 'Paul Perito, MD, FACS',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  profileCover: getInstructorProfileCover(id),
  category: specialty,
  shortVideoCard: getInstructorCardPicture(id),

  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
