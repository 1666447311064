import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'

const data = [
  `AJ Rastogi, MD is the CEO and Co-Founder of North American Spine & Pain Consultants.`,
  `Dr. Rastogi enjoys not just the art and the science of practicing pain management, but also considered the field a perfect fit for him early in his medical career. To him, it embodies the essence of being a doctor to take away pain. He also understands the importance of multi-modal therapy in treating all aspects of pain: physical and emotional.`,
  `Aside from his current focus is on advancing the state of interventional spine by investigating innovative new treatment options, Dr. Rastogi has completed multiple studies evaluating cutting edge minimally-invasive spinal procedures.`,
  `Dr. Rastogi also completed research at The University of Virginia on the analgesic effects of anticonvulsants. He authored many chapters concerning interventional spine procedures for professional textbooks.`,
  `Dr. Rastogi is also the national fellowship course director for multiple companies involved in developing emerging spine technology. He has trained over 400 physicians from both the US and internationally on a variety of interventional techniques.`,
  `Dr. Rastogi received his MD from the University of Virginia, and completed his residency and fellowship in pain management at the University of Pennsylvania.`,
]
const specialty = specialties.interventionalSpine.name
const id = 'rastogi'
export const rastogiData = {
  firstName: 'AJ',
  lastName: 'Rastogi',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'AJ RASTOGI, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].name, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  shortVideoCard: getInstructorCardPicture(id),

  events: getInstructorEvents(id),
}
