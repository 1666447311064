import React from 'react'
import Footer from './components/Footer/Footer'
import BurgerMenu from './components/Header/burgerMenu'
import AwarenessPlatform from './components/AwarenessPlatform'
import Benefits from './components/Benefits'
import BussinessPartner from './components/BussinessPartner'
import ContinuousTraining from './components/ContinuousTraining'
import ExcitementTrade from './components/ExcitementTrade'
import Header from './components/Header1'
import Hero from './components/Hero'
import NumberSection from './components/NumberSection'
import SalesEnablement from './components/SalesEnablement'
import Sustainability from './components/Sustainability'
import Testimonial from './components/Testimonial'
import Usecase from './components/Usecase'
import ImmersiveTraining from './components/immersiveTraining'
import MobileHeader from './components/Header/MobileHeader'
import useWindowSize from './hooks/useWindowResize'

function Home() {
  const [width] = useWindowSize()
  return (
    <div className="App">
      <BurgerMenu />
      {width > 900 ? <Header /> : <MobileHeader />}
      <Hero />
      <Benefits />
      <Usecase />
      <ContinuousTraining />
      <BussinessPartner />
      <SalesEnablement />
      <Sustainability />
      <div style={{ position: 'relative' }}>
        <div className="gradientBg" />
        <ExcitementTrade />
        <Testimonial />
      </div>
      <ImmersiveTraining img={'/immersiveTraining.png'} />
      <NumberSection />
      <AwarenessPlatform />
      <Footer />
    </div>
  )
}

export default Home
