import React from 'react'
import styles from './banner.module.scss'
import OutlineButton from '../OutlineButton'
import getUrlForm from '../../utils/getFormUrl'

function AreYouInterested({ bgcolor, bannerTitle, btnTitle, btnUrl }) {
  return (
    <div className={styles.container} style={{ background: bgcolor }}>
      <div className={styles.itemContainer}>
        <div className={styles.textContainer}>{bannerTitle}</div>

        <div className={styles.Dbutton}>
          <a href={getUrlForm(btnUrl)}>
            <OutlineButton
              customStyles={{
                height: '2.998389694041868vw',
                width: '21.822916666666668vw',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Medium',
                fontSize: '1.0416666666666667vw',
                lineHeight: '1.2850241545893721vw',
                textAlign: 'center',
                color: '#47D7AC',
              }}
              label={btnTitle}
            />
          </a>
        </div>
        <div className={styles.Mbutton}>
          <a href={getUrlForm(btnUrl)}>
            <OutlineButton
              customStyles={{ height: '40.5px', width: '302.28px' }}
              textStyles={{
                fontFamily: 'Gilroy-Medium',
                fontSize: '14.463px',
                lineHeight: '2.318840579710145vh',
                textAlign: 'center',
                color: '#47D7AC',
              }}
              label={btnTitle}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default AreYouInterested
