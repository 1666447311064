import React, { useContext, useState } from 'react'

const MuteContext = React.createContext()

export function useMute() {
  return useContext(MuteContext)
}

export function MuteStateProvider({ children }) {
  const [muted, setMuted] = useState(true)

  const onMuteClick = () => {
    setMuted(!muted)
  }

  return <MuteContext.Provider value={{ muted, onMuteClick }}>{children}</MuteContext.Provider>
}
