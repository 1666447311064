import React from 'react'
import styles from './continuous.module.scss'

function ContinuousTraining() {
  return (
    <div className={styles.container}>
      <div className={styles.gradient} />
      <div className={styles.subcontainer}>
        <div className={styles.leftContainer}>
          <h2>Recording content library</h2>
          <p>
            Record surgical procedures and host them in a content library so that you can watch them
            on demand for continuous training and education purposes.
          </p>
        </div>
        <div className={styles.rightContainer}>
          <img src="/cte.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default ContinuousTraining
