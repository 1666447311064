import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { cdnUrl } from './constants/enum'


const PDFViewer = () => {
  // const match = useParams()
  const { filename } = useParams()
  // check if filename has .pdf extension
  // if not, add it
  const new_filename = filename.endsWith('.pdf') ? filename : `${filename}.pdf`
  const pdfUrl = `${cdnUrl}/documents/${new_filename}`
  useEffect(() => {
    window.location.assign(pdfUrl)
  }, [pdfUrl])

  return null
}

export default PDFViewer
