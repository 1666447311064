import React, { useEffect } from 'react'
import Header from './components/Header1'
import ImmersiveTraining from './components/immersiveTraining'
import NumberSection from './components/NumberSection'
import AwarenessPlatform from './components/AwarenessPlatform'
import Footer from './components/Footer/Footer'
import BurgerMenu from './components/Header/burgerMenu'
import RequestDemoSuccess from './components/RequestDemoSuccess'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'

function SucessDemo() {
  const [width] = useWindowSize()
  useEffect(() => {
    let collection = document.querySelectorAll('.bm-burger-bars')
    Array(3)
      .fill('_')
      .map((item, index) => {
        collection[index].style.backgroundColor = '#fff'
      })

    document.addEventListener('scroll', () => {
      if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
        Array(3)
          .fill('_')
          .map((item, index) => {
            collection[index].style.backgroundColor = '#000'
          })
      } else {
        Array(3)
          .fill('_')
          .map((item, index) => {
            collection[index].style.backgroundColor = '#fff'
          })
      }
    })
  }, [])
  return (
    <>
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}
      <BurgerMenu />
      <RequestDemoSuccess />
      <ImmersiveTraining img={'/immersiveTraining.png'} />
      <NumberSection />
      <AwarenessPlatform />
      <Footer />
    </>
  )
}

export default SucessDemo
