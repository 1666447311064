export const mloc919 = {
  id: 'MLOC-919',
  name: 'North American Spine & Pain Consultants',
  description:
    'North American Spine And Pain is a group practice with 1 location. Currently, North American Spine And Pain specializes in Anesthesiologist, Pain Medicine and Nurse Practitioner with 10 physicians.',
  status: '06-Event Prep (ES)',
  address1: '404 Creek Crossing Blvd',
  address2: null,
  city: 'Hainesport',
  state: 'NJ',
  zip: '8036',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '39.97513',
  longitude: '-74.8488',
  photo: 'mloc-919.png',
  speciality: 'Spine',
}
