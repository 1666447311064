export const mloc639 = {
  id: 'MLOC-639',
  name: 'MedtoMarket',
  description:
    'MedtoMarket is a 31,683 square-foot medical training and co-working facility located in the St. Elmo District in south central Austin. Founded by practicing physicians, MedtoMarket’s mission is to help medical innovators bridge the gap from concept to market.',
  status: '07-Event Ready (ES)',
  address1: '2101 E St. Elmo Rd.',
  address2: null,
  city: 'Austin',
  state: 'TX',
  zip: '78744',
  category: 'Pilot',
  type: 'Bioskills Lab',
  latitude: '30.20869',
  longitude: '-97.74718',
  photo: 'mloc-639.png',
  speciality: 'Urology',
}
