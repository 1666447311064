// import * as instructors from '../constants/data/instructorData'
import { cdnUrl } from '../constants/enum'

function detectMob() {
  return window.innerWidth <= 900 ? 'mobile' : 'desktop'
}

/**
 * Returns the link to the short promo video of an instructor. Used on homepage.
 * @param  {} instructorId Last name of instructor. Matched instructorID in instructorData
 */
function getShortInstructorVideoLink(specialty, instructorId) {
  if (specialty && instructorId) {
    return `${cdnUrl}/videos/${specialty}/${instructorId}/short.mp4`
  } else return false
}

/**
 * Returns the link to the long promo video of an instructor. Used on
 * @param  {} instructorId Last name of instructor. Matched instructorID in instructorData
 */
function getLongInstructorVideoLink(specialty, instructorId) {
  if (specialty && instructorId) {
    return `${cdnUrl}/videos/${specialty}/${instructorId}/long.mp4`
  } else return false
}

/**
 * Returns the link for the instructor video cover on the main page.
 * @param  {} instructorName Last name of instructor. Matched instructorID in instructorData
 */
function getInstuctorHomePageVideoCover(instructorName) {
  return `/homePageVideoCover/${instructorName}.png`
}

/**
 * Returns the link for the instructor video cover on the main page.
 * @param  {} getInstuctorProgressiveImage Last name of instructor. Matched instructorID in instructorData
 */
function getInstuctorProgressiveImage(department, instructorName) {
  return `/ProgressiveImages/${department}/${instructorName}.png`
}
/**
 * Return the link for the instructor card pictures. By default it returns the homepage compatible 3 width card.
 * @param  {} instructorName Last name of instructor. Matched instructorID in instructorData
 * @param  {} layout Which card to get, the wider 3 layout or the slimmer 4 layout
 */
function getInstructorCardPicture(instructorName, platform) {
  // Default to desktop.
  if (!platform) platform = 'desktop'
  return `/instructorCardPictures/${detectMob()}/${instructorName}.png`
}

function getHeroImageOfInstructor(category, instructorName) {
  return `/instructorData/${category.replace(/^\s+|\s+$/gm, '')}/${instructorName}.png`
}

function getInstructorProfileCover(instructor) {
  return `/InstructorHeroPictures/${detectMob()}/${instructor}.png`
}

function getInstructorEvents(instructorId) {
  return Array(2)
    .fill('.')
    .map((item, index) => `/EventsComingSoon/${detectMob()}/${instructorId}/${index + 1}.png`)
}

function getLocationImage(locationPhoto) {
  return `/locationImages/${locationPhoto}`
}

export {
  getShortInstructorVideoLink,
  getLongInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getInstuctorProgressiveImage,
  getInstructorCardPicture,
  getHeroImageOfInstructor,
  getInstructorProfileCover,
  getInstructorEvents,
  getLocationImage,
}
