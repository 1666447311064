import React, { useEffect, useState } from 'react'
import styles from './ecs.module.scss'
import EcsSliderCard from './ecsSliderCard'
import ContainedButton from '../ContainedButton'
import Slider from 'react-slick'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { dataForHomeSlider } from '../../constants/data/homeSliderData'
import { dataForCoverSlider } from '../../constants/data/coverSliderData'
import useCheckMobileScreen from '../../utils/getPlatform'
import shuffle from '../../utils/getArrayRandomized.js'
import getUrlForm from '../../utils/getFormUrl'
import { instructorsData } from '../../constants/data/instructorData'


function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon className={styles.ArrowClass} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon className={styles.ArrowClass} />
    </div>
  )
}

function EcsSection({ specialty }) {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,

          // arrows: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }

  const [events, setEvents] = useState()

  useEffect(() => {
    if (specialty) {
      setEvents(
        [].concat.apply([], shuffle(dataForCoverSlider[specialty].map((item) => item.events))),
      )
    }
  }, [specialty])

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Events Coming Soon</div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={styles.overlay1} />
        <div className={styles.overlay2} />
        <div className={styles.sliderContainer}>
          <Slider {...settings}>
            {events?.map((item, index) => (
              <EcsSliderCard key={`${item}_${index}`} item={item} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default EcsSection
