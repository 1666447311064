import { useState, React } from 'react'
import styles from './SurgeonsTrain.module.scss'
// import Card from './slider/components/card'
import Slider from 'react-slick'
import ContainedButton from '../ContainedButton'
// import { css, keyframes } from '@emotion/css'
// import HoverVideoPlayer from "react-hover-video-player";
// import HoverVideoPlayer from '../HoverVideoPlayer'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

// import HoverOverlay from '../../components/Instructors/components/hoverOverlay'
import PausedOverlay from './components/pausedOverlay.js'
import CustomTabs from './CustomTabs'
import { productTabs } from '../../constants/data'
import { productTabsData } from '../../constants/data/productTabsData'
import CustomMobileTabs from './CustomMobileTabs'
// import { useMute } from '../../providers/MuteContextProvider'
import FullScreenVideo from '../Fullscreen/FullScreenVideo'
import getUrlForm from '../../utils/getFormUrl'
import { dataForCoverSlider } from '../../constants/data/coverSliderData'
import { specialties } from '../../utils/specialty'
import Instructors from './InstructorSlider'
import { useHomeCourselContext } from '../../providers/HomeCourselProvider'

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon className={styles.ArrowFont} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon className={styles.ArrowFont} />
    </div>
  )
}

function SurgeonsTrain() {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
    ],

    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  }

  const [currentTab, setCurrentTab] = useState('interventionalSpine')

  let currentCover = dataForCoverSlider[currentTab] || []

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const handleMobileTabChange = (newValue) => {
    setCurrentTab(newValue)
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>
        <div className={styles.mainText}>Surgeons that Train with Immertec</div>
        <div className={styles.subText}>
          Immertec partners with instructor physicians throughout the US to provide real-time
          virtual procedure training events across a range of treatment areas.
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.25))',
        }}
      >
        <div className={styles.tabsContainer}>
          <CustomTabs
            currentTab={currentTab}
            tabs={Object.keys(dataForCoverSlider)}
            onClick={handleChange}
          />
        </div>
        <div className={styles.MtabContainer}>
          <CustomMobileTabs
            currentIndex={currentTab}
            onChangeTab={handleMobileTabChange}
            tabsForTeam={productTabs}
          />
        </div>
        <Instructors instructors={currentCover} currentTab={currentTab} />
      </div>
      <div className={styles.bottomSubText}>
        Learn how more effective training can help your team reach more physicians
      </div>
      <div className={styles.btnContainer}>
        <Link to="/request-a-demo">Request a Demo</Link>
      </div>

      <div className={styles.MbtnContainer}>
        <Link to="/request-a-demo">Request a Demo</Link>
      </div>
    </div>
  )
}

export default SurgeonsTrain
// <div style={{ display: 'flex', justifyContent: 'center' }}>
//         <Grid xs={10} md={8} lg={7}>
//           <Slider {...settings} className="instructor-slider sliderCont">
//             {dataForCoverSlider[currentTab]?.map((elem) => (
//               <div key={elem.id} className={styles.sliderItemContainer}>
//                 <div className={styles.itemSlider}>
//                   <FullScreenVideo
//                     video={elem.shortVideoLink}
//                     id={elem.id}
//                     route={elem.profileLink}
//                     PausedOverlay={
//                       <PausedOverlay
//                         image={elem.shortVideoCard}
//                         name={elem.title}
//                         subTitle={specialties[elem.category]?.label}
//                         icon={specialties[elem.category]?.greenIcon}
//                       />
//                     }
//                   />
//                 </div>
//               </div>
//             ))}
//           </Slider>
//         </Grid>
//       </div>
