import React from 'react'
import styles from './LocationHeader.module.scss'
import ContainedButton from '../ContainedButton'
import getUrlForm from '../../utils/getFormUrl'
import ProgressiveImage from '../ProgressiveImage'
import { Link } from 'react-router-dom'

function LocationHeader() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.textContainer}>Locations</div>
        <div className={styles.btnContainer}>
          <Link to="/request-a-demo">
            <ContainedButton
              customStyles={{
                height: '2.9166666666666665vw',
                width: '21.822916666666668vw',
                backgroundColor: '#47D7AC',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Bold',
                fontSize: '1.0416666666666667vw',
                lineHeight: '100%',
                fontWeight: 'lighter',
                alignItems: 'center',
                textAlign: 'center',
                color: '#243746',
              }}
              label={'Request a demo'}
            />
          </Link>
        </div>
      </div>
      <div className={styles.GradientContainer}>
        <div className={styles.gradient} />
        <div className={styles.rightContainer}>
          <ProgressiveImage
            src={'/LocationPage/header-location.jpeg'}
            className={styles.image}
            placeholder={'/LocationPage/header-location-progressive.jpeg'}
            alt={''}
          />
        </div>
      </div>
      <div className={styles.MbtnContainer}>
        <Link to="/request-a-demo">
          <ContainedButton
            customStyles={{
              height: '40.5px',
              width: '303px',
              backgroundColor: '#47D7AC',
            }}
            textStyles={{
              fontFamily: 'Gilroy-Bold',
              fontSize: ' 14.463px',
              lineHeight: '100%',

              alignItems: 'center',
              textAlign: 'center',
              color: '#243746',
            }}
            label={'Request a demo'}
          />
        </Link>
      </div>
    </div>
  )
}

export default LocationHeader
