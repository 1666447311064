import React from 'react'
import styles from './FAQS.module.scss'
import CustomizedAccordions from './Accordion/AccordionComponent'
import { FAQ } from '../../constants/data/faqData'
function FAQS() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleContainer}>Frequently Asked Questions</div>
      {Object.keys(FAQ).map((section, index) => (
        <div className={styles.accordionContainer} key={`faqSection_${index}`}>
          <div className={styles.sectionTitle}>&emsp; &ensp;{section}</div>
          <CustomizedAccordions faqData={FAQ[section]} />
        </div>
      ))}
    </div>
  )
}

export default FAQS
