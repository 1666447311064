import React from 'react'
import Header1 from './components/Header1/index'
import ImmersiveHero from './components/Immersive2dViewer/Immersive2dViewer'
import CardSection from './components/CardSection/CardSection'
import Features from './components/Features/Features'
import AwarenessPlatform from './components/AwarenessPlatform'
import TestimonialBg from './components/TestimonialBg/index'
import ImmersiveTraining from './components/immersiveTraining'
import Footer from './components/Footer/Footer'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import BurgerMenu from './components/Header/burgerMenu'
import SolutionSubMenuHero from './components/SolutionsSubMenuHero'

function VRViewer() {
  const [width] = useWindowSize()
  const featureData = [
    {
      title: 'Hassle free',
      image: 'hassleIcon.png',
      cardDes: [
        'Easy event management and hosting.',
        'Join events with a simple passcode.',
        'Dedicated user portal for seamless access.',
      ],
    },
    {
      title: 'Immerse yourself',
      image: '/eye.png',
      cardDes: [
        'Feel as if you’re standing inside the OR.',
        'VR eliminates distractions around you.',
        '3D VR environments improve learning outcomes compared to 2D modalities.',
      ],
    },
    {
      title: 'Collaboration',
      image: 'colab.png',
      cardDes: [
        'Real-time video communication.',
        'Annotate medical feeds.',
        'Show presentation slides.',
      ],
    },
    {
      title: 'Medical feeds',
      image: '/feed.png',
      cardDes: [
        'Show up to four simultaneous HD medical feeds.',
        'Easily move or expand any feed as needed.',
        'Focus on what’s most important to you.',
      ],
    },
    {
      title: 'OR Equipment',
      image: '/or-equipment.png',
      cardDes: [
        'Connect with any OR equipment (C-Arms, scopes, Ultrasound, etc.).',
        'Support 3D medical equipment.',
        'Support AR technology from third parties.',
      ],
    },
    {
      title: 'Security',
      image: '/security.png',
      cardDes: [
        'SOC2 certification for enhanced security and trust.',
        'Compliance with privacy policies.',
        'Robust authentication to guarantee authorized access.',
      ],
    },
  ]
  const cardData = [
    {
      title: 'Feel as if you’re in the room',
      desc: 'Watching a live surgical procedure in VR is an immersive experience that puts you inside the operating room, allowing you to observe every detail up close and personal.',
      image: '/FeelCardImage.png',
    },
    {
      title: 'Avoid distractions',
      desc: 'Studies have shown that VR immersion can help students focus 4 times more and be 3.75 times more emotionally connected to the content shown, leading to improved learning outcomes.',
      image: '/distraction.png',
    },
    {
      title: 'Be fully present',
      desc: "The immersive nature of VR technology makes you forget that you're merely an observer, allowing you to feel fully engaged and connected.",
      image: '/PresentCardImage.png',
    },
  ]
  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header1 /> : <MobileHeader />}
      <SolutionSubMenuHero title={'VR Viewer'} heroImage={'/vr-hero.png'} />
      <CardSection data={cardData} />
      <Features
        data={featureData}
        heading="Watch live surgical procedures from anywhere in an immersive and collaborative environment"
      />
      <TestimonialBg />
      <ImmersiveTraining img={'/VrViewerImmersive.png'} />
      <AwarenessPlatform />

      <Footer />
    </div>
  )
}

export default VRViewer
