import React from 'react'
import styles from './scyptp.module.scss'
import OutlineButton from '../OutlineButton'
import ContainedButton from '../ContainedButton'
import { Link } from 'react-router-dom'
import getUrlForm from '../../utils/getFormUrl'

function SCYPTP(props) {
  return (
    <div className={styles.container}>
      <div className={styles.backgroundStyles}>
        <div className={styles.textContainer}>
          <div className={styles.title}>SCALE YOUR PROFESSIONAL TRAINING PROGRAM</div>
          <div className={styles.subtitle}>
            Learn how more effective training can help your team reach more physicians
          </div>
          <div className={styles.btnContainer}>
            <Link to="/request-a-demo">
              <ContainedButton
                customStyles={{
                  width: '12.03125vw',
                  height: '2.998389694041868vw',
                  backgroundColor: '#47D7AC',
                }}
                textStyles={{
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.0416666666666667vw',
                  fontWeight: '100',
                  color: '#243746',
                }}
                label={'Request a Demo'}
              />
            </Link>
            <Link to="/product">
              <OutlineButton
                customStyles={{
                  height: '3.1731424676209956vw',
                  width: '12.03125vw',
                }}
                textStyles={{
                  fontFamily: 'Gilroy-Medium',
                  fontSize: '1.0416666666666667vw',
                  color: '#fff',
                }}
                label={'Learn More'}
              />
            </Link>
          </div>
          <div className={styles.MbtnContainer}>
            <Link to="/request-a-demo">Request a Demo</Link>
            <Link to="/product">
              <OutlineButton
                customStyles={{ height: '40.5px', width: '303px' }}
                textStyles={{
                  fontFamily: 'Gilroy-Medium',
                  fontSize: '16px',
                  color: '#fff',
                }}
                label={'Learn More'}
              />
            </Link>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src={'/icons/scyptp.png'} alt="" className={styles.imageScyptp} />
        </div>
      </div>
      <div className={styles.overlayContainer}>
        <div className={styles.overlay1}></div>
      </div>
    </div>
  )
}

export default SCYPTP
