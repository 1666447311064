import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorCardPicture,
  getInstructorProfileCover,
  getInstructorEvents,
} from '../../../utils/getAssetLink'
const data = [
  `Ankur Khosla, MD, MBA, MS, is a physician at University Pain in The Woodlands, Texas. Dr. Khosla diagnoses and treats chronic and acute pain with the goal of restoring his patients' functionality, so they can get back to enjoying their active lifestyle.`,

  `Dr. Khosla began his higher education with a bachelor's degree in chemistry from Austin College in Sherman, Texas. He then attended Texas Tech University in Lubbock, where he earned a Master of Business Administration in Health Organizational Management, a Master of Science in Health Services Research and Management, and his Doctor of Medicine. Next, he completed a dual residency in anesthesiology and internal medicine at the University of Texas (UT) Medical School in Houston, where he was named Resident of the Year and also worked as the Chief Resident.`,

  `Following his residency, Dr. Khosla completed a fellowship in interventional and chronic pain at UT's branch in Galveston. This fellowship gave him the training he needed to become a specialist in treating back pain, cancer pain, and musculoskeletal injuries. His hospital affiliations include The Woodlands Hospital and Lakeside Hospital, both in The Woodlands. He has a particular interest in getting patients back a quality of life with pain but a distant memory. Given his vast experience, he has become the #1 provider for spinal cord stimulation in the region.`,

  `Dr. Khosla is a member of the Society of Physician Entrepreneurs, Texas Pain Society, North American Neuromodulation Society, and the American Academy of Pain Medicine. He speaks English, Hindi, and French.`,
]
const specialty = specialties.interventionalSpine.name
const id = 'khosla'
export const khoslaData = {
  firstName: 'Ankur',
  lastName: 'Khosla',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'ANKUR KHOSLA, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].name, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
  shortVideoCard: getInstructorCardPicture(id),
}
