export const mloc8915 = {
  id: 'MLOC-8915',
  name: 'Urology Associates of Nashville Surgery Center',
  description:
    'Founded in the 1940s, Urology Associates P.C. serves Middle Tennessee with over 20 board-certified urologists and 12 Advanced Practice Providers, providing male and female urological care in over 10 locations. They were the first practice to open a center solely dedicated to female urology in Nashville. They are committed to the innovation and the integration of advanced technology and advanced therapeutics in our treatment offerings. Their practice offers both minimally invasive and non-invasive surgical options. In addition, the practice provides unique treatment options and specialized care in their Urology Surgery Center, Women’s Institute for Sexual Health (WISH), and Advanced Therapeutics Center. Urology Associates P.C. was founded on the principles of compassionate, high-quality urological care, and continues to serve that mission.',
  status: '05-Cart Install (SI)',
  address1: '2801 Charlotte Ave',
  address2: null,
  city: 'Nashville',
  state: 'TN',
  zip: '37209',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: 36.153042,
  longitude: -86.819069,
  photo: 'mloc-8915.png',
  speciality: 'Spine',
}
