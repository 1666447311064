import { React, useState, useEffect } from 'react'
import styles from './inverteredBgSolutionCard.module.scss'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'
import { cdnUrl } from '../../constants/enum'


function InvertedBgSolutionCard() {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const { ref, inView } = useInView({
    threshold: 1,
  })

  useEffect(() => {
    setIsVideoPlaying(inView)
  }, [inView])
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.subtitle}>Data analytics</div>
          <div className={styles.title}>Data-driven performance insights</div>
          <div className={styles.description}>
            Track key training and awareness metrics to gain valuable business insights and use data
            to elevate medical training and education.
          </div>
          <Link to="/data-analytics">
            <div className={styles.btnContainer}>
              <div className={styles.btnText}>Find out more</div>
              <img src="/sra.png" alt="" />
            </div>
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <video autoPlay playsInline loop muted width="100%">
            <source
              src={`${cdnUrl}/videos/solutions/DATA_ANALYTICS_SOLUTIONS.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  )
}

export default InvertedBgSolutionCard
