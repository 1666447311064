import { getInstructorCardPicture, getShortInstructorVideoLink } from '../../utils/getAssetLink'
import { specialties } from '../../utils/specialty'

export const dataForHomeSlider = {
  'Interventional Spine': {
    id: 1,
    instructors: [
      {
        id: 0,
        img: getInstructorCardPicture('khosla'),
        name: 'Ankur Khosla, MD',
        subTitle: 'Interventional Spine',
        video: getShortInstructorVideoLink('khosla'),
        route: '/Instructor/khosla',
        icon: '/icon-IVspine.png',
      },
      {
        id: 1,
        img: getInstructorCardPicture('stephens'),
        name: 'Chad Stephens, DO',
        subTitle: 'Interventional Spine',
        video: getShortInstructorVideoLink('stephens'),
        route: '/Instructor/stephens',
        icon: '/icon-IVspine.png',
      },
      {
        id: 2,
        img: getInstructorCardPicture('rastogi'),
        name: 'AJ Rastogi, MD',
        subTitle: 'Interventional Spine',
        video: getShortInstructorVideoLink('rastogi'),
        route: '/Instructor/rastogi',
        icon: '/icon-IVspine.png',
      },
      {
        id: 3,
        img: getInstructorCardPicture('giuffrida'),
        name: 'ANTHONY GIUFFRIDA, MD',
        subTitle: 'Interventional Spine',
        video: getShortInstructorVideoLink('giuffrida'),
        route: '/Instructor/giuffrida',
        icon: '/icon-IVspine.png',
      },
    ],

    events: {
      desktop: [
        // '/ankur_event_1.png',
        // '/ankur_event_2.png',
        // '/chad_event_1.png',
        // '/chad_event_2.png',
        // '/rastogi_event_1.png',
        // '/rastogi_event_2.png',
        // '/anthony_event_1.png',
        // '/anthony_event_2.png',

        '/EventsComingSoon/desktop/khosla/1.png',
        '/EventsComingSoon/desktop/khosla/2.png',
        '/EventsComingSoon/desktop/giuffrida/1.png',
        '/EventsComingSoon/desktop/giuffrida/2.png',
        '/EventsComingSoon/desktop/rastogi/1.png',
        '/EventsComingSoon/desktop/rastogi/2.png',
        '/EventsComingSoon/desktop/stephens/1.png',
        '/EventsComingSoon/desktop/stephens/2.png',
      ],
      mobile: [
        // '/ankur_event_m1.png',
        // '/ankur_event_m2.png',
        // '/chad_event_m1.png',
        // '/chad_event_m2.png',
        // '/rastogi_event_m1.png',
        // '/rastogi_event_m2.png',
        // '/anthony_event_m1.png',
        // '/anthony_event_m2.png',

        '/EventsComingSoon/mobile/khosla/1.png',
        '/EventsComingSoon/mobile/khosla/2.png',
        '/EventsComingSoon/mobile/rastogi/1.png',
        '/EventsComingSoon/mobile/rastogi/2.png',
        '/EventsComingSoon/mobile/stephens/1.png',
        '/EventsComingSoon/mobile/stephens/2.png',
        '/EventsComingSoon/mobile/giuffrida/1.png',
        '/EventsComingSoon/mobile/giuffrida/2.png',
      ],
    },
  },
  Endovascular: {
    id: 2,
    instructors: [
      {
        id: 4,
        img: getInstructorCardPicture('walker'),
        name: 'Craig Walker, MD, FACC, FACA, FACP, FSCAI, FASCI, FCCP, FICA',
        subTitle: 'Endovascular',
        video: getShortInstructorVideoLink('walker'),
        route: '/Instructor/walker',
        icon: '/icon-endovascular.png',
      },
      {
        id: 5,
        img: getInstructorCardPicture('raja'),
        name: 'M. Laiq Raja, MD, FACC, FSCAI',
        subTitle: 'Endovascular',
        video: getShortInstructorVideoLink('raja'),
        route: '/Instructor/raja',
        icon: '/icon-endovascular.png',
      },
      // {
      //   id: 6,
      //   img: getInstructorCardPicture('mustapha'),
      //   name: 'JIHAD A. MUSTAPHA, MD, FACC, FSCAI',
      //   subTitle: 'Endovascular',
      //   video: getShortInstructorVideoLink('mustapha'),
      //   route: '/Instructor/MUSTAPHA',
      //   icon: '/icon-endovascular.png',
      // },

      {
        id: 7,
        img: getInstructorCardPicture('carr'),
        name: 'Jeffrey G. Carr, MD, FACC, FSCAI',
        subTitle: 'Endovascular',
        video: getShortInstructorVideoLink('carr'),
        route: '/Instructor/carr',
        icon: '/icon-endovascular.png',
      },
      {
        id: 8,
        img: getInstructorCardPicture('michael'),
        name: 'Paul Michael, MD, FSCAI',
        subTitle: 'Endovascular',
        video: getShortInstructorVideoLink('michael'),
        route: '/Instructor/michael',
        icon: '/icon-endovascular.png',
      },
    ],

    events: {
      desktop: [
        // '/jaffery_event_1.png',
        // '/jaffery_event_2.png',
        // '/jihad_event_1.png',
        // '/jihad_event_2.png',
        // '/mlaiq_event_1.png',
        // '/mlaiq_event_2.png',
        // '/cw_event_1.png',
        // '/cw_event_2.png',

        '/EventsComingSoon/desktop/carr/1.png',
        '/EventsComingSoon/desktop/carr/2.png',
        '/EventsComingSoon/desktop/michael/1.png',
        '/EventsComingSoon/desktop/michael/2.png',
        // '/EventsComingSoon/desktop/mustapha/1.png',
        // '/EventsComingSoon/desktop/mustapha/2.png',
        '/EventsComingSoon/desktop/raja/1.png',
        '/EventsComingSoon/desktop/raja/2.png',
        '/EventsComingSoon/desktop/walker/1.png',
        '/EventsComingSoon/desktop/walker/2.png',
      ],
      mobile: [
        // '/jaffery_event_m1.png',
        // '/jaffery_event_m2.png',
        // '/jihad_event_m1.png',
        // '/jihad_event_m2.png',
        // '/mlaiq_event_m1.png',
        // '/mlaiq_event_m2.png',
        // '/cw_event_m1.png',
        // '/cw_event_m2.png',
        '/EventsComingSoon/mobile/carr/1.png',
        '/EventsComingSoon/mobile/carr/2.png',
        '/EventsComingSoon/mobile/michael/1.png',
        '/EventsComingSoon/mobile/michael/2.png',
        // '/EventsComingSoon/mobile/mustapha/1.png',
        // '/EventsComingSoon/mobile/mustapha/2.png',
        '/EventsComingSoon/mobile/raja/1.png',
        '/EventsComingSoon/mobile/raja/2.png',
        '/EventsComingSoon/mobile/walker/1.png',
        '/EventsComingSoon/mobile/walker/2.png',
      ],
    },
  },
  Spine: {
    id: 3,
    instructors: [
      {
        id: 9,
        img: getInstructorCardPicture('asghar'),
        name: 'Jahangir "John" Asghar, MD',
        subTitle: 'Spine',
        video: getShortInstructorVideoLink('asghar'),
        route: '/Instructor/asghar',
        icon: '/icon-spine.png',
      },
      {
        id: 10,
        img: getInstructorCardPicture('shufflebarger'),
        name: 'Harry Shufflebarger, MD',
        subTitle: 'Spine',
        video: getShortInstructorVideoLink('shufflebarger'),
        route: '/Instructor/shufflebarger',
        icon: '/icon-spine.png',
      },
      {
        id: 11,
        img: getInstructorCardPicture('yue'),
        name: 'James Yue, MD',
        subTitle: 'Spine',
        video: getShortInstructorVideoLink('yue'),
        route: '/Instructor/yue',
        icon: '/icon-spine.png',
      },
      {
        id: 12,
        img: getInstructorCardPicture('smith'),
        name: 'Tyler Smith, MD',
        subTitle: 'Spine',
        video: getShortInstructorVideoLink('smith'),
        route: '/Instructor/smith',
        icon: '/icon-spine.png',
      },
      {
        id: 12,
        img: getInstructorCardPicture('coric'),
        name: 'Domagoj Coric, MD',
        subTitle: 'Spine',
        video: getShortInstructorVideoLink('coric'),
        route: '/Instructor/coric',
        icon: '/icon-spine.png',
      },
    ],
    events: {
      desktop: [
        '/EventsComingSoon/desktop/asghar/1.png',
        '/EventsComingSoon/desktop/asghar/2.png',
        '/EventsComingSoon/desktop/shufflebarger/1.png',
        '/EventsComingSoon/desktop/shufflebarger/2.png',
        '/EventsComingSoon/desktop/yue/1.png',
        '/EventsComingSoon/desktop/yue/2.png',
        '/EventsComingSoon/desktop/smith/1.png',
        '/EventsComingSoon/desktop/smith/2.png',
        '/EventsComingSoon/desktop/coric/1.png',
        '/EventsComingSoon/desktop/coric/2.png',
      ],
      mobile: [
        '/EventsComingSoon/mobile/asghar/1.png',
        '/EventsComingSoon/mobile/asghar/2.png',
        '/EventsComingSoon/mobile/shufflebarger/1.png',
        '/EventsComingSoon/mobile/shufflebarger/2.png',
        '/EventsComingSoon/mobile/yue/1.png',
        '/EventsComingSoon/mobile/yue/2.png',
        '/EventsComingSoon/mobile/smith/1.png',
        '/EventsComingSoon/mobile/smith/2.png',
        '/EventsComingSoon/mobile/coric/1.png',
        '/EventsComingSoon/mobile/coric/2.png',
      ],
    },
  },
  Orthopedics: {
    id: 4,
    instructors: [
      {
        id: 13,
        img: getInstructorCardPicture('murthi'),
        name: 'Anand Murthi, MD',
        subTitle: 'Orthopedics',
        video: getShortInstructorVideoLink('murthi'),
        route: '/Instructor/murthi',
        icon: '/icon-orthopedics.png',
      },
      {
        img: getInstructorCardPicture('cheung'),
        id: 14,
        name: 'Emilie V. Cheung, MD, FAAOS',
        subTitle: 'Orthopedics',
        video: getShortInstructorVideoLink('cheung'),
        route: '/Instructor/cheung',
        icon: '/icon-orthopedics.png',
      },
      {
        img: getInstructorCardPicture('abboud'),
        id: 15,
        name: 'Joseph Abboud, MD',
        subTitle: 'Orthopedics',
        video: getShortInstructorVideoLink('abboud'),
        route: '/Instructor/abboud',
        icon: '/icon-orthopedics.png',
      },
      {
        img: getInstructorCardPicture('grady'),
        id: 16,
        name: "CHRIS O'GRADY, MD",
        subTitle: 'Orthopedics',
        video: getShortInstructorVideoLink('grady'),
        route: '/Instructor/grady',
        icon: '/icon-orthopedics.png',
      },
    ],
    events: {
      desktop: [
        // '/anand_event_1.png',
        // '/anand_event_2.png',
        // '/emelie_event_1.png',
        // '/emelie_event_2.png',
        // '/joseph_event_1.png',
        // '/joseph_event_2.png',
        // '/chris_event_1.png',
        // '/chris_event_2.png',

        '/EventsComingSoon/desktop/abboud/1.png',
        '/EventsComingSoon/desktop/abboud/2.png',
        '/EventsComingSoon/desktop/cheung/1.png',
        '/EventsComingSoon/desktop/cheung/2.png',
        '/EventsComingSoon/desktop/murthi/1.png',
        '/EventsComingSoon/desktop/murthi/2.png',
        '/EventsComingSoon/desktop/grady/1.png',
        '/EventsComingSoon/desktop/grady/2.png',
      ],
      mobile: [
        '/EventsComingSoon/mobile/abboud/1.png',
        '/EventsComingSoon/mobile/abboud/2.png',
        '/EventsComingSoon/mobile/cheung/1.png',
        '/EventsComingSoon/mobile/cheung/2.png',
        '/EventsComingSoon/mobile/murthi/1.png',
        '/EventsComingSoon/mobile/murthi/2.png',
        '/EventsComingSoon/mobile/grady/1.png',
        '/EventsComingSoon/mobile/grady/2.png',
      ],
    },
  },
  Otolaryngology: {
    id: 4,
    instructors: [
      {
        id: 17,
        img: getInstructorCardPicture('dean'),
        name: 'Marc Dean, MD',
        subTitle: 'Otolaryngology',
        video: getShortInstructorVideoLink('dean'),
        route: '/Instructor/dean',
        icon: specialties.otolaryngology.greenIcon,
      },
      {
        id: 18,
        img: getInstructorCardPicture('pandit'),
        name: 'Rajiv Pandit, MD, FACS',
        subTitle: 'Otolaryngology',
        video: getShortInstructorVideoLink('pandit'),
        route: '/Instructor/pandit',
        icon: specialties.otolaryngology.greenIcon,
      },
    ],
    events: {
      desktop: [
        '/EventsComingSoon/desktop/dean/1.png',
        '/EventsComingSoon/desktop/dean/2.png',
        '/EventsComingSoon/desktop/pandit/1.png',
        '/EventsComingSoon/desktop/pandit/2.png',
      ],
      mobile: [
        '/EventsComingSoon/mobile/dean/1.png',
        '/EventsComingSoon/mobile/dean/2.png',
        '/EventsComingSoon/mobile/pandit/1.png',
        '/EventsComingSoon/mobile/pandit/1.png',
      ],
    },
  },
  Urology: {
    id: 5,
    instructors: [
      {
        id: 20,
        img: getInstructorCardPicture('ramasamy'),
        name: 'Ranjith Ramasamy, MD',
        subTitle: 'Urology',
        video: getShortInstructorVideoLink('ramasamy'),
        route: '/Instructor/ramasamy',
        icon: specialties.urology.greenIcon,
      },
      {
        id: 21,
        img: getInstructorCardPicture('carrion'),
        name: 'Rafael Carrion, MD',
        subTitle: 'Urology',
        video: getShortInstructorVideoLink('carrion'),
        route: '/Instructor/carrion',
        icon: specialties.urology.greenIcon,
      },
      {
        id: 22,
        img: getInstructorCardPicture('perito'),
        name: 'Paul Perito, MD, FACS',
        subTitle: 'Urology',
        video: getShortInstructorVideoLink('perito'),
        route: '/Instructor/perito',
        icon: specialties.urology.greenIcon,
      },
      {
        id: 23,
        img: getInstructorCardPicture('kow'),
        name: 'Nathan Kow, MD',
        subTitle: 'Urology',
        video: getShortInstructorVideoLink('kow'),
        route: '/Instructor/kow',
        icon: specialties.urology.greenIcon,
      },
    ],
    events: {
      desktop: [
        '/EventsComingSoon/desktop/ramasamy/1.png',
        '/EventsComingSoon/desktop/ramasamy/2.png',
        '/EventsComingSoon/desktop/kow/1.png',
        '/EventsComingSoon/desktop/kow/2.png',
        '/EventsComingSoon/desktop/perito/1.png',
        '/EventsComingSoon/desktop/perito/2.png',
        '/EventsComingSoon/desktop/carrion/1.png',
        '/EventsComingSoon/desktop/carrion/2.png',
      ],
      mobile: [
        '/EventsComingSoon/mobile/ramasamy/1.png',
        '/EventsComingSoon/mobile/ramasamy/2.png',
        '/EventsComingSoon/mobile/kow/1.png',
        '/EventsComingSoon/mobile/kow/2.png',
        '/EventsComingSoon/mobile/perito/1.png',
        '/EventsComingSoon/mobile/perito/2.png',
        '/EventsComingSoon/mobile/carrion/1.png',
        '/EventsComingSoon/mobile/carrion/2.png',
      ],
    },
  },
}
