import React from 'react'
import styles from './stats.module.scss'
import CountUpStat from '../countUp'

function StatSection() {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Immertec by the Numbers</div>
      </div>
      <div className={styles.statsContainer}>
        <CountUpStat fontColor="#47D7AC" value={60} label={'Instructors'} />
        <CountUpStat fontColor="#47D7AC" value={59} label={'Locations'} />
        <CountUpStat fontColor="#47D7AC" value={311} label={'Events'} />
        <CountUpStat fontColor="#47D7AC" value={1589} label={'Surgeons Trained'} />
      </div>
    </div>
  )
}

export default StatSection
