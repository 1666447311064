import React, { useEffect, useState } from 'react'
import styles from './header1.module.scss'
import CustomDropDown from '../CustomDropDown'
import StickyMenu from '../../utils/StickyMenu'
import useWindowSize from '../../hooks/useWindowResize'
import { Link } from 'react-router-dom'
import { SignalWifiStatusbarConnectedNoInternet4TwoTone } from '@mui/icons-material'

function Header({ disabled = false }) {
  const [width] = useWindowSize()
  const [logoImg, setLogoImg] = useState(width < 900 ? '/headerLogoVector.png' : '/headerLogo.png')
  const [currentPath, setCurrentPath] = useState(window.location.pathname)

  useEffect(() => {
    const handlePathChange = () => {
      setCurrentPath(window.location.pathname)
    }

    // Attach the event listener for path changes
    window.addEventListener('popstate', handlePathChange)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePathChange)
    }
  }, [])

  useEffect(() => {
    StickyMenu('.main-header')
  })
  useEffect(() => {
    setLogoImg(width < 900 ? '/headerLogoVector.png' : '/headerLogo.png')
    document.addEventListener('scroll', () => {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        setLogoImg('/headerLogoVector.png')
      } else {
        width < 900 ? setLogoImg('/headerLogoVector.png') : setLogoImg('/headerLogo.png')
      }
    })
  }, [width])

  const getButtonStyle = () => {
    if (currentPath === '/solutions') {
      return {
        backgroundColor: '#3B6CB3',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '40px',
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: 'Gilroy-Regular',
        fontWeight: '700',
        lineHeight: '24px',
      }
    } else {
      return {
        color: 'var(--gray-gray-9, #243746)',
        padding: '10px 20px',
        borderRadius: '40px',
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: 'Gilroy-Regular',
        fontWeight: '700',
        lineHeight: '24px',
      }
    }
  }

  const buttonStyle = getButtonStyle()

  return (
    <div className={`${styles.container} main-header`}>
      <div className={styles.subContainer}>
        <Link to="/" className={styles.logoContainer}>
          <img src={logoImg} />
        </Link>
        <div className={styles.navbarContainer}>
          <Link to="/solutions">
            <div className={styles.solutionsButton} style={buttonStyle}>Solutions</div>
          </Link>
          <CustomDropDown
            name={'About'}
            optionsToMap={[
              { title: 'The Science', link: '/science' },
              { title: 'Instructors', link: '/instructors' },
              { title: 'Locations', link: '/locations' },
              { title: 'Medical Advisory Board', link: '/mab' },
              { title: 'Team', link: '/team' },
              { title: 'Careers', link: '/careers' },
            ]}
          />
          {disabled ? (
            <div className={`${styles.disabled}`}>Request a Demo</div>
          ) : (
            <Link to="/request-a-demo">
              <div className={`${styles.navbarHighlightItem}`}>Request a Demo</div>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
