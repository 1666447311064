export const mloc163 = {
  id: 'MLOC-163',
  name: 'Florida Orthopaedic Institute Surgery Center - North Tampa',
  description:
    'The Florida Orthopaedic Institute Surgery Center is a leader of excellence in the ambulatory surgery arena providing compassionate care and quality service to all cultures, philosophies and generations, being worthy of patient, physician, and employee choice through their:\n• Innovative technology and methods to ensure best practices\n• Pristine, state of the art environment\n• Safe, ethical, high quality personal attention\n',
  status: '06-Event Prep (ES)',
  address1: '13020 N Telecom Pkwy',
  address2: null,
  city: 'Temple Terrace',
  state: 'FL',
  zip: '33637',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '28.06405',
  longitude: '-82.37269',
  photo: 'mloc-163.png',
  speciality: 'Endovascular',
}
