import React from 'react'
import styles from './card.module.scss'

function Card(props) {
  return (
    <div className={styles.bottom}>
      <div className={styles.leftContainer}>
        <div className={styles.mainText}>{props.text}</div>
        <div className={styles.subText}>{props.description}</div>
      </div>
      <div className={styles.rightContainer}>
        <img src={props.imgUrl} />
      </div>
    </div>
  )
}

export default Card
