import React from 'react'
import styles from './hospitaldescription.module.scss'
import { locationsChips, locationChipSelectionStyles } from '../../constants/data'
import LocationChip from '../LocationChip'
import ContainedButton from '../ContainedButton'
import { getLocationImage } from '../../utils/getAssetLink'

function HospitalDescription({
  address1,
  address2,
  description,
  city,
  id,
  name,
  state,
  type,
  status,
  photo,
}) {
  return (
    <div className={styles.main}>
      <div className={styles.imageContainer}>
        <img src={getLocationImage(photo)} alt="" />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.chipContainer}>
          <LocationChip
            label={type}
            value={'BioskillsLab'}
            customStyles={{ ...locationsChips[type]?.styles }}
          />
        </div>

        <div className={styles.textSection}>
          <div className={styles.title}>{name}</div>
          <div className={styles.locationContainer}>
            <img src="/LocationPage/locationIcon.png" alt="" />
            <div className={styles.locationText}>
              {address1 ?? '' + ' ' + address2 ?? '' + ',' + city ?? '' + ',' + state ?? ''}
            </div>
          </div>
          <div className={styles.textContainer}>
            <p>{description}</p>
          </div>
          <div className={styles.Dbutton}>
            <ContainedButton
              customStyles={{
                height: '3.888888888888889vw',
                width: '29.09722222222222vw',
                cursor: 'pointer',
                zIndex: 50,
                marginTop: '2.5vw',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Bold',
                fontSize: '1.0416666666666667vw',
                lineHeight: '24px',
                textAlign: 'center',
                color: ' #243746',
              }}
              label={'Train in this Location'}
            />
          </div>
          <div className={styles.Mbutton}>
            <ContainedButton
              customStyles={{
                height: '10.688888888888889vw',
                width: '81vw',
                cursor: 'pointer',
                zIndex: 50,
                marginTop: '8.0vw',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Bold',
                fontSize: '16px',
                lineHeight: '24px',
                textAlign: 'center',
                color: ' #243746',
              }}
              label={'Train in this Location'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HospitalDescription
