import React from 'react'
import styles from './ExperienceTraining.module.scss'
import { tabsForProduct } from '../../constants/data'
import LeadershipTeam from '../LeaderShipTeam/LeadershipTeam'
import CustomTabsList from './CustomTabs'
function ExperienceTraining() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainText}>Experience Training Immersively</div>
      <div className={styles.Image}>
        <img src="/ExperienceTraining.png" />
      </div>

      <div className={styles.feature}>
        <div className={styles.dot1}></div>
        <div className={styles.Text}>Conferencing capabilities</div>
      </div>

      <div className={styles.feature}>
        <div className={styles.dot2}></div>
        <div className={styles.Text}>Interactive medical imaging feeds</div>
      </div>

      <div className={styles.feature}>
        <div className={styles.dot3}></div>
        <div className={styles.Text}>Unobstructed view of the surgical suite</div>
      </div>
      {/* <div className={styles.subHeading}>
          Immertec's platform is the first of it's kind to offer medical professionals a way to train immersively from their home or office.
        </div>
        <div className={styles.TabsSec}>
          <CustomTabsList
            tabs={tabsForProduct}
            tabPanels={[
              [
                {
                  child: (
                    <LeadershipTeam
                      leader={{
                        img: 'icons/OR.svg',
                        fname: '180-Degree View of the OR',
                        role: 'Enhance access to higher-value medical devices and procedures',
                      }}
                      custom={true}
                      customStyles={{
                        leaderNameCss: {
                          width: '227px',
                          fontSize: '1.4583333333333333vw',
                          fontFamily: 'Gilroy-Bold',
                          lineHeight: '21px',
                          color: ' #000000',
                          'margin-top': '20px',
                        },
                        leaderRoleCss: {
                          width: '185px',
                          height: '63px',
                          fontSize: '16px',
                          'margin-top': '4.6992481203007515vh',
                          fontFamily: 'Gilroy-Regular',
                          lineHeight: '19px',
                          color: '#000000 ',
                        },
                      }}
                    />
                  ),
                  value: 0,
                },
                {
                  child: (
                    <LeadershipTeam
                      leader={{
                        img: 'icons/EA.svg',
                        fname: 'Expedite Adoption',
                        role: 'Enhance access to higher-value medical devices and procedures',
                      }}
                      custom={true}
                      customStyles={{
                        leaderNameCss: {
                          width: '227px',
                          fontSize: '1.4583333333333333vw',
                          fontFamily: 'Gilroy-Bold',
                          lineHeight: '21px',
                          color: ' #000000',
                          'margin-top': '20px',
                        },
                        leaderRoleCss: {
                          width: '185px',
                          height: '63px',
                          fontSize: '16px',
                          'margin-top': '4.6992481203007515vh',
                          fontFamily: 'Gilroy-Regular',
                          lineHeight: '19px',
                          color: '#000000 ',
                        },
                      }}
                    />
                  ),
                  value: 0,
                },
                {
                  child: (
                    <LeadershipTeam
                      leader={{
                        img: 'icons/IU.svg',
                        fname: 'Increase Utilization',
                        role: 'Enhance access to higher-value medical devices and procedures',
                      }}
                      custom={true}
                      customStyles={{
                        leaderNameCss: {
                          width: '227px',
                          fontSize: '1.4583333333333333vw',
                          fontFamily: 'Gilroy-Bold',
                          lineHeight: '21px',
                          color: ' #000000',
                          'margin-top': '20px',
                        },
                        leaderRoleCss: {
                          width: '185px',
                          height: '63px',
                          fontSize: '16px',
                          'margin-top': '4.6992481203007515vh',
                          fontFamily: 'Gilroy-Regular',
                          lineHeight: '19px',
                          color: '#000000 ',
                        },
                      }}
                    />
                  ),
                  value: 0,
                },
                {
                  child: (
                    <LeadershipTeam
                      leader={{
                        img: 'icons/RC.svg',
                        fname: 'Reduce Cancellations',
                        role: 'Enhance access to higher-value medical devices and procedures',
                      }}
                      custom={true}
                      customStyles={{
                        leaderNameCss: {
                          width: '227px',
                          fontSize: '1.4583333333333333vw',
                          fontFamily: 'Gilroy-Bold',
                          lineHeight: '21px',
                          color: ' #000000',
                          'margin-top': '20px',
                        },
                        leaderRoleCss: {
                          width: '185px',
                          height: '63px',
                          fontSize: '16px',
                          'margin-top': '4.6992481203007515vh',
                          fontFamily: 'Gilroy-Regular',
                          lineHeight: '19px',
                          color: '#000000 ',
                        },
                      }}
                    />
                  ),
                  value: 0,
                },
                {
                  child: (
                    <LeadershipTeam
                      leader={{
                        img: 'icons/IL.svg',
                        fname: 'Improve Learning',
                        role: 'Enhance access to higher-value medical devices and procedures',
                      }}
                      custom={true}
                      customStyles={{
                        leaderNameCss: {
                          width: '227px',
                          fontSize: '1.4583333333333333vw',
                          fontFamily: 'Gilroy-Bold',
                          lineHeight: '21px',
                          color: ' #000000',
                          'margin-top': '20px',
                        },
                        leaderRoleCss: {
                          width: '185px',
                          height: '63px',
                          fontSize: '16px',
                          'margin-top': '4.6992481203007515vh',
                          fontFamily: 'Gilroy-Regular',
                          lineHeight: '19px',
                          color: '#000000 ',
                        },
                      }}
                    />
                  ),
                  value: 0,
                },
                {
                  child: (
                    <LeadershipTeam
                      leader={{
                        img: 'icons/HC.svg',
                        fname: 'HIPAA Compliant',
                        role: 'Enhance access to higher-value medical devices and procedures',
                      }}
                      custom={true}
                      customStyles={{
                        leaderNameCss: {
                          width: '227px',
                          fontSize: '1.4583333333333333vw',
                          fontFamily: 'Gilroy-Bold',
                          lineHeight: '21px',
                          color: ' #000000',
                          'margin-top': '20px',
                        },
                        leaderRoleCss: {
                          width: '185px',
                          height: '63px',
                          fontSize: '16px',
                          'margin-top': '4.6992481203007515vh',
                          fontFamily: 'Gilroy-Regular',
                          lineHeight: '19px',
                          color: '#000000 ',
                        },
                      }}
                    />
                  ),
                  value: 0,
                },
              ],
            ]}
          />
        </div> */}
    </div>
  )
}

export default ExperienceTraining
