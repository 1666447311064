import React from 'react'
import Header1 from './components/Header1/index'
import SolutionsSubMenuHero from './components/SolutionsSubMenuHero'
import CardSection from './components/CardSection/CardSection'
import Features from './components/Features/Features'
import AwarenessPlatform from './components/AwarenessPlatform'
import TestimonialBg from './components/TestimonialBg/index'
import ImmersiveTraining from './components/immersiveTraining'
import Footer from './components/Footer/Footer'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import BurgerMenu from './components/Header/burgerMenu'
import { cdnUrl } from './constants/enum'

function DataAnalytics() {
  const [width] = useWindowSize()
  const featureData = [
    {
      title: 'Measure performance',
      image: '/performanceIcon.png',
      cardDes: ['Engagement metrics.', 'Analyze trends over time.', 'Get user feedback scores.'],
    },
    {
      title: 'Track usage',
      image: '/usageIcon.png',
      cardDes: [
        'Count the number of views.',
        'Identify trends over time.',
        'Easily search or filter usage data.',
      ],
    },
    {
      title: 'Understand your impact',
      image: '/understandIcon.png',
      cardDes: [
        'Get CO2 emission savings reports.',
        'Get time savings reports.',
        'Get cost savings reports.',
      ],
    },
    {
      title: 'Ensure compliance',
      image: '/complianceIcon.png',
      cardDes: [
        'Generate reports for each training session.',
        'Use metrics to enforce compliance requirements.',
      ],
    },
  ]
  const cardData = [
    {
      title: 'Instant Data Access',
      desc: 'Gain immediate access to comprehensive event data and gain valuable insights into participant engagement, event ratings, duration of engagement, and even environmental impact such as emissions saved. ',
      image: '/instantDataCard.png',
    },
    {
      title: 'In-Depth Analytics',
      desc: "Unlock a comprehensive view of the compound positive impact achieved through Immertec's technology and generate detailed reports that illuminate the daily benefits it brings to your operations. ",
      video: `${cdnUrl}/videos/solutions/DATA_ANALYTICS_IN_DEPTH_ANALYTICS.mp4`,
    },
    {
      title: 'Data-driven decision making',
      desc: 'Empower your organization to make informed decisions and utilize comprehensive insights for better strategic decision-making across divisions.',
      image: '/DataDrivenImage.png',
    },
  ]
  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header1 /> : <MobileHeader />}
      <SolutionsSubMenuHero
        title={'Data Analytics'}
        heroImage={`${cdnUrl}/photos/covers/DATA_ANALYTICS_COVER_APNG.png`}
      />
      <CardSection data={cardData} />
      <Features data={featureData} heading="The best viewing experience from anywhere " />
      <TestimonialBg />
      <ImmersiveTraining img={'/dataAnalyticsImmersive.png'} />
      <AwarenessPlatform />
      <Footer />
    </div>
  )
}

export default DataAnalytics
