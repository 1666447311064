import React from 'react'
import styles from './Gallery.module.scss'
import Slider from 'react-slick'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

let data = [
  {
    id: 1,
    img: [
      '/GallerySlider/1.jpg',
      '/GallerySlider/2.jpg',
      '/GallerySlider/3.jpg',
      '/GallerySlider/4.jpg',
      '/GallerySlider/5.jpg',
      '/GallerySlider/6.jpg',
      '/GallerySlider/7.jpg',
      '/GallerySlider/8.jpg',
      '/GallerySlider/5.jpg',
      '/GallerySlider/9.jpg',
      '/GallerySlider/10.jpg',
      '/GallerySlider/11.jpg',
    ],
    width: '15.625vw',
    height: '15.625vw',
  },
]

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon style={{ fill: '#ffff', fontSize: '2.2625vw' }} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon style={{ fill: '#ffff', fontSize: '2.2625vw' }} />
    </div>
  )
}

function Gallery() {
  const settings = {
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  }
  return (
    <div className={styles.Grid}>
      <div className={styles.container}>
        <div className={styles.gradient1}></div>

        <div className={styles.gradient2}></div>
        <Slider {...settings}>
          {data.map((item, index) =>
            item.img.map((img, i) => (
              <div className={styles.topContainer}>
                <div className={styles.subContainer}>
                  <div className={styles.subsubContainer}>
                    <img className={styles.left} src={item.img[0]} />
                    <div className={styles.rightContainer}>
                      <div className={styles.images}>
                        <img className={styles.img1} src={item.img[1]} />
                        <img className={styles.img2} src={item.img[2]} />
                        <img className={styles.img3} src={item.img[3]} />
                      </div>
                      <div className={styles.images}>
                        <img className={styles.img4} src={item.img[4]} />
                        <img className={styles.img5} src={item.img[5]} />
                        <img className={styles.img6} src={item.img[6]} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )),
          )}
        </Slider>
      </div>
    </div>
  )
}

export default Gallery
