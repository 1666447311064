export const mloc695 = {
  id: 'MLOC-695',
  name: 'Northeast Alliance Surgery Center (FKA Greater New Haven Ambulatory Surgery Center)',
  description:
    'As partner of Surgical Care Affiliates (SCA), Northeast Alliance Surgery Center works hard to ensure quality, safe and efficient patient care. They strive to make our facility a family friendly atmosphere, so you and your loved ones can have a stress-free experience.',
  status: '07-Event Ready (ES)',
  address1: '2080 Whitney Ave',
  address2: 'Suite 100',
  city: 'Hamden',
  state: 'CT',
  zip: '6518',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '41.37385',
  longitude: '-72.90571',
  photo: 'mloc-695.png',
  speciality: 'Otolaryngology',
}
