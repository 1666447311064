import * as React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  marginBottom: '2.898550724637681vh',
  border: '0',
  backgroundColor: '',
  borderRadius: '8px',
  justifyContent: 'center',

  '& .MuiAccordionSummary-content': {
    order: '1',
    fontFamily: 'Gilroy-Medium',
  },
  '& .MuiAccordionSummary-root': {
    borderRadius: '8px',
    fontFamily: 'Gilroy-Light',
    fontSize: '1.25vw',
    lineHeight: '2.7053140096618358vh',
    display: 'flex',
    alignItems: 'center',
    color: '#243746',
    background: '#E4E6ED',
    padding: ' 5px 10px',
    width: '64.84375vw',
    height: '7.115942028985508vh',
    '@media (max-width:1100px)': {
      width: '64.84375vw',
      height: '4.115942028985508vh',
      fontSize: ' 12px',
      paddingLeft: '5px',
    },
    '@media (max-width:900px)': {
      width: '90vw',
      height: '36px',
      fontSize: ' 12px',
      lineHeight: '17px',
      padding: ' 0px 15px',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(00deg) !important',
  },
  '& .MuiAccordionDetails-root': {
    border: 'none',
    width: '50vw',
    fontFamily: 'Gilroy-Light',
    fontSize: ' 1.25vw',
    lineHeight: '1.4583333333333333vw',
    padding: '2.9166666666666665vw 1.5625vw',

    '@media (max-width:900px)': {
      width: '80vw',
      height: '100%',
      fontSize: ' 12px',
      paddingLeft: '5px',
      lineHeight: '100%',
    },
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.4rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  paddingLeft: '10px',
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default function CustomizedAccordions({ faqData }) {
  const [expanded, setExpanded] = React.useState(null)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {faqData.map((ques) => (
        <Accordion key={ques.id} expanded={expanded === ques.id} onChange={handleChange(ques.id)}>
          <AccordionSummary
            expandIcon={
              expanded === ques.id ? (
                <ExpandLessIcon className={'expandLess'} />
              ) : (
                <ExpandMoreIcon className={'expandMore'} />
              )
            }
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            {ques?.question}
          </AccordionSummary>
          <AccordionDetails>
            {ques?.id == 3 ? (
              <div id="test">
                The Immertec app is available on{' '}
                <a href={ques.ios} target="_blank" rel="noreferrer">
                  iOS{' '}
                </a>{' '}
                and{' '}
                <a href={ques.android} target="_blank" rel="noreferrer">
                  Android{' '}
                </a>{' '}
                app stores.
              </div>
            ) : null}

            {ques.answers?.length > 0 && ques?.id !== 3 ? (
              <ul style={{ listStyle: 'none', rowGap: 20 }}>
                {ques?.answers.map((answer, index) => (
                  <li style={{ marginTop: 0, marginBottom: ' 2.6041666666666665vw' }} key={index}>
                    {answer}
                  </li>
                ))}
              </ul>
            ) : null}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
