export const iosLink = 'https://apps.apple.com/us/app/medoptic/id1517703273'
export const androidLink =
  'https://play.google.com/store/apps/details?id=com.immertec.medoptic.mobile'

export const FAQ = {
  'Participant Questions': [
    {
      id: 1,
      question: 'How does Immertec work?',
      answers: [
        'Immertec is an immersive peer-to-peer collaboration platform that allows remote attendees to view a live 3D stream of an operating room.',
        'Medical device companies create events on Immertec’s platform and invite physicians to register.',
        'Physicians register for Immertec events on the Immertec app - available for iOS and Android.',
        'Registered attendees are loaned a VR headset to attend the event. At the time of the event, physicians use their Immertec app to sync their VR headset to a live stream and virtually transport into an operating room from their home or office.',
      ],
    },
    {
      id: 2,
      question: '  How many medical feeds can I see at one time?',
      answers: [
        'A user can view up to 4 medical feeds from the operating room, including but not limited to, endoscopic views, x-rays, overhead views, and other medical imaging.',
      ],
      answerData: [],
    },
    {
      id: 3,
      question: 'How do I download the app?',
      answers: [
        `The Immertec app is available on <a href={${iosLink}}>iOS</a> and <a href ={${androidLink}}>Android</a> app stores.`,
      ],
      ios: iosLink,
      android: androidLink,
      answerData: [],
    },
    {
      id: 4,
      question: 'What can I expect during a live immersive event?',
      answers: [
        'Collaborate with others from your home or office with Immertec’s immersive technology. With multi-way communication, you can communicate live with the instructors and participants in real-time.',
        'Immertec streams a 3D, 180-degree view of the operating room with the ability to customize views of 4 live medical feeds.',
        'With a VR headset, users can virtually transport into an operating room from anywhere.',
      ],
      answerData: [],
    },
    {
      id: 5,
      question: 'How do I get a VR headset to join the event?',
      answers: [
        'Once you receive an invitation from an event or your event registration has been approved, Immertec’s app will prompt you to enter details to receive a VR headset for your event.',
        'Immertec loans users a VR headset sent to their address provided with a pre-paid return mailer to return the VR headset.',
      ],
      answerData: [],
    },
    {
      id: 6,
      question: 'Where can I register to join an event?',
      answers: [
        'Find new events on Immertec’s website or social media. You can also find upcoming events on Immertec’s app.',
        'Request access to an event on Immertec’s website or app.',
      ],
      answerData: [],
    },
  ],
  'Medical Device Questions': [
    {
      id: 7,
      question: 'How do I sponsor an event?',
      answers: [
        'Medical device companies can use our Contact page to get in touch with our Sales team to learn how to get started on Immertec’s platform.',
      ],
      answerData: [],
    },
    {
      id: 8,
      question: 'Is the training content recorded?',
      answers: [
        'Streamed content on Immertec’s platform is not recorded. All content is streamed live from the operating room. Live streaming allows us to provide an immersive peer-to-peer experience that promotes real-time collaboration.',
      ],
      answerData: [],
    },
    {
      id: 9,
      question: 'How many people can attend a live stream at one time? ',
      answers: [
        'For live patient cases, up to 5 participants allow for the best experience for real-time peer-to-peer collaboration. For lab events, we recommend up to 20 users join the event.',
      ],
      answerData: [],
    },
  ],
}
