import React from 'react'

import Footer from './components/Footer/Footer'
import TOStext from './components/TOStext/TOStext'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import Header from './components/Header1'

function TOS() {
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const [width] = useWindowSize()

  return (
    <div>
      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}
      <TOStext />
      <Footer />
    </div>
  )
}

export default TOS
