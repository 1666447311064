import React from 'react'
import Header1 from './components/Header1/index'
import CardSection from './components/CardSection/CardSection'
import Features from './components/Features/Features'
import AwarenessPlatform from './components/AwarenessPlatform'
import TestimonialBg from './components/TestimonialBg/index'
import ImmersiveTraining from './components/immersiveTraining'
import Footer from './components/Footer/Footer'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import BurgerMenu from './components/Header/burgerMenu'
import RecordingHero from './components/RecordingHero'

function RecordingLibrary() {
  const [width] = useWindowSize()
  const featureData = [
    {
      title: 'Cloud solutions',
      image: '/cloudIcon.png',
      cardDes: [
        'Store recording content on the cloud..',
        'Access your content on-demand from anywhere.',
        'Easily search and share your recordings.',
      ],
    },
    {
      title: 'Permissions',
      image: '/permissionIcon.png',
      cardDes: [
        'Control who can view your content.',
        'Easily manage access to all of your recordings.',
        'Monitor views with automated reports.',
      ],
    },
    {
      title: 'Video editing services',
      image: '/editingIcon.png',
      cardDes: [
        'Record any procedure.',
        'Create short clips for marketing purposes.',
        'Add your logos or additional content as needed.',
      ],
    },
    {
      title: 'Security',
      image: '/security.png',
      cardDes: [
        'SOC2 certification for enhanced security and trust.',
        'Compliance with privacy policies.',
        'Robust authentication to guarantee authorized access.',
      ],
    },
  ]
  const cardData = [
    {
      title: 'Capture every moment',
      desc: 'Effortlessly record any  procedure without the need for equipment installation. Immertec’s platform takes care of the entire recording process, including editing and publishing, saving you valuable time and effort.',
      image: '/CaptureCardImage.png',
    },
    {
      title: 'Expand reach and accessibility',
      desc: "Immertec's on-demand replay makes recorded procedures accessible to anyone, inside or outside your organization. Share knowledge with a wider audience, and empower them to learn from the best.",
      image: '/ExpandCardImage.png',
    },
    {
      title: 'Engage and collaborate in real-time',
      desc: 'Watch recorded procedures while seamlessly collaborating with peers in other locations using either VR headsets or the Immersive 2D viewer.',
      image: '/EngageCardImage.png',
    },
  ]
  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header1 /> : <MobileHeader />}
      <RecordingHero />
      {/* <ImmersiveHero title={'Recording Library'} heroImage={'/recording-hero.png'} /> */}
      <CardSection data={cardData} />
      <Features
        data={featureData}
        heading="Record important surgical procedures for education & awareness purposes"
      />
      <TestimonialBg />
      <ImmersiveTraining img={'/recordingLibraryImmersive.png'} />

      <AwarenessPlatform />

      <Footer />
    </div>
  )
}

export default RecordingLibrary
