import React from 'react'
import styles from './CardSection.module.scss'
import Immersive2DCard from '../Immersive2DCard/Immersive2DCard'
import Immersive2DCardInverted from '../Immersive2DCardInverted/Immersive2DCardInverted'

export default function CardSection({ data }) {
  return (
    <div className={styles.container}>
      {data?.map((item, index) =>
        index !== 1 ? (
          <Immersive2DCardInverted
            key={index}
            title={item.title}
            desc={item.desc}
            video={item.video}
            img={item.image}
          />
        ) : (
          <Immersive2DCard
            key={index}
            title={item.title}
            desc={item.desc}
            video={item.video}
            img={item.image}
          />
        ),
      )}
    </div>
  )
}
