import React from 'react'
import styles from './Features.module.scss'
import { Link } from 'react-router-dom'

export default function Features({ heading, data }) {
  return (
    <div className={styles.container}>
      <div className={styles.subcontainer}>
        <div className={styles.textContainer}>
          <div className={styles.heading}>{heading}</div>
        </div>

        <div className={styles.featureContainer}>
          {data?.map((item, index) => (
            <div className={styles.cardContainer} key={index}>
              <div className={styles.card}>
                <div className={styles.highlightContainer}>
                  <div className={styles.iconContainer}>
                    <img src={item.image} alt="" />
                  </div>
                  <div className={styles.text}>{item.title}</div>
                </div>
                <div className={styles.listContainer}>
                  <ul>
                    {item?.cardDes?.map((subItem, Subindex) => (
                      <li key={Subindex}>{subItem}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
          {/* <div className={styles.cardContainer}>
            <div className={styles.card}>
              <div className={styles.highlightContainer}>
                <div className={styles.iconContainer}>
                  <img src="/eye.png" alt="" />
                </div>
                <div className={styles.text}>The best views</div>
              </div>
              <div className={styles.listContainer}>
                <ul>
                  <li>180° main view of the OR </li>
                  <li>Effortlessly pan and navigate the main view</li>
                  <li>Up to four simultaneous HD medical feeds</li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* <div className={styles.cardContainer}>
            <div className={styles.card}>
              <div className={styles.highlightContainer}>
                <div className={styles.iconContainer}>
                  <img src="/colab.png" alt="" />
                </div>
                <div className={styles.text}>Collaboration</div>
              </div>
              <div className={styles.listContainer}>
                <ul>
                  <li>Real-time video communication</li>
                  <li>Annotate medical feeds</li>
                  <li>Show presentation slides</li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* <div className={styles.cardContainer}>
            <div className={styles.card}>
              <div className={styles.highlightContainer}>
                <div className={styles.iconContainer}>
                  <img src="/feed.png" alt="" />
                </div>
                <div className={styles.text}>Medical feeds</div>
              </div>
              <div className={styles.listContainer}>
                <ul>
                  <li>Show up to four simultaneous HD medical feeds </li>
                  <li>Easily move or expand any feed as needed </li>
                  <li>Focus on what’s most important to you</li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* <div className={styles.cardContainer}>
            <div className={styles.card}>
              <div className={styles.highlightContainer}>
                <div className={styles.iconContainer}>
                  <img src="/or-equipment.png" alt="" />
                </div>
                <div className={styles.text}>OR Equipment</div>
              </div>
              <div className={styles.listContainer}>
                <ul>
                  <li>Connect with any OR equipment (C-Arms, scopes, Ultrasound, etc.)</li>
                  <li>Support 3D medical equipment</li>
                  <li>Support AR technology from third parties</li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* <div className={styles.cardContainer}>
            <div className={styles.card}>
              <div className={styles.highlightContainer}>
                <div className={styles.iconContainer}>
                  <img src="/security.png" alt="" />
                </div>
                <div className={styles.text}>Security</div>
              </div>
              <div className={styles.listContainer}>
                <ul>
                  <li>SOC2 certification for enhanced security and trust</li>
                  <li>Compliance with privacy policies </li>
                  <li>Robust authentication to guarantee authorized access </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
        <div className={styles.btnCenter}>
          <Link to={'/instructors'}>
            <div className={styles.btnContainer}>
              <div className={styles.btnText}>Request a demo</div>
              <img src="/sra.png" alt="" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
