import React, { useState } from 'react'
import styles from './CoreValues.module.scss'
import CustomTabsList from '../CustomTabsList'
import { coreTabs } from '../../constants/data'
import { coreValuesTabPanel } from '../../constants/data/coreValuesTabPanelData'
import Card from './Card'
import Slider from 'react-slick'
import './coursel.css'
import CustomTabs from './CustomTabs'
let settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

function CoreValues() {
  const [currentTab, setCurrentTab] = useState(0)

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.containerWrapper}>
          <div className={styles.Header}>CORE VALUES</div>
          <Card
            imgUrl={coreValuesTabPanel[currentTab].imgUrl}
            text={coreValuesTabPanel[currentTab].text}
            description={coreValuesTabPanel[currentTab].description}
          />
          <CustomTabs currentTab={currentTab} tabs={coreTabs} onClick={handleChange} />
        </div>
      </div>
      <div className={styles.courselContainer}>
        <div className={styles.containerWrapper}>
          <div className={styles.Header}>CORE VALUES</div>
          <Slider {...settings} className="coreValues-slider">
            {coreValuesTabPanel.map((item, index) => (
              <Card
                key={`${item.text}_${index}`}
                imgUrl={item.imgUrl}
                text={item.text}
                description={item.description}
              />
            ))}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default CoreValues
