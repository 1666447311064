import React from 'react'
import styles from './HowImmersiveWorks.module.scss'

function HowImmersiveWorks() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.topSec}>
        <div className={styles.heading}>How Immersive Procedural Training Works</div>
        <div className={styles.mainContent}>
          <div className={styles.first}>
            <img src="/icons/laptop.png" />
          </div>
          <div className={styles.sideText1}>
            Medical device companies use the Immertec Dashboard to schedule events and invite
            attendees
          </div>
        </div>
      </div>

      <div className={styles.secondSec}>
        <div className={styles.sideText2}>
          Invited surgeons register for live surgical training events and request a loaner VR
          headset on Immertec’s Mobile App
        </div>
        <div className={styles.multiImage}>
          <img className={styles.twoImage} src="/icons/multiImage.png" />
        </div>
      </div>

      <div className={styles.thirdSec}>
        <div className={styles.thirdImage}>
          <img src="/3rd.png" />
        </div>
        <div className={styles.sideText3}>
          At the time of the event, users join the live procedure with their loaned VR headset
        </div>
      </div>
    </div>
  )
}

export default HowImmersiveWorks
