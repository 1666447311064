import { mloc1 } from './locationData/mloc-1.js'
import { mloc109 } from './locationData/mloc-109.js'
import { mloc127 } from './locationData/mloc-127.js'
import { mloc136 } from './locationData/mloc-136.js'
import { mloc145 } from './locationData/mloc-145.js'
import { mloc163 } from './locationData/mloc-163.js'
import { mloc181 } from './locationData/mloc-181.js'
import { mloc1920 } from './locationData/mloc-1920.js'
import { mloc1923 } from './locationData/mloc-1923.js'
import { mloc1926 } from './locationData/mloc-1926.js'
import { mloc1928 } from './locationData/mloc-1928.js'
import { mloc1931 } from './locationData/mloc-1931.js'
import { mloc1938 } from './locationData/mloc-1938.js'
import { mloc1940 } from './locationData/mloc-1940.js'
import { mloc1942 } from './locationData/mloc-1942.js'
import { mloc1963 } from './locationData/mloc-1963.js'
import { mloc199 } from './locationData/mloc-199.js'
import { mloc217 } from './locationData/mloc-217.js'
import { mloc226 } from './locationData/mloc-226.js'
import { mloc269 } from './locationData/mloc-269.js'
import { mloc319 } from './locationData/mloc-319.js'
import { mloc352 } from './locationData/mloc-352.js'
import { mloc3937 } from './locationData/mloc-3937.js'
import { mloc457 } from './locationData/mloc-457.js'
import { mloc458 } from './locationData/mloc-458.js'
import { mloc489 } from './locationData/mloc-489.js'
import { mloc497 } from './locationData/mloc-497.js'
import { mloc543 } from './locationData/mloc-543.js'
import { mloc5442 } from './locationData/mloc-5442.js'
import { mloc55 } from './locationData/mloc-55.js'
// import { mloc556 } from './locationData/mloc-556.js'
import { mloc581 } from './locationData/mloc-581.js'
import { mloc595 } from './locationData/mloc-595.js'
import { mloc639 } from './locationData/mloc-639.js'
import { mloc64 } from './locationData/mloc-64.js'
import { mloc665 } from './locationData/mloc-665.js'
import { mloc685 } from './locationData/mloc-685.js'
import { mloc686 } from './locationData/mloc-686.js'
import { mloc695 } from './locationData/mloc-695.js'
import { mloc700 } from './locationData/mloc-700.js'
import { mloc709 } from './locationData/mloc-709.js'
import { mloc73 } from './locationData/mloc-73.js'
import { mloc769 } from './locationData/mloc-769.js'
import { mloc840 } from './locationData/mloc-840.js'
import { mloc8915 } from './locationData/mloc-8915.js'
import { mloc905 } from './locationData/mloc-905.js'
import { mloc91 } from './locationData/mloc-91.js'
import { mloc919 } from './locationData/mloc-919.js'
import { mloc931 } from './locationData/mloc-931.js'

export const locationData = [
  mloc1,
  mloc109,
  mloc127,
  mloc136,
  mloc145,
  mloc163,
  mloc181,
  mloc1920,
  mloc1923,
  mloc1926,
  mloc1928,
  mloc1931,
  mloc1938,
  mloc1940,
  mloc1942,
  mloc1963,
  mloc199,
  mloc217,
  mloc226,
  mloc269,
  mloc319,
  mloc352,
  mloc3937,
  mloc457,
  mloc458,
  mloc489,
  mloc497,
  mloc543,
  mloc5442,
  mloc55,
  // mloc556,
  mloc581,
  mloc595,
  mloc639,
  mloc64,
  mloc665,
  mloc685,
  mloc686,
  mloc695,
  mloc700,
  mloc709,
  mloc73,
  mloc769,
  mloc840,
  mloc8915,
  mloc905,
  mloc91,
  mloc919,
  mloc931,
]
