import { DomainVerification } from '@mui/icons-material'
import React from 'react'
import styles from './card.module.scss'

function Card({ item }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={item.image} alt="" className={styles.image} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.textSubContainer}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.descriptionContainer}>
              <p>{item.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
