import React from 'react'
import styles from './Card.module.scss'
import { ButtonBase } from '@mui/material'
function Card({ item }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={item.image} className={styles.image} alt="" />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.mainText}>{item.mainText}</div>
          <div className={styles.btnContainer}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <ButtonBase
                className={styles.container}
                style={{
                  width: '12.984375vw',
                  height: ' 2.5vw',
                  transition: ' all 0.5s ease',
                  background: '#ffff',
                  borderRadius: '8px',
                  fontSize: '1.0416666666666667vw',
                  fontFamily: 'Gilroy-Medium',
                  color: '#243746',
                  border: '1px solid #243746',
                }}
              >
                {'Read More'}
              </ButtonBase>
            </a>
          </div>
          <div className={styles.MbtnContainer}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <ButtonBase
                className={styles.container}
                style={{
                  width: '250px',
                  height: '40px',
                  transition: ' all 0.5s ease',
                  background: '#ffff',
                  borderRadius: '8px',
                  fontSize: '16px',
                  fontFamily: 'Gilroy-Medium',
                  color: '#243746',
                  border: '1px solid #243746',
                }}
              >
                {'Read More'}
              </ButtonBase>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
