export const mloc700 = {
  id: 'MLOC-700',
  name: 'CardiaStream',
  description:
    'Nobody goes further to bring exceptional healthcare closer and more convenient to you. Our main facility is in Tyler, with an additional full-time facility in Athens. And to make our high-quality cardiovascular services even more accessible to you, our CardiaStream physicians also see patients at multiple practice locations throughout East Texas.\nMany CardiaStream physicians are Board Certified in both Internal Medicine and Cardiovascular Disease, with some having additional certifications in Nuclear Medicine, Electrophysiology, Cardiac Intervention, Echocardiography, and Peripheral Vascular Disease. Key members of our support staff include certified or licensed Physician Assistants, Registered Nurses (RN), Licensed Vocational Nurses (LVN), Nuclear Radiographic Technologists, Echo, and Vascular Sonographers.',
  status: '07-Event Ready (ES)',
  address1: '1783 Troup Highway',
  address2: null,
  city: 'Tyler',
  state: 'Texas',
  zip: '75701',
  category: 'Commercial',
  type: 'Office Based Lab',
  latitude: '32.31671',
  longitude: '-95.28028',
  photo: 'mloc-700.png',
  speciality: 'Urology',
}
