import React from 'react'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import ContactUs from './components/ContactUs/ContactUs'
import BurgerMenu from './components/Header/burgerMenu'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'

function ContactPage() {
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const [width] = useWindowSize()

  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}

      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      <ContactUs />
      <Footer />
    </div>
  )
}

export default ContactPage
