import { useEffect, useRef, useCallback } from 'react'

import './VideoScrollEffect.css'

export const VideoScrollEffect = ({ videoUrl, speed = 1 }) => {
  const playbackRate = 500 / speed
  const vidContainer = useRef()
  const vidWrapper = useRef()
  const videoTime = useRef()
  const video = useRef()

  const isOutOfViewport = (elem) => {
    const bounding = elem.getBoundingClientRect()
    const out = {}
    out.top = bounding.top < 0
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)
    return out
  }

  const onScroll = useCallback(() => {
    const vidContainerRef = vidContainer.current
    const vidWrapperRef = vidWrapper.current
    const vidRef = video.current
    vidContainerRef.style.height = `${Math.ceil(vidRef.duration) * playbackRate}px`
    let isOut = isOutOfViewport(vidContainerRef)

    if (isOut.top && isOut.bottom) {
      if (!videoTime.current) {
        videoTime.current = setInterval(() => {
          vidRef.currentTime = (window.pageYOffset - vidContainerRef.offsetTop) / playbackRate
        }, 40)
        if (!vidWrapperRef.classList.contains('fixed')) {
          vidWrapperRef.classList.add('fixed')
          if (vidWrapperRef.classList.contains('bottom')) {
            vidWrapperRef.classList.remove('bottom')
          }
        }
      }
    } else {
      if (videoTime.current) {
        clearInterval(videoTime)
        videoTime.current = null
        if (vidWrapperRef.classList.contains('fixed')) {
          vidWrapperRef.classList.remove('fixed')
        }
      }

      if (!isOut.bottom) {
        if (!vidWrapperRef.classList.contains('bottom')) {
          vidWrapperRef.classList.add('bottom')
        }
      }
    }
  }, [playbackRate])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  return (
    <>
      <div className="scroll_fx_video_heading">Experience Training Immersively</div>

      {videoUrl ? (
        <div id="scroll_fx_video_container" ref={vidContainer}>
          <div id="scroll_fx_video_wrapper" ref={vidWrapper}>
            <video width="100%" id="scroll_fx_video" preload="preload" ref={video}>
              <source type="video/mp4;" src={videoUrl} />
            </video>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default VideoScrollEffect
