import React, { useEffect, useState } from 'react'
import styles from './mobileheader.module.scss'
import { Link } from 'react-router-dom'
import StickyMenu from '../../utils/StickyMenu'
// import useWindowSize from '../../hooks/useWindowResize'

function MobileHeader({ handleOnChange }) {
  const [logoImg, setLogoImg] = useState('/headerLogo.png')

  useEffect(() => {
    StickyMenu('.mobile-header', 30)
  })

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
        setLogoImg('/headerLogoVector.png')
      } else {
        setLogoImg('/headerLogo.png')
      }
    })
  }, [])

  useEffect(() => {
    let collection = document.querySelectorAll('.bm-burger-bars')
    if (collection.length != 0) {
      Array(3)
        .fill('_')
        .map((item, index) => {
          collection[index]['style']['backgroundColor'] = '#fff'
        })
    }

    document.addEventListener('scroll', () => {
      if (collection.length > 0) {
        if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
          Array(3)
            .fill('_')
            .map((item, index) => {
              collection[index]['style']['backgroundColor'] = '#000'
            })
        } else {
          Array(3)
            .fill('_')
            .map((item, index) => {
              collection[index]['style']['backgroundColor'] = '#fff'
            })
        }
      }
    })
  }, [])

  return (
    <nav>
      <div className={`${styles.mainContainer} mobile-header`}>
        <div className={styles.headerContainer}>
          <Link to="/">
            <div className={styles.logoContainer}>
              <img src={logoImg} alt="" />
            </div>
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default MobileHeader
