import { specialties } from './../../../utils/specialty'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getLongInstructorVideoLink,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'

const data = [
  'Dr. Knoll received his medical degree from the University of Health Sciences, Chicago Medical School, North Chicago, Illinois. He completed an internship in general surgery and a residency in urology at the Mayo Clinic in Rochester, Minnesota. Dr. Knoll has been a practicing urologist since 1987.',
  'A frequent contributor to the medical press, Dr. Knoll has published over 100 peer-reviewed articles, abstracts, and book chapters. He has delivered numerous lectures and operated both nationally and internationally.',
  'Dr. Knoll has been a principle investigator in over 110 basic science and clinical trials of new devices and pharmacotherapies for various urological diseases including new devices and pharmacotherapies for the diagnosis and treatment of male erectile dysfunction, male and female urinary incontinence, and benign prostatic hypertrophy.',
]

const specialty = specialties.urology.name
const id = 'knoll'

export const knollData = {
  id,
  firstName: 'Dean',
  lastName: 'Knoll',
  middleName: undefined,
  suffix: 'MD',
  title: 'DEAN KNOLL, MD',

  // Not using actual web link. to be used when we dynamically pull instructors.
  heroImage: getHeroImageOfInstructor(specialty, id), // Just use the name of the file. Path will be set during render time.
  // Allows to switch between the desktop/mobile images.
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileLink: `/Instructor/${id}`,
  profileCover: getInstructorProfileCover(id),
  events: getInstructorEvents(id),
  shortVideoCard: getInstructorCardPicture(id),
}
