export const mloc226 = {
  id: 'MLOC-226',
  name: 'Stanford Medical',
  description:
    "Stanford University School of Medicine is the medical school of Stanford University and is located in Stanford, California. It traces its roots to the Medical Department of the University of the Pacific, founded in San Francisco in 1858. This medical institution, then called Cooper Medical College, was acquired by Stanford in 1908. The medical school moved to the Stanford campus near Palo Alto, California, in 1959.\nThe School of Medicine, along with Stanford Health Care and Lucile Packard Children's Hospital, is part of Stanford Medicine. Stanford Health Care was ranked the fourth best hospital in California (behind UCLA Medical Center, Cedars-Sinai Medical Center, and UCSF Medical Center, respectively)",
  status: '07-Event Ready (ES)',
  address1: '5555 W Las Positas Blvd',
  address2: null,
  city: 'Pleasanton',
  state: 'CA',
  zip: '94588',
  category: 'Research Collaboration',
  type: 'Hospital',
  latitude: '37.69339',
  longitude: '-121.88034',
  photo: 'mloc-226.png',
  speciality: 'Interventional Spine',
}
