import React from 'react'
import styles from './OpenPosition.module.scss'
import CustomizedAccordions from '../FAQS/Accordion/AccordionComponent'
import { FAQ } from '../../constants/data'

function OpenPosition({ reference }) {
  return (
    <div ref={reference} className={styles.mainContainer}>
      <div className={styles.title}>Open Positions</div>
      <div className={styles.subText}>
        Like what you see? The next step in your career might be listed below. If not, feel free to
        drop your information into the ‘Future Opportunities’ so we can consider your talents as we
        grow.
      </div>
      <div className={styles.positionContainer}>
        <iframe
          className={styles.iframe}
          src="https://app.trinethire.com/companies/23635-immertec/jobs/embed"
        ></iframe>
      </div>
    </div>
  )
}

export default OpenPosition
