import React from 'react'
import styles from './RecordingHero.module.scss'
import SolutionNavbar from '../SolutionNavbar'
import { cdnUrl } from '../../constants/enum'

function RecordingHero() {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <SolutionNavbar />
        <div className={styles.heading}>{'Recording Library'}</div>
        <div className={styles.heroImage}>
          <img className={styles.laptopBg} src={'/recording-hero.png'} />

          <div className={styles.laptop}>
            <img
              src={`${cdnUrl}/photos/covers/RECORDING_LIBRARY_LAPTOP_SCREEN.png`}
              type="video/mp4"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecordingHero
