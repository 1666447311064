export const doctorDepartmentCategory = {
  0: 'Interventional Spine',
  1: 'Interventional Spine',
  2: 'Interventional Spine',
  3: 'Interventional Spine',
  4: 'Endovascular',
  5: 'Endovascular',
  6: 'Endovascular',
  7: 'Endovascular',
  8: 'Endovascular',
  9: 'Spine',
  10: 'Spine',
  11: 'Spine',
  12: 'Orthopedics',
  13: 'Orthopedics',
  14: 'Orthopedics',
  15: 'Orthopedics',
  16: 'Otolaryngology',
  17: 'Otolaryngology',
}

export const customMobileTabsValue = {
  'Interventional Spine': 0,
  Endovascular: 1,
  Spine: 2,
  Orthopedics: 3,
  Otolaryngology: 4,
}

export const doctorDepartmentCategoryTabClick = {
  'Interventional Spine': 3,
  Endovascular: 4,
  Spine: 9,
  Orthopedics: 12,
  Otolaryngology: 15,
}

export const tabsForTeam = [
  {
    label: 'ALL',
    value: 0,
  },
  {
    label: 'SALES',
    value: 1,
  },
  {
    label: 'FINANCE',
    value: 2,
  },
  {
    label: 'MARKETING',
    value: 3,
  },
  {
    label: 'OPERATIONS',
    value: 4,
  },
  {
    label: 'ENGINEERING',
    value: 5,
  },
]

export const tabsForProduct = [
  {
    label: 'FOR COMPANIES & ORGANIZATIONS',
    value: 0,
  },
  {
    label: 'FOR COMPANIES & ORGANIZATIONS',
    value: 1,
  },
  {
    label: 'FOR MEDICAL PROFESSIONALS',
    value: 2,
  },
  {
    label: 'FOR MEDICAL FACILITIES & TOOLS',
    value: 3,
  },
]

export const coreTabs = [
  {
    label: 'INTEGRITY',
    value: 0,
  },
  {
    label: 'COMMITMENT',
    value: 1,
  },
  {
    label: 'INNOVATION',
    value: 2,
  },
  {
    label: 'COMMUNITY',
    value: 3,
  },
  {
    label: 'CURIOSITY',
    value: 4,
  },
]

export const productTabs = [
  {
    label: 'ENDOVASCULAR',
    value: 'endovascular',
  },
  {
    label: 'SPINE',
    value: 'spine',
  },
  {
    label: 'INTERVENTIONAL SPINE',
    value: 'interventionalSpine',
  },
  {
    label: 'ORTHOPEDICS',
    value: 'orthopedics',
  },
  {
    label: 'OTOLARYNGOLOGY',
    value: 'otolaryngology',
  },

  {
    label: 'UROLOGY',
    value: 'urology',
  },
]

export const locationsChips = {
  'Office Based Lab': {
    title: 'Office Based Lab',
    icon: '/LocationPage/Vector.png',
    styles: {
      background: '#FFE1B8',
      border: ' 1px solid #FF9502',
      color: ' #FF9502',
    },
  },
  'Company HQ Lab': {
    title: 'Company HQ Lab',
    icon: '/LocationPage/Vector5.png',
    styles: {
      background: '#D9E4FD',
      border: '1px solid #3754E2',
      color: ' #3754E2',
    },
  },
  'Bioskills Lab': {
    title: 'Bioskills Lab',
    icon: '/LocationPage/Vector3.png',
    styles: {
      background: '#EAFAF6',
      border: '1px solid #A3EBD5',
      color: ' #1F8E6D',
    },
  },
  Hospital: {
    title: 'Hospital',
    icon: '/LocationPage/Vector4.png',
    styles: {
      background: '#ECF1F9',
      border: '1px solid #ABC2E3',
      color: '#2B4F82',
    },
  },
  Practice: {
    title: 'Practice',
    icon: '/LocationPage/Vector2.png',
    styles: {
      background: '#ECDCFC',
      border: '1px solid #6932C9',
      color: '#2B4F82',
    },
  },
  'Surgery Center': {
    title: 'Surgery Center',
    icon: '/LocationPage/Vector1.png',
    styles: {
      background: '#FEE6EA',
      border: '1px solid #FB637E',
      color: '#FB637E',
    },
  },
}

export const locationChipSelectionStyles = {
  inactive: {
    styles: {
      background: '#FBFCFD',
      border: '1px solid #929BA3',
      color: '#929BA3',
    },
  },
  active: {
    styles: {
      background: '#EAFAF6',
      border: '1px solid #A3EBD5',
      color: '#1F8E6D',
    },
  },
}
