import React from 'react'
import { Link } from 'react-router-dom'
import styles from './homeSlider.module.scss'
import ContainedButton from '../ContainedButton'
import OutlineButton from '../OutlineButton'
import TabBar from './tabsBar'
import SwiperCoverflow from './sliderComponent/index'
import CustomMobileTabs from './CustomMobileTabs'
import getUrlForm from '../../utils/getFormUrl'
import getFormUrl from '../../utils/getFormUrl'
import { specialties } from '../../utils/specialty'

function HomeSlider({
  currentCover,
  handleOnChange,
  tabs,
  handleOnTabClick,
  handleSetActiveSlider,
  currentCoverTextIndex,
  sliderRef,
  doctors,
}) {
  return (
    <>
      <div className={styles.homeSliderContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              IMMERSIVE <br /> PEER-TO-PEER <br /> COLLABORATION <br /> PLATFORM
            </div>
            <div className={styles.subTitle}>
              Learn the latest procedures from your home or office
            </div>
            <div className={styles.btnContainer}>
              <Link to="/request-a-demo">
                <ContainedButton
                  customStyles={{
                    width: '12.03125vw',
                    height: '2.998389694041868vw',
                    backgroundColor: '#47D7AC',
                  }}
                  textStyles={{
                    fontFamily: 'Gilroy-Bold',
                    fontSize: '1.0416666666666667vw',
                    fontWeight: '100',
                    color: '#243746',
                  }}
                  label={'Request a Demo'}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.rightSliderContainer}>
          <SwiperCoverflow
            handleOnChange={handleOnChange}
            sliderRef={sliderRef}
            handleSetActiveSlider={handleSetActiveSlider}
            doctors={doctors}
          />
          <div className={styles.rightOverlay} />
          {currentCover && (
            <div className={styles.textContainer}>
              <div className={styles.rightOverlayM} />
              <div className={styles.text}>{currentCover.title}</div>
              <div className={styles.subTextContainer}>
                <img
                  src={specialties[currentCover?.category]?.greenIcon}
                  alt={''}
                  className={styles.heartIconStyles}
                />
                <div className={styles.subText}>{specialties[currentCover?.category]?.label}</div>
              </div>
              <div className={styles.btnContainer}>
                <Link to="/request-a-demo">Request a Demo</Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.mobileTabs}>
        <CustomMobileTabs
          activeTab={currentCover?.category}
          onChangeTab={handleOnTabClick}
          tabsForTeam={tabs}
        />
      </div>
      <div className={styles.tabContainer}>
        <TabBar
          activeTab={currentCover?.category}
          tabs={tabs}
          handleOnTabClick={handleOnTabClick}
        />
      </div>
    </>
  )
}

export default HomeSlider
