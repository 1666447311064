import React from 'react'
import styles from './numberSection.module.scss'

import { useCounter } from '../../hooks/useCounter'

import { useInView } from 'react-intersection-observer'

function CountUpStat({ summaryStyle, labelStyle, fontColor, value, label, endIcon }) {
  const { ref, inView } = useInView({
    threshold: 0,
  })
  const currentVal = useCounter(value, inView)
  return (
    <div ref={ref} style={{ color: fontColor }} className={styles.numberSection}>
      <div className={summaryStyle}>
        {' '}
        {currentVal.toLocaleString('en-US')}
        {endIcon}{' '}
      </div>
      <div className={labelStyle}>{label}</div>
    </div>
  )
}

function NumberSection() {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.title}>Immertec by the Numbers</div>
          <div className={styles.description}>
            Our customers see significant value from using our platform on a day-to-day basis.{' '}
          </div>
        </div>
        <div className={styles.rightTopContainer}>
          <div className={styles.rightContainer}>
            <div className={styles.statSection}>
              <CountUpStat
                summaryStyle={styles.number}
                labelStyle={styles.detail}
                fontColor="#3B6CB3"
                value={80}
                label={'Average Net Promoter Score'}
              />
            </div>
            <div className={styles.statSection}>
              <CountUpStat
                summaryStyle={styles.number}
                labelStyle={styles.detail}
                fontColor="#3B6CB3"
                value={50}
                label={'Sales Cycle Reduction'}
                endIcon={'%'}
              />
            </div>
            <div className={styles.statSection}>
              <CountUpStat
                summaryStyle={styles.number}
                labelStyle={styles.detail}
                fontColor="#3B6CB3"
                value={70}
                label={'Engagement Score in VR'}
                endIcon={'%'}
              />
            </div>
            <div className={styles.statSection}>
              <CountUpStat
                summaryStyle={styles.number}
                labelStyle={styles.detail}
                fontColor="#3B6CB3"
                value={1425}
                label={'Surgeons Trained'}
                endIcon={'+'}
              />
            </div>
          </div>
          {/* <div className={styles.Mbtn}>Learn More</div> */}
        </div>
      </div>
    </div>
  )
}

export default NumberSection
