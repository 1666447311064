import { specialties } from './../../../utils/specialty'
import {
  getShortInstructorVideoLink,
  getLongInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getInstructorProfileCover,getInstructorCardPicture,
  getInstructorEvents,
} from '../../../utils/getAssetLink'

const data = [
  `Dr. Jahangir Asghar is a spine surgeon with over 18 years of quality care and experience. His concentration lies in the treatment of patients of all ages with scoliosis and complex deformities of the spine. Dr. Asghar utilizes cutting-edge technology and a holistic approach when treating patients. He practices at the Cantor Spine Center at The Paley Orthopedic & Spine Institute where oversees clinical research, ultrasonic, and robotic technologies for adult and pediatric scoliosis.`,
  `Dr. Asghar attended St. George’s University School of Medicine in West Indies, Grenada, and completed his residency in orthopedic surgery at the University of Medicine and Dentistry of New Jersey in Newark. He finished a fellowship in complex spine and spine deformity at the Shriners Hospital for Children in Philadelphia, Pennsylvania. Before his time at Cantor Spine Center at The Paley Orthopedic & Spine Institute, Dr. Asghar was part of the team at Nicklaus Children’s Hospital in Miami, Florida. He is board-eligible with the American Board of Orthopaedic Surgery.`,
  `Dr. Asghar approaches patient visits in a highly communicative fashion, and always opts for the most noninvasive, comfortable solution possible. Through his creation of revolutionary, minimally invasive ultrasonic procedures, he is able to successfully treat conditions such as spinal deformity and scoliosis. Dr. Asghar and his spinal deformity team have gained national recognition due to the safety of their surgeries, advanced technology, and the quick recovery of their patients.`,
  `Dr. Asghar has been published over 40 times, and his charitable work in pediatric spinal deformities and scoliosis has taken him to service trips across the world. He has performed surgeries and aided communities in Asia, South America, and the Middle East. He is a founding member of the Society for Minimally Invasive Spine Surgery and a member of the Scoliosis Research Society (SRS), the North American Spine Society (NASS), Florida Orthopaedic Society, and the International Society for Advancement Spine Surgery (ISASS).`,
  `Dr. Asghar is thrilled to bring his extensive experience in spinal health to patients of Cantor Spine Center at The Paley Orthopedic and Spine Institute and aims to continue furthering advancement in the field.`,
]
const specialty = specialties.spine.name
const id = 'asghar'
export const asgharData = {
  firstName: 'John',
  lastName: 'Asghar',
  middleName: undefined,
  suffix: 'MD',

  // Not using actual web link. to be used when we dynamically pull instructors.
  id,
  title: 'JAHANGIR "JOHN" ASGHAR, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileLink: `/Instructor/${id}`,
  profileCover: getInstructorProfileCover(id),
  shortVideoCard: getInstructorCardPicture(id),

  events: getInstructorEvents(id),
}
