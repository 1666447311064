export const mloc3937 = {
  id: 'MLOC-3937',
  name: 'Eminent Medical Center',
  description:
    'Eminent Medical Center is a full service physician-owned and operated hospital located in Richardson, TX. Their center features three operating suites, along with a pharmacy, laboratory, emergency department, tranquil pre- and postoperative zones, and relaxing patient rooms designed with patient’s comfort in mind.',
  status: '05-Cart Install (SI)',
  address1: '1351 W President George Bush Hwy',
  address2: '1351 W President George Bush Hwy, Richardson, TX 75080',
  city: 'Richardson',
  state: 'TX',
  zip: '75080',
  category: 'Commercial',
  type: 'Hospital',
  latitude: 32.99977,
  longitude: -96.75558,
  photo: 'mloc-3937.png',
  speciality: 'Spine',
}
