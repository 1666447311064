import React from 'react'
import styles from './countUp.module.scss'
import { useCounter } from '../../hooks/useCounter'

import { useInView } from 'react-intersection-observer'

function CountUpStat({ fontColor, value, label }) {
  const { ref, inView } = useInView({
    threshold: 0,
  })
  const currentVal = useCounter(value, inView)
  return (
    <div ref={ref} style={{ color: fontColor }} className={styles.container}>
      <div className={styles.summaryText}> {currentVal} </div>
      <div className={styles.labelText}>{label}</div>
    </div>
  )
}

export default CountUpStat
