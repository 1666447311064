import { DomainVerification } from '@mui/icons-material'
import React from 'react'
import styles from './card.module.scss'
import { ButtonBase } from '@mui/material'
function Card({ item }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={item.image} className={styles.image} alt="" />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.heading}>{item.mainText}</div>
          <div className={styles.subheading}>{item.subText}</div>

          <div className={styles.btnContainer}>
            {' '}
            <a href={item.link}>
              <ButtonBase className={styles.btnStyle}>{'Read More'}</ButtonBase>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
