import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstructorProfileCover,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'

const data = [
  `Chad B. Stephens, DO has over 16 years of experience focusing on interventional pain management and sports medicine and serves the communities of Southlake and Fort Worth, Texas, and the surrounding areas at his state-of-the-art practice, Noble Pain Management & Sports Medicine.`,

  `Noble Pain Management & Sports Medicine is founded on the mission that Dr. Stephens and his staff relentlessly strive to improve their patients’ quality of life, continually embrace medical innovation, and deliver excellence in clinical services. Dr. Stephens and his staff believe in the power of leading-edge technology, science and medicine, and stays up-to-date with the latest advancements in sports medicine and pain medicine. For example, he is an early adopter of innovative and life-changing procedures such as SphenoCath, a minimally invasive solution for migraine sufferers, and TenJet, a minimally-invasive procedure for tendon pain and injuries, as well as, peripheral and spinal cord stimulators, and COOLIEF radiofrequency neurotomy.`,

  `At Noble Pain Management & Sports Medicine, the team uses cutting-edge technology to treat patients with minimally invasive procedures rather than medicine management. This is done in an effort to help patients of all ages with acute or chronic conditions get back to playing the sports they love with less downtime. The qualified team at Noble Pain Management & Sports Medicine look forward to having the opportunity to serve your needs with the goal of improving your emotional and physical well-being.`,
]
const specialty = specialties.interventionalSpine.name
const id = 'stephens'
export const stephensData = {
  firstName: 'Chad',
  lastName: 'Stephens',
  middleName: undefined,
  suffix: 'DO',
  id,
  title: 'CHAD STEPHENS, DO',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].name, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  shortVideoCard: getInstructorCardPicture(id),

  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
