import React from 'react'
import styles from './bussinesspartner.module.scss'

function BussinessPartner() {
  return (
    <div className={styles.topcontainer}>
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={styles.title}>Immertec Business Partners</div>
          <div className={styles.brandsContainer}>
            <img src="/terumo.png" alt="" />
            <img src="/boston.png" alt="" />
            <img src="/biomet.png" alt="" />
            <img src="/bd.png" alt="" />
            <img src="/noble.png" alt="" />
            <img src="/mec.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BussinessPartner
