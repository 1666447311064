import styles from './pausedOverlay.module.scss'

const PausedOverlay = ({ image, title, name }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>{image && <img src={image} alt="" />}</div>
    </div>
  )
}

export default PausedOverlay

// <div className={styles.titleContainer}>
//     <div className={styles.logoContainer}>
//       <img src='/icons/heartIcon.png' alt='' />
//     </div>
//     <div className={styles.title}>{title}</div>
//   </div>
//   <div className={styles.nameContainer}>
//   </div>
