export const mloc840 = {
  id: 'MLOC-840',
  name: 'West Coast Joint & Spine',
  description:
    'The West Coast Joint and Spine Center performs procedures in the specialty areas of Ophthalmology, Orthopedic Joints and Spine, Pain Management, and Sports Medicine. The facility is accredited by The Joint Commission.',
  status: '07-Event Ready (ES)',
  address1: '8135 Saratoga Way',
  address2: null,
  city: 'El Dorado Hills',
  state: 'CA',
  zip: '95762',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '38.65323',
  longitude: '-121.07354',
  photo: 'mloc-840.png',
  speciality: 'Spine',
}
