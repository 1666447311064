import React, { useEffect, useState } from 'react'
import AreYouInterested from './components/AreYouInterestedBanner'
import Collaborate from './components/Collaborate'
import CollaborateSection from './components/CollaborateSection'
import DoctorSaying from './components/DoctorSaying'
import EcsSection from './components/EcsSectionHome'
import FAQS from './components/FAQS/FAQS'
import Footer from './components/Footer/Footer'
import FreeForDoc from './components/FreeforDoc'
import BurgerMenu from './components/Header/burgerMenu'
import Header from './components/Header/Header'
import HomeSlider from './components/homeSlider'
import Instructors from './components/Instructors/Instructors'
import SCYPTP from './components/SCYPTP'
import StatSection from './components/StatsSection'
import { useHomeCourselContext } from './providers/HomeCourselProvider'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'

function InstructorsPage() {
  const specialty = window.localStorage.getItem('specialty')
  const [isHoveredForMobile, setIsHoveredForMobile] = useState(false)

  const {
    categories,
    shuffledDoctors,
    currentCover,
    currentCoverTextIndex,
    sliderRef,
    sliderChangeRef,
    handleSetActiveSlider,
    handleOnCategoryTabClick,
    handleChangeOnCurrentCover,
  } = useHomeCourselContext()

  useEffect(() => {
    window.localStorage.removeItem('specialty')
    setTabOnRedirect()
  }, [])

  const setTabOnRedirect = () => {
    if (specialty) handleOnCategoryTabClick({}, specialty)
  }

  const handleOnMobileHoverEnter = () => {
    setIsHoveredForMobile(true)
  }

  const handleOnMobileHoverLeave = () => {
    setIsHoveredForMobile(false)
  }
  const [width] = useWindowSize()

  return (
    <div className="App" ref={sliderChangeRef}>
      <BurgerMenu handleOnChange={handleOnCategoryTabClick} />
      {/* <Header handleOnChange={handleOnCategoryTabClick} /> */}
      {width > 900 ? <Header /> : <MobileHeader />}

      <HomeSlider
        tabs={categories}
        currentCover={currentCover}
        currentCoverTextIndex={currentCoverTextIndex}
        handleChangeOnCurrentCover={handleChangeOnCurrentCover}
        handleOnTabClick={handleOnCategoryTabClick}
        handleSetActiveSlider={handleSetActiveSlider}
        sliderRef={sliderRef}
        doctors={shuffledDoctors}
      />
      <CollaborateSection cover={currentCover} isHoveredForMobile={isHoveredForMobile} />
      <Instructors
        instructors={currentCover}
        handleOnMobileHoverEnter={handleOnMobileHoverEnter}
        handleOnMobileHoverLeave={handleOnMobileHoverLeave}
      />
      {/* <AreYouInterested
        bannerTitle={'Join the Immertec instructor team'}
        btnTitle={'Apply Here'}
        btnUrl={'host'}
        bgcolor={'#F6F7FB'}
      /> */}
      <EcsSection specialty={currentCover?.category} />
      <Collaborate />
      <FreeForDoc />
      <DoctorSaying />
      <StatSection />
      <SCYPTP />
      <FAQS />
      <Footer />
    </div>
  )
}

export default InstructorsPage
