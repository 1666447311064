import React from 'react'
import styles from './rightArrow.module.scss'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

function RightArrow(props) {
  const { onClick } = props
  return (
    <div className={`${styles.ArrowContainer}`} onClick={onClick}>
      <ChevronLeftIcon />
    </div>
  )
}

export default RightArrow
