import { useState, React, useEffect } from 'react'
import styles from './aboutIntro.module.scss'

import { instructorsData } from '../../constants/data/instructorData'
import { useInView } from 'react-intersection-observer'
import FullScreenVideo from '../Fullscreen/FullScreenVideo'

function AboutIntro({ instructorId, reference }) {
  // TODO: Setup default instructor
  if (!instructorId) instructorId = 'default'
  const data = instructorsData[instructorId]

  const description = data.description.map((text, index) => {
    return <p key={`${text}_${index}`}>{text}</p>
  })

  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const { ref, inView } = useInView({
    threshold: 1,
  })

  useEffect(() => {
    setIsVideoPlaying(inView)
  }, [inView])

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.text}>About {data.name}</div>
        </div>
        <div className={styles.textContainer}>{description}</div>
        {/** reference to scroll to right above ecs section.*/}
        <div ref={reference}></div>
        <div className={styles.imageContainer} ref={ref}>
          <FullScreenVideo
            video={data?.longVideoLink}
            id={data?.id}
            visitProfile={false}
            focused={isVideoPlaying}
          />
        </div>
      </div>
    </div>
  )
}

export default AboutIntro
