import React from 'react'
import styles from './solutionCard.module.scss'
import { Link } from 'react-router-dom'

function SolutionCard({ title, Video, subtitle, description, btnLink }) {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.leftContainer}>
          <video autoPlay playsInline loop muted width="100%">
            <source src={Video} type="video/mp4" />
          </video>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.subTitle}>{subtitle}</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
          <Link to={btnLink}>
            <div className={styles.btnContainer}>
              <div className={styles.btnText}>Find out more</div>
              <img src="/sra.png" alt="" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SolutionCard
