import React from 'react'
import styles from './ecsCardSlider.module.scss'
import ContainedButton from '../../ContainedButton'
import getUrlForm from '../../../utils/getFormUrl'
import { Link } from 'react-router-dom'

function EcsSliderCard({ item }) {
  item = item.img ? item.img : item
  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <div className={styles.imageContainer}>
          <img src={item} alt={''} className={styles.image} />
        </div>
        <div className={styles.btnContainer}>
          <Link to="/request-a-demo">
            <ContainedButton
              customStyles={{
                width: '8.03125vw',
                height: '2.4442230273752013vw',
                backgroundColor: '#47D7AC',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Medium',
                fontSize: '0.7416666666666667vw',
                fontWeight: 2500,
                lineHeight: ' 2.4442230273752013vw',
                color: '#243746',
              }}
              label={'Request a Demo'}
            />
          </Link>
        </div>
        <div className={styles.MbtnContainer}>
          <Link to="/request-a-demo">
            <ContainedButton
              customStyles={{
                width: '100px',
                height: '50px',
                backgroundColor: '#47D7AC',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Medium',
                fontSize: '14px',
                fontWeight: 2500,
                lineHeight: ' 17px',
                color: '#243746',
              }}
              label={'Request a Demo'}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default EcsSliderCard
