export const mloc905 = {
  id: 'MLOC-905',
  name: 'Holy Cross Health',
  description:
    'Holy Cross Health fka Holy Cross Hospital is located in Fort Lauderdale, Florida. The hospital is a non-profit, 557-bed Catholic hospital which operates in the spirit of the Sisters of Mercy. It is a member of Trinity Health',
  status: '10-Inactive (Sales/ES/SI)',
  address1: '4725 N Federal Hwy',
  address2: null,
  city: 'Fort Lauderdale',
  state: 'FL',
  zip: '33308',
  category: 'Commercial',
  type: 'Hospital',
  latitude: '26.18666',
  longitude: '-80.11965',
  photo: 'mloc-905.png',
  speciality: 'Spine',
}
