import React from 'react'
import styles from './OurApproach.module.scss'

function OurApproach() {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <img className={styles.image} src="ourApproach.png" />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.textContainer}>
          <div className={styles.mainText}>Our Approach</div>
          <div className={styles.subText}>
            We work with leading universities, colleges and organizations to continually evaluate
            and improve learning in 3D VR.
            <br />
            <br /> By collaborating with the nation’s top teaching hospitals and institutions, we
            are improving our product to deliver the best experience to medical professionals.
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurApproach
