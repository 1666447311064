import React from 'react'
import styles from './PartnerLogo.module.scss'

function PartnerLogo({ item }) {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.logoContainer}>
        <img className={styles.logoImage} src={item.img} />
      </div>
    </div>
  )
}

export default PartnerLogo
