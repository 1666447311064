export const mloc1923 = {
  id: 'MLOC-1923',
  name: 'Alpha Surgical Center',
  description:
    'As a top-rated medical facility, Alpha Surgical Center is committed to providing exceptional patient care.\nTheir outpatient surgical facility provides a safe and convenient alternative to inpatient hospitalization with a more caring and personalized treatment aimed at enhancing their surgical experience.\nAlpha Surgical Center provides patients with opportune same-day surgery in a range of medical disciplines.\nThe staff comprises highly reputable, licensed surgeons who work alongside certified nurses and other highly specialized medical professionals.\nTheir team is known for their compassion and dedication to providing extraordinary quality care.\nAlpha Surgical Center’s highly trained administrative staff work tirelessly to ensure our patient’s satisfaction.\nThey provide hassle-free registration and a simple billing process, as well as patient advocate services to help you navigate the complicated insurance landscape.',
  status: '07-Event Ready (ES)',
  address1: '24727 Tomball Pkwy',
  address2: 'Suite 100',
  city: 'Tomball',
  state: 'TX',
  zip: '77375',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '30.04093',
  longitude: '-95.60914',
  photo: 'mloc-1923.png',
  speciality: 'Spine',
}
