import React from 'react'
import styles from './sustainability.module.scss'

function Sustainability() {
  return (
    <div className={styles.container}>
      <div className={styles.gradient} />
      <div className={styles.subcontainer}>
        <div className={styles.column1}>
          <h3>Sustainability reporting</h3>
          <div className={styles.paraContainer}>
            <p>
              Immertec's remote training solutions offer a sustainable alternative to traditional
              in-person training, reducing the need for travel and lowering carbon emissions.
            </p>
            <p>CO2 reports enable customers to track emissions and meet sustainability goals.</p>
          </div>
        </div>
        <div className={styles.imagesContainer}>
          <div className={styles.column2}>
            <img src="/sr1.png" alt="" />
          </div>
          <div className={styles.column3}>
            <img src="/sr2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sustainability
