import React, { useState, useEffect } from 'react'
import styles from './collaborate.module.scss'

import { useInView } from 'react-intersection-observer'
import { cdnUrl } from './../../constants/enum'
import FullScreenVideo from '../Fullscreen/FullScreenVideo'

const videoLink = `${cdnUrl}/videos/other/homepagecommercial.mp4`
function Collaborate() {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const { ref, inView } = useInView({
    threshold: 0.7,
  })

  useEffect(() => {
    setIsVideoPlaying(inView)
  }, [inView])

  return (
    <div>
      <div className={styles.container} ref={ref}>
        <FullScreenVideo
          video={videoLink}
          id={'collaborate'}
          focused={isVideoPlaying}
          className={styles.hoverPlayerClass}
          PausedOverlay={
            <img
              src="/icons/collaborate.png"
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              alt={''}
            />
          }
          visitProfile={false}
        />
      </div>
    </div>
  )
}

export default Collaborate
