import React, { useState, useEffect, useRef } from 'react'
import BurgerMenu from './components/Header/burgerMenu'
import Header from './components/Header/Header'
import LocationHeader from './components/LocationHeader/LocationHeader'
import Footer from './components/Footer/Footer'
import LocationInterestedBanner from './components/LocationInterestedBanner/LocationInterestedBanner'
import HospitalDescription from './components/HospitalDescription'
import Maps from './components/Maps'
import Physician from './components/physicians'
import { locationData } from './constants/data/locationData'
import _scrollTo from './utils/scrollTo'
import { dataForCoverSlider } from './constants/data/coverSliderData'
import { locationSpeciality } from './constants/data/locationSpeciality'
import { instructors } from './constants/data/instructorData'
import groupLocationsByType from './utils/grouplocations'
import { locationsChips } from './constants/data'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'

function Locations() {
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const [width] = useWindowSize()
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [speciality, setSpeciality] = useState([])
  const [filteredInstructors, setFilteredInstructors] = useState([])
  const [selectedLocationDetails, setSelectedLocationDetails] = useState(null)
  const [locations, setLocations] = useState(Object.keys(locationsChips))

  const locationDetailsRef = useRef()

  const filteredInst = [
    ...new Set(
      flatten(
        [...new Set(locationData.map((item) => item.id))].map((item) =>
          Object.keys(locationSpeciality[item]).map(
            (speciality) => locationSpeciality[item][speciality],
          ),
        ),
      ),
    ),
  ]

  const handleOnSpecialityClick = (item) => {
    const isExist = speciality.find((o) => item === o)
    if (!isExist) {
      setSpeciality([...speciality, item])
      return
    }
    setSpeciality(speciality.filter((o) => o !== item))
  }

  useEffect(() => {
    if (selectedLocation) {
      setSelectedLocationDetails(locationData.find((item) => item.id === selectedLocation))
    }
  }, [selectedLocation])

  useEffect(() => {
    if (speciality.length === 0) {
      setFilteredInstructors(
        flatten(filteredInst?.map((doc) => instructors?.filter((item) => item.id === doc))),
      )
      return
    }
    setFilteredInstructors(
      flatten(
        locations.map((item) =>
          speciality?.map((speciality) =>
            groupLocationsByType(locationData, 'type')[item][speciality].map((location) => location.id)
              .map(
                (locId) =>
                  locationSpeciality[locId][
                    speciality === 'Interventional Spine'
                      ? 'interventionalSpine'
                      : speciality.toLowerCase()
                  ],
              )
              .map((instructor) =>
                dataForCoverSlider[
                  speciality === 'Interventional Spine'
                    ? 'interventionalSpine'
                    : speciality.toLowerCase()
                ]?.filter((inst) => inst.id === instructor[0]),
              ),
          ),
        ),
      ),
    )
  }, [speciality, locations])

  const handleOnLocationClick = (location) => {
    if (selectedLocation === location) {
      setSelectedLocation(null)
      return
    }
    setSelectedLocation(location)
    _scrollTo(locationDetailsRef, -40)
  }

  function containsWhitespace(str) {
    return /\s/.test(str)
  }

  const handleLowerCase = (string) => {
    if (containsWhitespace(string)) {
      const arr = string.split(' ')

      arr[0] = arr[0].toLowerCase()

      return arr.join('')
    }

    return string.toLowerCase()
  }

  function flatten(ary) {
    var ret = []
    for (var i = 0; i < ary.length; i++) {
      if (Array.isArray(ary[i])) {
        ret = ret.concat(flatten(ary[i]))
      } else {
        ret.push(ary[i])
      }
    }
    return ret
  }



  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}

      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      <LocationHeader />
      <Maps
        locations={locations}
        setLocations={setLocations}
        handleOnLocationClick={handleOnLocationClick}
        speciality={speciality}
        handleOnSpecialityClick={handleOnSpecialityClick}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        ref={locationDetailsRef}
      >
        {selectedLocation && (
          <>
            <HospitalDescription {...selectedLocationDetails} />

            {filteredInstructors.length > 0 && <Physician instructors={filteredInstructors} />}
          </>
        )}
      </div>

      <LocationInterestedBanner />
      <Footer />
    </div>
  )
}

export default Locations
