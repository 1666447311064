
const env = process.env.REACT_APP_ENV

let cdn_env = "red"
if (!env) {
  cdn_env = "red" // default to red
} else {
  cdn_env = process.env.REACT_APP_ENV === 'production' ? "com" : env
}

export const cdnUrl = `https://immertec-cdn.immertec.${cdn_env}`
