import React from 'react'
import styles from './MemberDetails.module.scss'

function ErikMaltais() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.memberImageContainer}>
        <img style={{ width: ' 12.8125vw', height: '12.8125vw' }} src="/erik.png" />
      </div>
      <div className={styles.mobileContainer}>
        <div className={styles.imageM}>
          <img style={{ width: ' 100px', height: '100px' }} src="/erik.png" />
        </div>
        <div className={styles.DetailsM}>
          <div className={styles.memberNameM}>Erik Maltais</div>

          <div className={styles.memberRoleM}>CEO</div>
          <div className={styles.socialIconsM}>
            <img src="/icons/facebook.png" />
            <img src="/icons/instagram.png" />
            <img src="/icons/linkedin.png" />
          </div>
        </div>
      </div>
      <div className={styles.memberDetails}>
        <div className={styles.memberName}>Erik Maltais</div>

        <div className={styles.memberText1}>
          If you are reading this you are most likely interested in understanding me better in
          consideration to join our team, become a customer, invest in our company or collaborate in
          some way. Instead of a boring you with details of my past accomplishment written by yours
          truly to highlight my greatness, I will instead share my weaknesses and strengths from my
          predictive index behavioral pattern assessment summary below. Please note these words
          below are not my own. I hope this information provides some insight into how I behave, so
          you may better judge our suitability.
        </div>
        <div className={styles.memberQuote}>
          “A very independent, confident, decisive, self-starter, intense and driving. This
          individual has a strong sense of urgency, can react and adjust quickly to changing
          conditions, generate novel ideas, and deal with them swiftly."
        </div>
        <div className={styles.memberText2}>
          Strongly venturesome in taking risks and focusing on the future; almost exclusively
          concerned with where they’re going rather than either how they’ll get there, or where
          they’ve been. Their drive is directed at getting the important things done. Competitive,
          ambitious and venturesome, they respond positively and actively to challenge and pressure,
          always sure of an ability to handle problems and people. This individual is outgoing and
          poised; a forceful, animated, communicator tending to be more authoritative than
          persuasive in style. They talk briskly, with assurance and conviction and are a
          stimulating influence on others, while being direct, determined and flexible.
          <br /> <br /> Sure of the value of personal judgments and opinions, and persistent in
          defending them if put under pressure to change them, Erik will question and challenge
          established company policies or systems and strive to prove in action the value of their
          ideas for change.
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.memberRole}>CEO</div>
        <div className={styles.socialIcons}>
          <img src="/icons/facebook.png" />
          <img src="/icons/instagram.png" />
          <img src="/icons/linkedin.png" />
        </div>
      </div>
    </div>
  )
}

export default ErikMaltais
