import React, { useState } from 'react'
import styles from './testimonial.module.scss'
import Slider from 'react-slick'

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <img src={'/arrowLeft.png'} className={styles.ArrowFont} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <img src={'/arrowRight.png'} className={styles.ArrowFont} />
    </div>
  )
}

function Testimonial() {
  const [index, setIndex] = useState()

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 10000,

    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    beforeChange: (current, next) => setIndex(next + 1),
  }
  return (
    <div className={styles.container}>
      <div className={styles.subcontainer}>
        <Slider {...settings}>
          <div className={styles.slider}>
            <div className={styles.text}>
              {
                '“The quality was great, I love the fact that you can pick exactly what’s important to you during a case and highlight that.”'
              }
            </div>
            <div className={styles.personDetails}>Clinical Education Manager</div>
          </div>
          <div className={styles.slider}>
            <div className={styles.text}>
              {
                '“I never experienced anything like this and don’t really have anything to compare it to."'
              }
            </div>
            <div className={styles.personDetails}>Podiatrist</div>
          </div>
          <div className={styles.slider}>
            <div className={styles.text}>
              {'"This is a game changer in the realm of surgical education and learning!"'}
            </div>
            <div className={styles.personDetails}>Neurosurgeon</div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default Testimonial
