import React, { useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useMute } from '../../providers/MuteContextProvider'
// import PausedOverlay from './components/pausedOverlay.js'
import HoverOverlay from '../HoverOverlay'
import HoverVideoPlayer from '../HoverVideoPlayer'

function FullScreenVideo({
  video,
  id,
  route,
  PausedOverlay,
  focused,
  className = '',
  visitProfile = true,
}) {
  const { muted, onMuteClick } = useMute()

  const [fullscreenAvailable, setFullscreenAvailable] = useState(true)

  const [fullscreen, setFullscreen] = useState(false)

  const handle = useFullScreenHandle()

  const onFullScreenClick = () => {
    // TODO: Why does this run twice?
    if (fullscreen) {
      setFullscreen(false)
      handle.exit()
      return
    }
    setFullscreen(true)
    handle.enter()
  }

  return (
    <FullScreen handle={handle}>
      <HoverVideoPlayer
        key={id}
        muted={muted}
        className={className}
        videoSrc={video}
        fullscreen={true}
        focused={focused || fullscreen}
        setFullscreenAvailable={setFullscreenAvailable}
        pausedOverlay={PausedOverlay}
        hoverOverlay={
          <HoverOverlay
            onMuteClick={onMuteClick}
            path={route}
            visitProfile={visitProfile}
            muted={muted}
            onFullScreenClick={onFullScreenClick}
            fullScreenSupport={true}
          />
        }
      />
    </FullScreen>
  )
}

export default FullScreenVideo
