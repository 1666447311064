import { specialties } from '../../../utils/specialty'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getLongInstructorVideoLink,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'

const data = [
  `Before becoming a Spine Surgeon at Spine Vue, Dr. Jones Quaidoo was an Orthopaedic Surgery Intern at the University of Virginia and served as an Orthopaedic Surgery Resident and Chief Resident at the University of Virginia. He then moved on to train under orthopedic surgeons and neurosurgeons at the Norton Leatherman Spine Center in Louisville, KY. Dr. Jones-Quaidoo is certified by the American Board of Orthopaedic Surgery. He is the recipient of the California Healthcare Foundation, National Healthcare Scholars Foundation, Novo Nordisk Pharmaceutical, Inc. Diabetes Scholarship, Pfizer Minority Medical Student Scholarship for Biomedical and Clinical Research, awarded the Charles R. Drew Department of Orthopaedic Surgery Book Award, the Dr. Martin Luther King, Jr. Award, and the award winner of the Patient Care Employee Recognition Award Winner University of Virginia Health System. `,
]

const specialty = specialties.spine.name
const id = 'quaidoo'

export const quaidooData = {
  id,
  firstName: 'Sean M.',
  lastName: 'Jones-Quaidoo',
  middleName: undefined,
  suffix: 'MD',
  title: 'Sean M. Jones-Quaidoo, MD',

  // Not using actual web link. to be used when we dynamically pull instructors.
  heroImage: getHeroImageOfInstructor(specialty, id), // Just use the name of the file. Path will be set during render time.
  // Allows to switch between the desktop/mobile images.
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileLink: `/Instructor/${id}`,
  profileCover: getInstructorProfileCover(id),
  events: getInstructorEvents(id),
  shortVideoCard: getInstructorCardPicture(id),
}
