export const mloc199 = {
  id: 'MLOC-199',
  name: 'Ocean Springs Hospital',
  description:
    'Ocean Springs Hospital – a full-service, acute care hospital – offers a wide array of inpatient and outpatient services to meet your healthcare needs:\n- State-of-the-art Diagnostics: Cardiac Catheterization Lab, Non-Invasive Cardiology and Vascular Lab, Gastrointestinal Lab, Heart Failure Clinic, Lab and Radiology Services.\n- Medical & Surgical Inpatient Services Program\n- Level III Trauma Center\n- Comprehensive Cardiology Services: Diagnostics, Congestive Heart Failure Clinic, Cardiovascular Hybrid Operating Room\n- A multi-specialty ambulatory surgery center\n- Hybrid Operating Room\n- Latest technology in robotic Joint, Hip, Knee Replacement\n- Pulmonary/critical care – intensive care units (medical, surgical and neuro)\n- Emergency Department\n- Wound Care Center\n- Women’s and Children’s',
  status: '06-Event Prep (ES)',
  address1: '3109 Bienville Blvd',
  address2: null,
  city: 'Ocean Springs',
  state: 'MS',
  zip: '39564',
  category: 'Commercial',
  type: 'Hospital',
  latitude: '30.41407',
  longitude: '-88.7808',
  photo: 'mloc-199.png',
  speciality: 'Endovascular',
}
