export const mloc769 = {
  id: 'MLOC-769',
  name: 'Emory University Midtown',
  description:
    "Emory University Hospital Midtown is a 511-bed acute care teaching hospital located in the SoNo district of Atlanta, Georgia, United States, and affiliated with Emory Healthcare. The hospital's CEO is Dan Owens.",
  status: '07-Event Ready (ES)',
  address1: '550 Peachtree St NE',
  address2: null,
  city: 'Atlanta',
  state: 'GA',
  zip: '30308',
  category: 'Research Collaboration',
  type: 'Hospital',
  latitude: '33.76889',
  longitude: '-84.38569',
  photo: 'mloc-769.png',
  speciality: 'Spine',
}
