export const mloc1942 = {
  id: 'MLOC-1942',
  name: 'FirstHealth Moore Regional Hospital',
  description:
    'FirstHealth Moore Regional Hospital is a 402-bed, acute care, not-for-profit hospital that serves as the referral center for a 15-county region in the Carolinas. Located in Pinehurst, N.C., it is the flagship hospital for FirstHealth of the Carolinas, the region’s first comprehensive health care network.\nMoore Regional Hospital opened on November 25, 1929, with 33 beds, five bassinets and a staff of 27. Since then, Moore Regional Hospital has grown to include an active medical staff of 287 physicians, a professional staff of more than 2,700 and an average of 900 volunteers and offers a full range of health care services to people within the six-county primary service area and 15-county secondary service area.',
  status: '07-Event Ready (ES)',
  address1: '155 Memorial Drive',
  address2: null,
  city: 'Pinehurst',
  state: 'NC',
  zip: '30092',
  category: 'Pilot',
  type: 'Hospital',
  latitude: '35.20631',
  longitude: '-79.45668',
  photo: 'mloc-1942.png',
  speciality: 'Spine',
}
