import { style } from '@mui/system'
import React from 'react'
import ContainedButton from '../ContainedButton'
import styles from './ImmersiveSurgical.module.scss'
import getUrlForm from '../../utils/getFormUrl'
import ProgressiveImage from '../ProgressiveImage'
import { Link } from 'react-router-dom'

function ImmersiveSurgical() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.middleSec}>
          <div className={styles.mainText}>
            Immersive <br /> PEER-TO-PEER <br />
            COLLABORATION <br />
            in Real-Time
          </div>
          <div className={styles.subText}>
            Collaborate on the latest in medical innovation from anywhere
          </div>
          <div className={styles.btnContainer}>
            <Link to="/request-a-demo">
              <ContainedButton
                customStyles={{
                  height: '3.0940511797939516vw',
                  width: '21.822916666666668vw',
                  backgroundColor: '#47D7AC',
                }}
                textStyles={{
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.0416666666666667vw',
                  fontWeight: '100',
                  alignItems: 'center',
                  textAlign: 'center',
                  color: '#243746',
                }}
                label={'Request a Demo'}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.GradientContainer}>
        <div className={styles.gradient} />
        <div className={styles.rightContainer}>
          <ProgressiveImage
            src={'/ProductPage/header-industry.jpeg'}
            placeholder={'/ProductPage/header-industry-progressive.jpeg'}
            className={styles.image}
            alt={''}
          />
        </div>
      </div>
      <div className={styles.MbtnContainer}>
        <a href={getUrlForm('chat')}>
          <ContainedButton
            customStyles={{
              height: '100%',
              width: '303px',
              backgroundColor: '#47D7AC',
            }}
            textStyles={{
              fontFamily: 'Gilroy-Bold',
              fontSize: '15px',
              fontWeight: '100',
              alignItems: 'center',
              textAlign: 'center',
              // Textheight: '24px' ,
              lineHeight: '17px',
              color: '#243746',
            }}
            label={'Schedule a Demo'}
          />
        </a>
      </div>
    </div>
  )
}

export default ImmersiveSurgical
