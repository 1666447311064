//Note: This are should be used to manage specialty ids.
/**
 * Return the Specialty Label
 * @param  {} instructor_specialties
 */
export function getSpecialty(instructor_specialties) {
  const specialty = getSpecialtyID(instructor_specialties)
  if (specialties[specialty]) {
    return specialties[specialty].label
  }
}
/**
 * Checks teh specialty object and returns the name of the specialty
 * Most places use the label so this function is able to convert that into a name.
 * I hope this si reliable
 * @param  {} instructor_specialties
 */
export const getSpecialtyID = (instructor_specialties) => {
  if (specialties[instructor_specialties]) {
    return specialties[instructor_specialties].name
  }
  //This does not run if above returns.
  Object.keys(specialties).forEach((key) => {
    if (specialties[key].label === instructor_specialties) return specialties[key].name
  })
}
export function checkSpecialtyEquals(specialty1, specialty2) {
  specialty1 = getSpecialtyID(specialty1)
  specialty2 = getSpecialtyID(specialty2)
  return specialty1 === specialty2
}

export const specialties = {
  orthopedics: {
    name: 'orthopedics',
    label: 'Orthopedics',
    strokeIcon: '/specialtyIcons/orthopedics/strokeIcon.png',
    whiteIcon: '/specialtyIcons/orthopedics/whiteIcon.png',
    greenIcon: '/specialtyIcons/orthopedics/greenIcon.png',
    svgIcon: '/specialtyIcons/orthopedics/tabBar.svg',
  },
  interventionalSpine: {
    name: 'interventionalSpine',
    label: 'Interventional Spine',
    strokeIcon: '/specialtyIcons/interventionalSpine/strokeIcon.png',
    whiteIcon: '/specialtyIcons/interventionalSpine/whiteIcon.png',
    greenIcon: '/specialtyIcons/interventionalSpine/greenIcon.png',
    svgIcon: '/specialtyIcons/interventionalSpine/tabBar.svg',
  },
  spine: {
    name: 'spine',
    label: 'Spine',
    strokeIcon: '/specialtyIcons/spine/strokeIcon.png',
    whiteIcon: '/specialtyIcons/spine/whiteIcon.png',
    greenIcon: '/specialtyIcons/spine/greenIcon.png',
    svgIcon: '/specialtyIcons/spine/tabBar.svg',
  },
  otolaryngology: {
    name: 'otolaryngology',
    label: 'Otolaryngology',
    strokeIcon: '/specialtyIcons/otolaryngology/strokeIcon.png',
    whiteIcon: '/specialtyIcons/otolaryngology/whiteIcon.png',
    greenIcon: '/specialtyIcons/otolaryngology/greenIcon.png',
    svgIcon: '/specialtyIcons/otolaryngology/tabBar.svg',
  },
  endovascular: {
    name: 'endovascular',
    label: 'Endovascular',
    strokeIcon: '/specialtyIcons/endovascular/strokeIcon.png',
    whiteIcon: '/specialtyIcons/endovascular/whiteIcon.png',
    greenIcon: '/specialtyIcons/endovascular/greenIcon.png',
    svgIcon: '/specialtyIcons/endovascular/tabBar.svg',
  },
  urology: {
    name: 'urology',
    label: 'Urology',
    strokeIcon: '/specialtyIcons/urology/strokeIcon.png',
    whiteIcon: '/specialtyIcons/urology/whiteIcon.png',
    greenIcon: '/specialtyIcons/urology/greenIcon.png',
    svgIcon: '/specialtyIcons/urology/tabBar.svg',
  },
}
