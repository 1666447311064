import React from 'react'
import ContainedButton from '../ContainedButton'
import styles from './JoinUs.module.scss'
// import Slider from 'react-slick'
import JoinUsSlider from './JoinUsSlider'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ProgressiveImage from '../ProgressiveImage'
let data = [
  {
    id: 1,
    img: '/icons/JoinUs.png',
    progressiveImage: '/CareerPage/Progressive/AwesomeTeamSlider1.png',
  },
  {
    id: 2,
    img: '/icons/JoinUs2.png',
    progressiveImage: '/CareerPage/Progressive/AwesomeTeamSlider2.png',
  },
  {
    id: 3,
    img: '/icons/JoinUs3.png',
    progressiveImage: '/CareerPage/Progressive/AwesomeTeamSlider3.png',
  },
]

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon style={{ fill: '#000000', fontSize: '2.2625vw' }} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon style={{ fill: '#000000', fontSize: '2.2625vw' }} />
    </div>
  )
}
function JoinUs({ onClick: sendToComponent }) {
  const settings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.mainText}>Join us and help inspire PEER-TO-PEER COLLABORATION</div>
          <div className={styles.btnContainer}>
            <ContainedButton
              customStyles={{
                height: '2.9166666666666665vw',
                width: '21.822916666666668vw',
                backgroundColor: '#47D7AC',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Bold',
                fontSize: '1.0416666666666667vw',
                lineHeight: '1.25vw',
                fontWeight: 'lighter',
                alignItems: 'center',
                textAlign: 'center',
                color: '#243746',
              }}
              label={'Explore Roles'}
              handleClick={sendToComponent}
            />
          </div>
        </div>
      </div>

      <div className={styles.rightContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={styles.overlay}></div>
          <ProgressiveImage
            src={'/CareerPage/header-careers-desktop.jpeg'}
            alt={''}
            placeholder={'/CareerPage/Progressive/header-careers-progressive.jpeg'}
          />
        </div>
      </div>
      <div className={styles.MbtnContainer}>
        <ContainedButton
          customStyles={{
            height: '41px',
            width: '303px',
            backgroundColor: '#47D7AC',
          }}
          textStyles={{
            fontFamily: 'Gilroy-Bold',
            fontSize: '14.46px',
            lineHeight: '17px',
            fontWeight: 'lighter',
            alignItems: 'center',
            textAlign: 'center',
            color: '#243746',
          }}
          label={'Explore Roles'}
        />
      </div>
    </div>
  )
}

export default JoinUs
