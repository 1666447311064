import React from 'react'
import styles from './OutlineButton.module.scss'
import { ButtonBase } from '@mui/material'

function OutlineButton(props) {
  const { customStyles, textStyles, label, handleClick } = props
  return (
    <ButtonBase
      variant={'outlined'}
      className={styles.container}
      style={{ ...customStyles, ...textStyles, borderRadius: '8px' }}
      onClick={handleClick}
    >
      {label}
    </ButtonBase>
  )
}

export default OutlineButton
