import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorCardPicture,
  getInstructorEvents,
} from '../../../utils/getAssetLink'
const data = [
  `Dr. Anand Murthi is the Chief, Shoulder and Elbow Surgery, at MedStar Union Memorial Hospital, where he also serves as the Director of the Shoulder and Elbow Fellowship and Research. He is a board-certified orthopaedic surgeon, fellowship trained in shoulder and elbow surgery, who sees patients in Timonium (Baltimore County) and in Baltimore at MedStar Union Memorial.`,

  `Dr. Murthi received his medical degree from Case Western Reserve University School of Medicine and completed his general surgery internship and orthopaedic surgery residency at George Washington University Medical Center. He completed the internationally recognized fellowship in shoulder and elbow reconstruction at the New York Orthopaedic Hospital-Columbia Presbyterian Medical Center. He is the current section editor for the journal “Current Orthopaedic Practice.” As a teacher he has been involved in teaching physicians around the country surgical techniques in shoulder and elbow surgery. He is former president and founding member of the Association of Clinical Elbow and Shoulder Surgeons (ACESS) group. He is also an elected member of the prestigious American Shoulder and Elbow Surgeons Society (ASES), an international group of shoulder and elbow surgeons dedicated to the mission of teaching and research for which he has chaired the Education Committee. Recently he was elected to the Neer Circle of ASES. He currently is a co-chairman of the Mid-Atlantic Shoulder and Elbow Society which holds an annual regional scientific meeting.`,

  `Dr. Murthi has been involved in the development of numerous implants for clinical care. These include total shoulder and reverse shoulder replacement, pyrocarbon resurfacing humerus, proximal humerus fracture plate, clavicle plating system and others. Currently he is involved in designing for robotic assisted shoulder replacement. He and his colleague have also designed the first international shoulder data registry (Shoulder JAM by OBERD).`,

  `He is currently the fellowship director for the MedStar Shoulder and Elbow Fellowship program which trains future shoulder specialists. He also directs the Shoulder and Elbow Research programs which is a highly productive clinical, biomechanical, and translational program with numerous publications.`,
]
const specialty = specialties.orthopedics.name
const id = 'murthi'
export const murthiData = {
  firstName: 'Anand',
  lastName: 'Murthi',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'ANAND MURTHI, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  shortVideoCard: getInstructorCardPicture(id),

  events: getInstructorEvents(id),
}
