import React from 'react'
import styles from './WhyTrain.module.scss'

function WhyTrain() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.textContainer}>
        <div className={styles.mainText}>Why Train with Immertec?</div>
        <div className={styles.subText}>
          We can scale your professional education program by helping you engage your customers and
          KOLs.
          <br />
          <br />
          Medical professionals learn from a live, immersive clinical setting regardless of their
          physical location.
        </div>
      </div>

      <div className={styles.centerSec}>
        <div className={styles.imageContainer}>
          <div className={styles.images}>
            <img src="/whyTrain/3.png" />
          </div>
          <div className={styles.imageText}>IMMERSIVE</div>
        </div>

        <div className={styles.imageContainer}>
          <div className={styles.images}>
            <img src="/whyTrain/2.png" />
          </div>
          <div className={styles.imageText}>DISTRACTION FREE</div>
        </div>

        <div className={styles.imageContainer}>
          <div className={styles.images}>
            <img src="/whyTrain/1.png" />
          </div>
          <div className={styles.imageText}>BEST SEAT IN THE OR</div>
        </div>

        <div className={styles.imageContainer}>
          <div className={styles.images}>
            <img src="/whyTrain/4.png" />
          </div>
          <div className={styles.imageText}>NO PATIENT DATA STORED</div>
        </div>

        <div className={styles.imageContainer}>
          <div className={styles.images}>
            <img src="/whyTrain/5.png" />
          </div>
          <div className={styles.imageText}>NO TRAVEL REQUIRED</div>
        </div>
      </div>
    </div>
  )
}

export default WhyTrain
