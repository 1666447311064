import { useRef, useEffect, useState } from 'react'
import anime from 'animejs/lib/anime.es.js'

export const useCounter = (endVal, inView = false) => {
  const value = useRef(0)
  const [current, setCurrent] = useState(value.current)
  useEffect(() => {
    anime({
      autoplay: inView,
      targets: value,
      current: [
        endVal > 20
          ? {
              value: [0, endVal > 20 ? endVal - 20 : endVal],
              easing: 'easeInCubic',
              duration: 1000,
            }
          : {
              duration: 2000,
              value: endVal,
              easing: 'easeInOutCubic',
            },
        endVal > 20
          ? {
              value: [endVal - 20, endVal],
              duration: 1000,
              easing: 'easeOutCubic',
            }
          : {},
      ],
      round: 1,
      easing: 'easeOutCirc',
      update: () => setCurrent(value.current),
    })
  }, [endVal, inView])
  return current
}
