import React from 'react'
import Slider from 'react-slick'

import styles from './ResearchCardSlider.module.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ResearchCard from '../ResearchCard/ResearchCard'

let cardData = [
  {
    id: 1,
    image: '/ScienceDoctors/1.png',
    imageText: 'Marc Dean, MD ENT',
    mainText: 'Quote and Headshot',
    subText:
      ' “I believe all patients deserve access to state-of-the-art Healthcare. As a result, I’ve been active in telemedicine for over a decade and continually try to push the envelope of what can be done remotely. Immertec’s fully immersive, 3-D platform restores personal connection that is missing from all other telehealth solutions, and I’m confident it will not only change the nature of telehealth but also revolutionize healthcare as a whole.” ',
  },
  {
    id: 2,
    image: '/ScienceDoctors/2.png',
    imageText: 'Emilie Cheung, MD Orthopedics',
    mainText: 'Quote and Headshot',
    subText:
      ' “We are at an exciting turning point in virtual healthcare. Immertec has state-of-the-art technology that is the future of surgical training. Once the VR headset is activated, the surgeon feels like they are right there in the live OR, immersed, and learning cutting-edge techniques by the world’s leading experts. Prepare to be impressed.” ',
  },

  {
    id: 3,
    image: '/ScienceDoctors/3.png',
    imageText: 'Chris O’Grady, MD Orthopedics',
    mainText: 'Quote and Headshot',
    subText:
      ' “We are equally as excited about our partnership with Immertec and the impact their training platform has on improving patient outcomes.” ',
  },

  {
    id: 4,
    image: '/ScienceDoctors/4.png',
    imageText: 'Hooman Melamed, MD Spine',
    mainText: 'Quote and Headshot',
    subText:
      ' “Immertec’s virtual surgery training platform can greatly reduce the inefficiencies we see with current surgical training methods. It enables greater collaboration among the surgical community and breaks through the physical barriers that inhibit the adoption of higher value medical devices and procedures. I’m confident it’ll have a powerful impact on surgical training, and healthcare as a whole.”',
  },

  {
    id: 5,
    image: '/ScienceDoctors/5.png',
    imageText: 'Haru Okuda, MD Emergency Medicine',
    mainText: 'Quote and Headshot',
    subText:
      ' “This technology is a game-changer for the future of medical and surgical education. Real-time virtual reality can help augment or even replace traditional training modalities for physicians and health care providers.” ',
  },

  {
    id: 6,
    image: '/ScienceDoctors/6.png',
    imageText: 'Jahangir Asghar, MD Spine',
    mainText: 'Quote and Headshot',
    subText:
      ' “I am really excited to be working with the Immertec team to help create, and develop improved immersive live education experiences in the OR. Virtual learning and experiences will be an integral part of surgical education and development in the post COVID-19 environment.”',
  },
]

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon className={styles.ArrowFont} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon className={styles.ArrowFont} />
    </div>
  )
}

function ResearchCardSlider() {
  let settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
        },
      },
    ],
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#F6F7FB',
          paddingTop: '2.6041666666666665vw',
        }}
      >
        <div className={styles.mainText}>What Our Partners Are Saying</div>

        <div className={styles.sliderWidth}>
          <Slider {...settings}>
            {cardData.map((card, index) => (
              <ResearchCard card={card} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default ResearchCardSlider
