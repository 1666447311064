import React from 'react'

function LoadingScreen() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        height: '100vh',
      }}
    >
      <img src="/icons/Prod.svg" alt="" />
    </div>
  )
}

export default LoadingScreen
