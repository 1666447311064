export const coreValuesTabPanel = [
  {
    imgUrl: '/icons/Integrity.png',
    text: 'Integrity',
    description:
      'Acting with integrity with team members, clients, vendors and visitors, fosters trust and sets the foundation for us to maintain strong relationships. To us, Integrity means being accountable, being honest and leading with transparency.',
    value: 0,
  },
  {
    imgUrl: '/icons/Commitment.png',
    text: 'Commitment',
    description:
      'Commitment fuels our company to pave the way for excellence. Being committed helps us evaluate, innovate and execute our vision. We empower our people to understand that extraordinary results require extraordinary commitment. To us, commitment is accomplished by being passionate and pursing excellence on a consistent basis.',
    value: 1,
  },
  {
    imgUrl: '/icons/Innovation.png',
    text: 'Innovation',
    description:
      'Innovation in products and processes helps us to improve our business and continually raise our standards. To us, being innovative is when we are focused on the end-user, in a state of constant growth and always ambitious to be the best.',
    value: 2,
  },
  {
    imgUrl: '/icons/Community.png',
    text: 'Community',
    description:
      'Being a part of something bigger than ourselves allows us to do more with and for others. We want everyone involved with our organization to feel like they are a part of something larger than themselves, which gives deeper meaning to our work and our lives. To us, community means being impactful, respectful and inclusive to all we work with.',
    value: 3,
  },

  {
    imgUrl: '/Curiosity.png',
    text: 'Curiosity',
    description:
      "Focusing on curiosity allows us to keep a profound sense of understanding ourselves and others; allowing us to focus on the things we can do better, work to understand others and their unique situations, and always striving to learn and embrace things we don't yet understand. To us, curiosity means being humble, seeking feedback and developing empathy to understand others.",
    value: 4,
  },
]
