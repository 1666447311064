import React, { useState, useRef, useCallback } from 'react'
import MapGL, { NavigationControl } from 'react-map-gl'
import styles from './maps.module.scss'
import LocationChip from '../LocationChip'
import { locationsChips, locationChipSelectionStyles } from '../../constants/data'
import { useHomeCourselContext } from '../../providers/HomeCourselProvider'
import { specialties } from '../../utils/specialty'
import { Markers } from './controls/markers/Markers'
import ToggleMap from './controls/togglemap/ToggleMap'
import { locationData } from '../../constants/data/locationData'
import groupLocationsByType from '../../utils/grouplocations'
import Popover from './controls/popovers/Popover'
import { Swiper, SwiperSlide } from 'swiper/react'

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiZ2Vvcmdpb3MtdWJlciIsImEiOiJjanZidTZzczAwajMxNGVwOGZrd2E5NG90In0.gdsRu_UeU_uPi9IulBruXA'

const initialViewport = {
  latitude: 38.91,
  longitude: -77.0305,
  zoom: 3,
}

function Maps({locations,setLocations, handleOnLocationClick, speciality, handleOnSpecialityClick }) {
  const [viewport, setViewport] = useState(initialViewport)
  const [markerHovered, setMarkerHovered] = useState()
  const [mapStyle, setMapStyle] = useState('light-v10')
  const mapRef = useRef()
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), [])
  const { categories } = useHomeCourselContext()

  const handleOnChange = (collection, setCollection, item) => {
    const isExist = collection.find((o) => item === o)
    if (!isExist) {
      setCollection([...collection, item])
      return
    }
    setCollection(collection.filter((o) => o !== item))
  }

  const handleOnMarkerHovered = (id) => {
    setMarkerHovered(id)
  }
  const handleOnMarkerUnHovered = () => {
    setMarkerHovered(null)
  }

  return (
    <div>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          Instructors are passionate knowledge opinion leaders with years of training experience.
        </div>
      </div>
      <div className={styles.locationSection}>
        <div className={styles.textContainer}>
          <div className={styles.text}>Select the locations </div>
        </div>
        <div className={styles.chipOuterContainer}>
          <div className={styles.chipContainer}>
            {Object.keys(locationsChips).map((item) => (
              <LocationChip
                label={locationsChips[item].title}
                value={item}
                customStyles={{
                  ...(locations.includes(locationsChips[item].title)
                    ? locationsChips[item].styles
                    : locationChipSelectionStyles['inactive'].styles),
                }}
                onChange={(location) => handleOnChange(locations, setLocations, location)}
              />
            ))}
          </div>
          <div className={styles.divider} />
          <div className={styles.chipContainer}>
            {categories.map((item) => (
              <LocationChip
                label={
                  specialties[item].label === 'Interventional Spine'
                    ? 'Int Spine'
                    : specialties[item].label
                }
                value={specialties[item].label}
                onChange={(spec) => handleOnSpecialityClick(spec)}
                customStyles={{
                  ...locationChipSelectionStyles[
                    speciality.includes(specialties[item].label) ? 'active' : 'inactive'
                  ].styles,
                }}
              />
            ))}
          </div>
        </div>
        <div className={styles.locationMobileTabContainer}>
          <div id="locationMTabs">
            <Swiper
              slidesPerView={'auto'}
              centeredSlides={true}
              loop={false}
              slideToClickedSlide={true}
              spaceBetween={10}
              freeMode={true}
              initialSlide={1}
            >
              {Object.keys(locationsChips).map((item) => (
                <SwiperSlide>
                  <LocationChip
                    label={locationsChips[item].title}
                    value={item}
                    customStyles={{
                      ...(locations.includes(locationsChips[item].title)
                        ? locationsChips[item].styles
                        : locationChipSelectionStyles['inactive'].styles),
                    }}
                    onChange={(location) => handleOnChange(locations, setLocations, location)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.MdividerContainer}>
            <div className={styles.Mdivider}></div>
          </div>

          <div id="locationMTabs">
            <Swiper
              slidesPerView={'auto'}
              centeredSlides={true}
              loop={false}
              slideToClickedSlide={true}
              spaceBetween={10}
              freeMode={true}
              initialSlide={1}
            >
              {categories.map((item) => (
                <SwiperSlide>
                  <LocationChip
                    label={
                      specialties[item].label === 'Interventional Spine'
                        ? 'Int Spine'
                        : specialties[item].label
                    }
                    value={specialties[item].label}
                    onChange={(spec) => handleOnSpecialityClick(spec)}
                    customStyles={{
                      ...locationChipSelectionStyles[
                        speciality.includes(specialties[item].label) ? 'active' : 'inactive'
                      ].styles,
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div style={{ height: '54.013875123885036vh', width: '100%' }}>
        <MapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapStyle={'mapbox://styles/mapbox/' + mapStyle}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 30,
            }}
          >
            <NavigationControl />
          </div>
          <div
            style={{
              position: 'absolute',
              top: 10,
              left: 30,
            }}
          >
            <ToggleMap mapStyle={mapStyle} onChange={setMapStyle} />
          </div>

          {locations.map((selectedLocation) => {
            return speciality.length > 0
              ? speciality?.map((item) =>
                  groupLocationsByType(locationData, 'type')[selectedLocation][item].map(
                    (item2) => (
                      <Markers
                        {...item2}
                        handleOnLocationClick={handleOnLocationClick}
                        handleOnMarkerHovered={handleOnMarkerHovered}
                        handleOnMarkerUnHovered={handleOnMarkerUnHovered}
                      />
                    ),
                  ),
                )
              : Object.keys(groupLocationsByType(locationData, 'type')[selectedLocation])?.map(
                  (item) =>
                    groupLocationsByType(locationData, 'type')[selectedLocation][item].map(
                      (item2) => (
                        <Markers
                          {...item2}
                          handleOnLocationClick={handleOnLocationClick}
                          handleOnMarkerHovered={handleOnMarkerHovered}
                          handleOnMarkerUnHovered={handleOnMarkerUnHovered}
                        />
                      ),
                    ),
                )
          })}

          {locations.map((selectedLocation) => {
            return speciality.length > 0
              ? speciality?.map((item) =>
                  groupLocationsByType(locationData, 'type')[selectedLocation][item].map(
                    (item2) => <Popover markerHovered={markerHovered} {...item2} />,
                  ),
                )
              : Object.keys(groupLocationsByType(locationData, 'type')[selectedLocation])?.map(
                  (item) =>
                    groupLocationsByType(locationData, 'type')[selectedLocation][item].map(
                      (item2) => <Popover markerHovered={markerHovered} {...item2} />,
                    ),
                )
          })}
        </MapGL>
      </div>
      <div className={styles.dropShodow} />
    </div>
  )
}

export default Maps
