import React from 'react'
import styles from './LeadershipTeam.module.scss'
import RoundedImage from '../RoundedImage/RoundedImage'

function LeadershipTeam({ leader, customStyles, custom = false, onClick, pointer = false }) {
  const { leaderNameCss, leaderRoleCss } = customStyles
  return (
    <div className={styles.cardContainer} onClick={onClick ? onClick : () => {}}>
      {!custom ? (
        <div className={`${pointer && styles.pointer} ${styles.leaderImage}`}>
          <RoundedImage
            style={{ width: leader.width, height: leader.height }}
            roundImage={leader.img}
          />
        </div>
      ) : (
        <div className={styles.customImg}>
          <img style={{ width: leader.width, height: leader.height }} src={leader.img} />
        </div>
      )}
      <div className={styles.fname} style={leaderNameCss}>
        {leader.fname}
      </div>
      <div className={styles.leaderRole} style={leaderRoleCss}>
        {leader.role}
      </div>
    </div>
  )
}

export default LeadershipTeam
