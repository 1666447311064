import React, { useEffect, useState } from 'react'

function ProgressiveImage({ src, placeholder, alt, className }) {
  const [currentSrc, setCurrentSrc] = useState(placeholder)

  useEffect(() => {
    const imageToLoad = new Image()
    imageToLoad.src = src
    imageToLoad.onload = () => {
      setCurrentSrc(src)
    }
  }, [])

  return (
    <img
      src={currentSrc}
      className={className ?? 'imageSize'}
      style={{
        transition: 'opacity .25s linear',
      }}
      alt={alt}
    />
  )
}

export default ProgressiveImage
