import styles from './customTabs.module.scss'
import React, { useState } from 'react'
import { Tabs, Tab } from '@mui/material'
import { styled } from '@mui/material/styles'
import TabPanel from '../../CustomTabPanel'

const AntTabs = styled(Tabs)({
  background: '#ffffff',
  width: 'fit-content',
  'margin-bottom': '9.022556390977444vh',
  'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
  'border-radius': '20px',
  display: 'flex',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: ['-apple-system', 'Gilroy-Medium'].join(','),
  cursor: 'pointer',
  'font-family': 'Gilroy-Bold',
  'font-style': 'normal',
  'font-size': '18px',
  'line-height': '21px',
  'text-transform': 'uppercase',
  color: '#000000',
  border: 'none',
  background: '#ffffff',
  width: '278px',
  height: '94px',
  'text-align': 'center !important',

  '&.Mui-selected': {
    background: '#47d7ac',
    borderRadius: '20px',
    color: '#000',
    fontWeight: 'bold',
  },
  '&.Mui-focusVisible': {},

  '&.MuiTab-root:last-child': {
    borderRight: 'none',
  },
}))

function TabBar({ tabs, tabPanels, flip = false }) {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        {!flip && (
          <AntTabs value={value} onChange={handleChange} aria-label="secondary tabs example">
            {tabs?.map((item, index) => (
              <AntTab
                key={`${item.label}_${index}`}
                value={item.value}
                wrapped
                label={item.label}
              />
            ))}
          </AntTabs>
        )}

        {tabPanels?.map((item, index) =>
          Array.isArray(item) ? (
            <div className={styles.tabPanelContainer}>
              {item.map((item1, index1) => (
                <TabPanel key={`${item1.label}_${index1}`} value={value} index={index}>
                  {item1.child}
                </TabPanel>
              ))}
            </div>
          ) : (
            <TabPanel key={index} value={value} index={index}>
              {item.child}
            </TabPanel>
          ),
        )}

        {flip && (
          <AntTabs value={value} onChange={handleChange} aria-label="secondary tabs example">
            {tabs?.map((item, index) => (
              <AntTab key={`${item.label}_${index}`} value={item.value} label={item.label} />
            ))}
          </AntTabs>
        )}
      </div>
    </div>
  )
}

export default TabBar
