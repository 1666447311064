import React, { useState, useEffect } from 'react'
import styles from './solutionnavbar.module.scss'
import useWindowSize from '../../hooks/useWindowResize'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

const navMenu = [
  {
    title: 'VR Viewer',
    link: '/vr-viewer',
    icon: '/vrIcon.png',
  },
  {
    title: 'Immersive 2D Viewer',
    link: '/immersive-2d-viewer',
    icon: '/immersive-tv.png',
  },
  {
    title: 'Recordings',
    link: '/recording-library',
    icon: '/recording.png',
  },
  {
    title: 'Data Anaytics',
    link: '/data-analytics',
    icon: '/data-analytics.png',
  },
]

function SolutionNavbar() {
  const [width] = useWindowSize()
  const { pathname } = useLocation()
  const [openDropdown, setOpenDropdown] = useState()
  const [selectedOptions, setSelectedOption] = useState(
    navMenu.filter((item) => item.link === pathname),
  )
  const handleClickOnDropdown = () => {
    setOpenDropdown(!openDropdown)
  }

  // const handleClickOnSelectedOption = (option) => {
  //   let val = navMenu.map((item) => item.title === option)
  //   setSelectedOption(val)
  // }

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        {width < 900 ? (
          <div className={styles.dropdownTopContainer}>
            <div className={styles.dropdownContainer}>
              <div className={styles.selectedOption}>
                <img src={selectedOptions[0]?.icon} alt={''} />
                {selectedOptions[0]?.title}
              </div>
              <div className={styles.dropdown} onClick={handleClickOnDropdown}>
                <img src={'/dropdownArrow.png'} alt={''} />
              </div>
            </div>
            <div
              className={`${styles.optionsContainer} ${
                openDropdown ? styles.display : styles.hidden
              }`}
            >
              {navMenu.map((item) => (
                <Link to={item.link} key={`${item.title}`}>
                  <div className={styles.selectedOptionInactive} key={item.title}>
                    <img src={`${item.icon}`} />
                    {item.title}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.navigationBar}>
            {navMenu.map((item) => (
              <Link to={item.link} key={`${item.title}`}>
                <div
                  className={`${styles.itemContainer} ${pathname === item.link && styles.active}`}
                >
                  <img src={`${item.icon}`} />
                  <div className={styles.iconText}>{item.title}</div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SolutionNavbar
