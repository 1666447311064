import React from 'react'
import styles from './MeetImmertecTeam.module.scss'
import ContainedButton from '../ContainedButton'
import ProgressiveImage from '../ProgressiveImage'

function MeetImmertecTeam() {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.textContainer}>
          <div className={styles.mainText}>MEET THE IMMERTEC TEAM</div>
          <div className={styles.subText}>
            The talent behind the world's only technology that streams in real-time 3D
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <ProgressiveImage
          src={'/TeamPage/team-desktop.jpeg'}
          placeholder={'/TeamPage/progressiveImage/team-progressive.jpeg'}
          alt={''}
        />
        <div className={styles.gradient}></div>
      </div>
      <div className={styles.btnContainer}>
        <ContainedButton
          customStyles={{
            height: '40.5px',
            width: '303px',
            backgroundColor: '#47D7AC',
          }}
          textStyles={{
            fontFamily: 'Gilroy-Bold',
            fontSize: '14.46px',
            lineHeight: '1.25vw',
            fontWeight: 'lighter',
            alignItems: 'center',
            textAlign: 'center',
            color: '#243746',
          }}
          label={'Explore Roles'}
        />
      </div>
    </div>
  )
}

export default MeetImmertecTeam
