import React from 'react'
import styles from './immersiveTraining.module.scss'

export default function ImmersiveTraining({img}) {
  return (
    <div className={styles.container}>
      <div className={styles.bgImage}>
        <img src={img} />
      </div>
      <div className={styles.bgGradient} />
      <div className={styles.textContainer}>
        Immersive <br /> Training & Awareness <br /> Platform
      </div>
    </div>
  )
}
