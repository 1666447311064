import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
// ==============================|| NAVIGATION SCROLL TO TOP ||============================== //

const NavigationScroll = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return (
    <>
      <Outlet />
    </>
  )
}

export default NavigationScroll
