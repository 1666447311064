import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from 'swiper'
import './sliderComponent.css'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/effect-coverflow/effect-coverflow.scss'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { IconButton } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import ProgressiveImage from '../../ProgressiveImage'

const useStyles = makeStyles((theme) => ({
  customHoverFocus: {
    '&.MuiButtonBase-root': {
      '@media (max-width:900px)': {
        height: '30px',
        width: '30px',
      },
      backgroundColor: '#fff !important',
      color: '#000',
      height: '2.6041666666666665vw',
      width: '2.6041666666666665vw',
    },
    '&:hover, &.Mui-focusVisible': { backgroundColor: 'yellow' },
  },
}))

SwiperCore.use([Navigation, Pagination, EffectCoverflow])

export default function SwiperCoverflow(props) {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const { sliderRef, handleSetActiveSlider, doctors } = props
  const classes = useStyles()

  return (
    <div id={'homeCoursel'}>
      <Swiper
        effect="coverflow"
        ref={sliderRef}
        loop={true}
        onSlideChange={(data) => {
          // console.log(data)
          console.log(data.realIndex)
          handleSetActiveSlider(data.realIndex)
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 50,
          modifier: 1,
          slideShadows: false,
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
        }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            // spaceBetween: 40,
          },

          900: {
            slidesPerView: 4,
            spaceBetween: 60,
          },
          1100: {
            slidesPerView: 4,
            spaceBetween: 60,
          },
        }}
        initialSlide={4}
        preventClicks={true}
        centeredSlides={true}
      >
        {doctors.map((item, index2) => (
          <SwiperSlide key={`slide_${index2}`}>
            <div key={`slide_${index2}`} className={'homeSliderImageContainer'}>
              <ProgressiveImage src={item?.heroImage} placeholder={item?.progressiveImage} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-prev-btn" ref={navigationPrevRef}>
        <IconButton className={classes.customHoverFocus} aria-label="Delete">
          <ArrowBackIosNewRoundedIcon className="swiper-arrow" />
        </IconButton>
      </div>
      <div className="swiper-button-next-btn" ref={navigationNextRef}>
        <IconButton className={classes.customHoverFocus} aria-label="Delete">
          <ArrowForwardIosRoundedIcon className="swiper-arrow" />
        </IconButton>
      </div>
    </div>
  )
}
