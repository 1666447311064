export const mloc5442 = {
  id: 'MLOC-5442',
  name: 'Ear & Sinus Institute - Ft. Worth',
  description:
    'At Ear and Sinus Institute, the patient comes first. The office conveniently located in the heart of Fort Worth that also serves the surrounding areas of Arlington and Dallas. Physician Marc Dean, MD consistently strives to adopt and study the most up to date technology in order to provide the best possible experience for his patients. The most recent study was on the safety and efficacy of the Balloon Sinuplasty™ system, which produced remarkable results.\n',
  status: '07-Event Ready (ES)',
  address1: '901 Hemphill Street',
  address2: null,
  city: 'Fort Worth',
  state: 'Texas',
  zip: '76104',
  category: 'Commercial',
  type: 'Practice',
  latitude: '32.73538',
  longitude: '-97.33133',
  photo: 'mloc-5442.png',
  speciality: 'Orthopedics',
}
