import React from 'react'
import styles from './index.module.scss'

function LocationChip({ label, value, customStyles, onChange }) {
  return (
    <div onClick={() => onChange(value)} style={customStyles} className={styles.container}>
      <p>{label}</p>
    </div>
  )
}

export default LocationChip
