import React from 'react'
import styles from './requestDemoSucess.module.scss'

function RequestDemoSuccess() {
  return (
    <div className={styles.container}>
      <div className={styles.subcontainer}>
        <h3>Thank you!</h3>
        <p>We will reach out to you shortly to schedule a demo of our platform.</p>
        <div className={styles.imageContainer}>
          <img className={styles.image1} src="/sdi.png" alt="" />
          <img className={styles.image2} src="/cte.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default RequestDemoSuccess
