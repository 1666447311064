import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorCardPicture,
  getInstructorEvents,
} from '../../../utils/getAssetLink'
const data = [
  `Christopher O'Grady, M.D is a board-certified orthopedic surgeon with a Certificate of Added Qualifications in Sports Medicine. He treats musculoskeletal conditions resulting from injury, overuse and arthritis, and has a special interest in arthroscopic shoulder surgery, shoulder reconstruction, shoulder replacement surgery, and athletes of all ages. Dr. O’Grady enjoys coaching his own three children in several different sports, which has afforded him years of experience treating sports-related injuries, from Little League to the professional ranks.`,
]
const specialty = specialties.orthopedics.name
const id = 'grady'
export const gradyData = {
  firstName: 'Chris',
  lastName: "O'Grady",
  middleName: undefined,
  suffix: 'MD',
  id,
  title: "CHRIS O'GRADY, MD",
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
  shortVideoCard: getInstructorCardPicture(id),
}
