import React from 'react'
import styles from './doctorSliderCard.module.scss'
import { IconButton } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  customHoverFocus: {
    '&.MuiButtonBase-root': {
      '@media (max-width:900px)': {
        height: '35px',
        width: '35px',
      },
      backgroundColor: '#85E4C8 !important',
      color: '#000',
      height: '25px',
      width: '25px',
    },
    '&:hover, &.Mui-focusVisible': { backgroundColor: 'yellow' },
  },
}))
function SliderCard({ item }) {
  const classes = useStyles()
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={item.img} className={styles.image} alt="" />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.text}>{item.text}</div>
        <div className={styles.divider} />
        <div className={styles.bottomDiv}>
          <div className={styles.highlightedText}>{item.name}</div>
          <div className={styles.socialContainer}>
            <IconButton className={classes.customHoverFocus} aria-label="Delete">
              <img className={styles.iconImage} src="/icons/linkedin.png" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderCard
