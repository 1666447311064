import React from 'react'
import styles from './requestDemo.module.scss'
import { getFormRedirectUrl } from '../../utils/getFormUrl'

function RequestDemoHero() {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.textContainer}>
          <h2>Request a demo</h2>
          <p>
            Please complete the form below to schedule a hands-on demonstration of our platform.
          </p>
        </div>
        <div className={styles.topContainer}>
          <div className={styles.leftContainer}>
            <img src="/rd.png" alt="request a demo" />
            <div className={styles.subTextContainer}>
              Discover firsthand the significant value Immertec's Immersive Training and Awareness
              platform can bring to your business.
            </div>
          </div>
          <div className={styles.rightContainer}>
            <form
              action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
              method="POST"
            >
              <input type="hidden" name="oid" value="00DDn000004M28T" />
              <input type="hidden" name="retURL" value={getFormRedirectUrl()} />
              <label>
                First Name <span className="required-asterisk">*</span>
              </label>
              <br />

              <input
                id="first_name"
                maxLength="40"
                name="first_name"
                size="20"
                type="text"
                required
                // placeholder="Jordan Gorrochotegui"
              />
              <br />
              <label>
                Last Name <span className="required-asterisk">*</span>
              </label>
              <br />

              <input
                id="last_name"
                maxLength="80"
                name="last_name"
                size="20"
                type="text"
                required
                // placeholder="Gorrochotegui"
              />
              <br />
              <label>
                Title <span className="required-asterisk">*</span>
              </label>
              <br />

              <input
                id="title"
                maxLength="40"
                name="title"
                size="20"
                type="text"
                required
                // placeholder="Clinical Education Manager"
              />
              <br />

              <label>
                Company <span className="required-asterisk">*</span>
              </label>
              <br />

              <input
                id="company"
                maxLength="40"
                name="company"
                size="20"
                type="text"
                required
                // placeholder="Med USA"
              />
              <br />
              <label>
                Email <span className="required-asterisk">*</span>
              </label>
              <br />

              <input
                id="email"
                maxLength="80"
                name="email"
                size="20"
                type="text"
                required
                // placeholder="jordan@medusa.com"
              />
              <br />
              <label>
                Phone <span className="required-asterisk">*</span>
              </label>
              <br />

              <input
                id="phone"
                maxLength="40"
                name="phone"
                size="20"
                type="text"
                required
                // placeholder="813-908-4567"
              />
              <br />
              <div className={styles.btnContainer}>
                <input type="submit" name="submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestDemoHero
