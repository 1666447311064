import React from 'react'
import styles from './publications.module.scss'
import Card from './slider/components/card'
import Slider from 'react-slick'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

let cardData = [
  {
    id: 1,
    image: '/PublicationSlider/1.png',
    mainText: 'Remote solution for immersive surgical mentoring',
    subText: 'Authors: Shannon Bailey, PhD \nMarc Dean, MD',
    link: 'https://www.researchgate.net/publication/340383467_Remote_solution_for_immersive_surgical_mentoring',
  },

  {
    id: 2,
    image: '/PublicationSlider/2.png',
    mainText:
      'Validation of Live Virtual Reality for Remote Surgical Education: A Usability Evaluation in Orthopedic Surgery',
    subText: 'Authors: Shannon Bailey, PhD\nStephanie C. Petterson, MD\nRichard Friedland, MD',
    link: 'https://www.researchgate.net/publication/356814267_Validation_of_Live_Virtual_Reality_for_Remote_Surgical_Education_A_Usability_Evaluation_in_Orthopedic_Surgery',
  },

  {
    id: 3,
    image: '/PublicationSlider/3.png',
    mainText:
      'The Next Generation of Virtual Reality: Recommendations for Accessible and Ergonomic Design',
    subText: 'Authors: Shannon Bailey, PhD\nBrendan A. Ciccone\nJoanna E. Lewis',
    link: 'https://www.researchgate.net/publication/350326606_The_Next_Generation_of_Virtual_Reality_Recommendations_for_Accessible_and_Ergonomic_Design',
  },

  {
    id: 4,
    image: '/PublicationSlider/4.png',
    mainText:
      'Is Virtual Reality Streaming Ready for Remote Medical Education? Measuring Latency of Stereoscopic VR for Telementoring',
    subText: 'Authors: Shannon Bailey, PhD\nMichael Burns. PhD\nKerem C. Celebi, Kunal Bansal',
    link: 'https://www.researchgate.net/publication/356167370_Is_Virtual_Reality_Streaming_Ready_for_Remote_Medical_Education_Measuring_Latency_of_Stereoscopic_VR_for_Telementoring',
  },
]

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon style={{ fill: '#fff', fontSize: 30 }} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon style={{ fill: '#fff', fontSize: 30 }} />
    </div>
  )
}

function Publications() {
  var settings = {
    // dots: true,

    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // centerMode: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainText}>Publications</div>

      <div className={styles.mainSliderContainer}>
        <div className={styles.gradient1} />
        <div className={styles.gradient2} />
        <div className={styles.sliderContainer}>
          <Slider {...settings}>
            {cardData.map((item, index) => (
              <Card item={item} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Publications
