import React from 'react'
import styles from './awareness.module.scss'
import { Link } from 'react-router-dom'
function AwarenessPlatform() {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.leftContainer}>
          <h2>Immersive Training & Awareness Platform</h2>
          <p>
            Find out how our healthcare solutions can help organizations improve business results.
          </p>
          <div className={styles.btn}>
            <Link to="/request-a-demo">Request a Demo</Link>
          </div>
        </div>
        <div className={styles.rightTopContainer}>
          <div className={styles.rightContainer}>
            <img src="/ap.png" alt="" />
          </div>
          <div className={styles.Mbtn}>
            <Link to="/request-a-demo">Request a Demo</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AwarenessPlatform
