import { specialties } from '../../../utils/specialty'
import { cdnUrl } from '../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorCardPicture,
  getInstructorProfileCover,
  getInstructorEvents,
} from '../../../utils/getAssetLink'
//Use this template to add more instructors
const data = [
  `Dom Coric, MD is a practicing neurosurgical spine surgeon at Carolina Neurosurgery and Spine Associates in Charlotte, NC. He is Chief of the Spine Division at Atrium Health Musculoskeletal Institute and Professor in the Department of Orthopedic Surgery at Atrium Healthcare where he served as Chief of the Department of Neurosurgery for 14 years. He specializes in artificial disc replacement, spinal trauma and tumors as well as adult reconstructive spinal surgery.`,
  `Dr. Coric received a BS from Duke University and his medical degree from Wake Forest University Baptist Medical Center in Winston-Salem, NC. He completed both his general surgical internship and neurosurgical residency at Wake Forest University Baptist Medical Center.`,
  `Dr. Coric is a highly regarded speaker, having given hundreds of presentations at national and international spine meetings and forums. He has published more than 100 peer-reviewed academic papers and book chapters. His research pursuits have largely focused on motion-preserving spinal technologies.`,
  `Dr. Coric is a past-President of the North Carolina Spine Society and the Southern Neurosurgical Society and serves on the Board of Directors for the Carolina Center for Specialty Surgery, Atrium Healthcare System Political Action Committee and the UnitedHealthcare Spine Scientific Advisory Board. He is currently President of the International Society for the Advancement of Spine Surgery and the Chair of the AANS/CNS Joint Section on Disorders of Spine and Peripheral Nerves.`,
]
const specialty = specialties.spine.name
const id = 'coric'
export const coricData = {
  firstName: 'Domagoj',
  lastName: 'Coric',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'Domagoj Coric, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileLink: `/Instructor/${id}`,
  profileCover: getInstructorProfileCover(id),
  shortVideoCard: getInstructorCardPicture(id),

  events: getInstructorEvents(id),
}
