import { instructorsData } from './instructorData'

export const dataForCoverSlider = {
  interventionalSpine: [
    instructorsData?.rastogi,
    instructorsData?.giuffrida,
    instructorsData?.stephens,
    instructorsData?.khosla,
  ],
  endovascular: [
    instructorsData?.michael,
    instructorsData?.walker,
    // instructorsData?.mustapha,
    instructorsData?.raja,
    instructorsData?.carr,
    instructorsData?.elder,
  ],
  spine: [
    instructorsData?.yue,
    instructorsData?.asghar,
    instructorsData?.shufflebarger,
    instructorsData?.smith,
    instructorsData?.coric,
    instructorsData?.quaidoo,
  ],
  orthopedics: [
    instructorsData?.murthi,
    instructorsData?.abboud,
    instructorsData?.grady,
    instructorsData?.cheung,
    instructorsData?.ballard,

  ],
  otolaryngology: [
    instructorsData?.dean,
    instructorsData?.pandit,
    instructorsData?.poe,
    ],

  urology: [
    instructorsData?.ramasamy,
    // instructorsData?.perito,
    instructorsData?.kow,
    instructorsData?.carrion,
    instructorsData?.knoll,
  ],
}
