import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MabCard.module.scss'

function MabCard({ item }) {
  return (
    <div className={styles.Card}>
      <Link to={item.page}>
        <div className={styles.itemSlider}>
          <div className={styles.overlay}></div>

          <img className={styles.cardImage} src={item.image} />
        </div>
        <div className={styles.cardLogo}>
          <img src={item.icon} className={styles.cardLogoImage} alt="" />
        </div>
        <div className={styles.textContainer}>
          <p className={styles.cardTitle}>{item.title}</p>
          <p className={styles.cardSubTitle}>{item.subtitle}</p>
        </div>
      </Link>
    </div>
  )
}

export default MabCard
