import React, { useState, useRef } from 'react'
import styles from './Immersive2dViewer.module.scss'
import SolutionNavbar from '../SolutionNavbar'
import { useLocation } from 'react-router'
import PulseBtn from '../PulseBtn'
import EyeBtn from '../EyeBtn'
import UserBtn from '../UserBtn'
import OppArrowBtn from '../OppArrowBtn'
import GridBtn from '../GridBtn'
import { cdnUrl } from '../../constants/enum'
function ImmersiveHero({ title, heroImage, video }) {
  const [defaultHover, setDefaultHover] = useState(0)
  const [videoUrl, setVideoUrl] = useState(video)

  const { pathname } = useLocation()
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <SolutionNavbar />
        <div className={styles.heading}>{title}</div>
        <div className={styles.heroImage}>
          <div className={styles.videoContainer}>
            <div className={styles.pulseContainer}>
              <PulseBtn />
              <EyeBtn />
              <UserBtn />
              <OppArrowBtn />
              <GridBtn />
            </div>

            <video key={defaultHover} autoPlay="autoplay" muted loop playsInline width="100%">
              <source src={videoUrl} type="video/mp4" />
            </video>
            {/* <img className={styles.hero} src={heroImage} /> */}
          </div>
          {/* <video key={defaultHover} autoPlay="autoplay" muted loop playsInline width="100%">
            <source src={videoUrl} type="video/mp4" />
          </video> */}
          <img className={styles.hero} src={heroImage} />
        </div>
        {pathname !== '/recording-library' && pathname !== '/data-analytics' && (
          <>
            <div className={styles.textContainer}>
              Explore how different specialties are shown in Immersive 2D
            </div>
            <div className={styles.navBar}>
              <div className={styles.navTab}>
                <div
                  className={`${styles.navIconsContainer} ${defaultHover === 0 && styles.active}`}
                  onClick={() => {
                    setDefaultHover(0)
                    setVideoUrl(video)
                  }}
                >
                  <div className={styles.navIcons}>
                    <img className={styles.navImage} src={'/heartNav.png'} alt="" />
                  </div>
                </div>
                <div
                  className={`${styles.navIconsContainer} ${defaultHover === 1 && styles.active}`}
                  onClick={() => {
                    setDefaultHover(1)
                    setVideoUrl(`${cdnUrl}/videos/2d_viewer/spine.mp4`)
                  }}
                >
                  <div className={styles.navIcons}>
                    <img className={styles.navImage} src={'/spineNav.png'} alt="" />
                  </div>
                </div>
                <div
                  className={`${styles.navIconsContainer} ${defaultHover === 2 && styles.active}`}
                  onClick={() => {
                    setDefaultHover(2)
                    setVideoUrl(
                      `${cdnUrl}/videos/2d_viewer/total_knee.mp4`,
                    )
                  }}
                >
                  <div className={styles.navIcons}>
                    <img className={styles.navImage} src={'/orthoNav.png'} alt="" />
                  </div>
                </div>
                <div
                  className={`${styles.navIconsContainer} ${defaultHover === 3 && styles.active}`}
                  onClick={() => {
                    setDefaultHover(3)
                    setVideoUrl(
                      `${cdnUrl}/videos/2d_viewer/reverse_shoulder.mp4`,
                    )
                  }}
                >
                  <div className={styles.navIcons}>
                    <img className={styles.navImage} src={'/bladeNav.png'} alt="" />
                  </div>
                </div>
                <div
                  className={`${styles.navIconsContainer} ${defaultHover === 4 && styles.active}`}
                  onClick={() => {
                    setDefaultHover(4)
                    setVideoUrl(`${cdnUrl}/videos/2d_viewer/foot.mp4`)
                  }}
                >
                  <div className={styles.navIcons}>
                    <img className={styles.navImage} src={'/footNav.png'} alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.textTab}>
                <div className={styles.navTextContainer}>
                  <div className={styles.navText}>
                    {defaultHover === 0 && 'Interventional Cardiology'}
                  </div>
                </div>
                <div className={styles.navTextContainer}>
                  <div className={styles.navText}>{defaultHover === 1 && 'Spine'}</div>
                </div>
                <div className={styles.navTextContainer}>
                  <div className={styles.navText}>{defaultHover === 2 && 'Knee'}</div>
                </div>
                <div className={styles.navTextContainer}>
                  <div className={styles.navText}>{defaultHover === 3 && 'Shoulder'}</div>
                </div>
                <div className={styles.navTextContainer}>
                  <div className={styles.navText}>{defaultHover === 4 && 'Foot'}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ImmersiveHero
