import React from 'react'
import Footer from './components/Footer/Footer'
import MoreAboutScience from './components/MoreAboutScience/MoreAboutScience'
import PlatformSupport from './components/PlatformSupport/PlatformSupport'
import OurApproach from './components/OurApproach/OurApproach'
import ResearchPartners from './components/ResearchPartners'
import IMC from './components/Imc'
import Publications from './components/Publications'
import GoldenStandard from './components/GoldenStandard/GoldenStandard'
import ScienceStats from './components/ScienceStats/ScienceStats'
import BurgerMenu from './components/Header/burgerMenu'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import Header from './components/Header/Header'

export default function SciencePage() {
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const [width] = useWindowSize()

  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}
      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      <MoreAboutScience />
      <PlatformSupport />
      <OurApproach />
      <GoldenStandard />
      <ResearchPartners />
      <IMC />
      <ScienceStats />
      <Publications />
      <Footer />
    </div>
  )
}
