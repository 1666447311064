export const mloc1928 = {
  id: 'MLOC-1928',
  name: 'Dallas ENT Head & Neck Surgery',
  description:
    'Dallas ENT provides care for the ear, nose, and throat, including an innovative new procedure called balloon sinuplasty for the treatment of sinusitis.',
  status: '07-Event Ready (ES)',
  address1: '1411 North Beckley Avenue',
  address2: null,
  city: 'Dallas',
  state: 'TX',
  zip: '75203',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '32.74627',
  longitude: '-96.82278',
  photo: 'mloc-1928.png',
  speciality: 'Spine',
}
