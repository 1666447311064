import React from 'react'
import styles from './sales.module.scss'

function SalesEnablement() {
  return (
    <div className={styles.container}>
      <div className={styles.gradient} />
      <div className={styles.subcontainer}>
        <div className={styles.leftContainer}>
          <img src="/set.png" alt="" />
        </div>
        <div className={styles.rightContainer}>
          <h2>Sales enablement </h2>
          <p>
            Immertec's solutions allow medical sales reps to build trust with customers, improve
            lead generation, and shorten the sales cycle.
          </p>
        </div>
      </div>
    </div>
  )
}

export default SalesEnablement
