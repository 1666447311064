export const mloc581 = {
  id: 'MLOC-581',
  name: 'Oschner Clinical Simulation Center',
  description:
    'The Ochsner Clinical Simulation and Patient Safety Center opened in late 2017. Offering the most current technology to improve patient safety through the training of all practicing clinicians and students and residents. The high-fidelity human patient simulators mimic physiologic functions of a real patient, and allow learners to acquire and practice clinical skills in a safe, non-judgmental environment.',
  status: '09-Out of Service (ES)',
  address1: '1401 Jefferson Highway',
  address2: '2nd floor',
  city: 'New Orleans',
  state: 'LA',
  zip: '70121',
  category: 'Commercial',
  type: 'Bioskills Lab',
  latitude: '29.96249',
  longitude: '-90.14467',
  photo: 'mloc-581.png',
  speciality: 'Urology',
}
