const getFormUrl = (type) => {
  const url = window.location.origin
  const extension = url.split('.').slice(-1)[0]

  if (extension === 'com') {
    return `https://immertec.formstack.com/forms/marketing_lead_capture?lead_interest=${type}`
  }

  return `https://immertec.formstack.com/forms/marketing_lead_capture_dev?lead_interest=${type}`
}

export default getFormUrl

export const getFormRedirectUrl = (type) => {
  const url = window.location.origin

  return `${window.location.origin}/success/request-a-demo`
}
