import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorCardPicture,
  getInstructorProfileCover,
  getInstructorEvents,
} from '../../../utils/getAssetLink'
const data = [
  `Dr. Paul Michael is a cardiologist in Boynton Beach, Florida and is affiliated with JFK Medical Center. He received his medical degree from University of Miami School of Medicine and has been in practice between 6-10 years.`,
  `Dr. Michael, a fellow of the Society of Cardiac Angiography and Intervention, is a Miami native and graduated medical school at the University of Miami. He completed Internal Medicine residency as Chief Resident at Cleveland Clinic Florida. In New Orleans at the Ochsner Heart and Vascular Institute he then completed his Cardiovascular Disease fellowship and served as Chief Cardiology Fellow. Doctor Michael continued his specialty training at Ochsner by completing a two year advanced coronary, peripheral, and structural interventional cardiology fellowship where he also served as chief interventional cardiology fellow. He is board certified in Internal Medicine, Cardiovascular Disease, and Interventional Cardiology. Doctor Michael speaks fluent Spanish and is excited to be working back in South Florida. He specializes in cardiac as well as peripheral arterial and venous interventions`,
]
const specialty = specialties.endovascular.name
const id = 'michael'
export const michaelData = {
  firstName: 'Paul',
  lastName: 'Michael',
  middleName: undefined,
  suffix: 'MD, FSCAI',
  id,
  title: 'PAUL MICHAEL, MD, FSCAI',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  profileCover: getInstructorProfileCover(id),
  category: specialty,
  shortVideoCard: getInstructorCardPicture(id),

  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
