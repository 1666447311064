export const mloc685 = {
  id: 'MLOC-685',
  name: 'El Paso Cardiology Associates',
  description:
    'El Paso Cardiology Associates is a medical group practice located in El Paso, TX that specializes in Nursing (Nurse Practitioner) and Cardiology.',
  status: '07-Event Ready (ES)',
  address1: '4301 N Messa',
  address2: 'Suite 100',
  city: 'El Paso',
  state: 'TX',
  zip: '79902',
  category: 'Commercial',
  type: 'Office Based Lab',
  latitude: '31.7967',
  longitude: '-106.5116',
  photo: 'mloc-685.png',
  speciality: 'Urology',
}
