export const locationSpeciality = {
  'MLOC-1920': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: ['khosla'],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-556': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-64': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-269': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-1923': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: ['khosla'],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-497': {
    spine: [],
    orthopedics: ['grady'],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-55': {
    spine: ['asghar'],
    orthopedics: [],
    urology: [],
    interventionalSpine: ['giuffrida'],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-181': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: ['stephens'],
    endovascular: [],
    otolaryngology: ['dean'],
  },
  'MLOC-217': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: ['giuffrida'],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-700': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: ['carr'],
    otolaryngology: [],
  },
  'MLOC-709': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: ['walker'],
    otolaryngology: [],
  },
  'MLOC-73': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-1926': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-665': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-1928': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: ['pandit'],
  },
  'MLOC-91': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-5442': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: ['dean'],
  },
  'MLOC-685': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: ['raja'],
    otolaryngology: [],
  },
  'MLOC-595': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-3937': {
    spine: ['quaidoo'],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-769': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-319': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-1942': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-145': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-163': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-905': {
    spine: ['asghar'],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-136': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-489': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-458': {
    spine: [],
    orthopedics: ['ballard'],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-1931': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: ['stephens'],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-1938': {
    spine: [],
    orthopedics: [],
    urology: ['ramasamy'],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-639': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-352': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-543': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-919': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: ['rastogi'],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-695': {
    spine: ['yue'],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-199': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-457': {
    spine: [],
    orthopedics: ['ballard'],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-109': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-581': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: ['stephens'],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-1963': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-931': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: ['michael'],
    otolaryngology: [],
  },
  'MLOC-686': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-1': {
    spine: ['asghar', 'shufflebarger'],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-226': {
    spine: [],
    orthopedics: ['cheung'],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-8915': {
    spine: [],
    orthopedics: [],
    urology: ['knoll'],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-1940': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
  'MLOC-127': {
    spine: [],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: ['dean'],
  },
  'MLOC-840': {
    spine: ['smith'],
    orthopedics: [],
    urology: [],
    interventionalSpine: [],
    endovascular: [],
    otolaryngology: [],
  },
}
