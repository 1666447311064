import React from 'react'
import styles from './toggle.module.scss'

function ToggleMap({ mapStyle, onChange }) {
  return (
    <div className={styles.main}>
      <div
        className={mapStyle === 'satellite-v9' ? styles.tab : styles.activetab}
        onClick={() => onChange('light-v10')}
      >
        Map
      </div>
      <div
        className={mapStyle === 'light-v10' ? styles.tab : styles.activetab}
        onClick={() => onChange('satellite-v9')}
      >
        Satellite
      </div>
    </div>
  )
}

export default ToggleMap
