import React from 'react'
import styles from './popover.module.scss'
import { Popup } from 'react-map-gl'
import { getLocationImage } from '../../../../utils/getAssetLink'

function Popover({
  markerHovered,
  photo,
  latitude,
  longitude,
  id,
  name,
  address1,
  address2,
  city,
  state,
}) {
  return (
    <React.Fragment>
      {markerHovered === id && (
        <Popup latitude={+latitude} longitude={+longitude} anchor="bottom" closeOnClick={false}>
          <div className={styles.popOver}>
            <div className={styles.container}>
              <div className={styles.imageContainer}>
                <img src={getLocationImage(photo)} alt={''} />
              </div>
              <div className={styles.textContainer}>
                <div className={styles.title}>{name}</div>
                <div className={styles.locationContainer}>
                  <div className={styles.locationIcon}>
                    <img src="/LocationPage/locationIcon.png" alt="" />
                  </div>
                  <div className={styles.locationText}>
                    {address1 ?? '' + ' ' + address2 ?? '' + ',' + city ?? '' + ',' + state ?? ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </React.Fragment>
  )
}

export default Popover
