import React from 'react'
import styles from './SolutionsSubMenuHero.module.scss'
import SolutionNavbar from '../SolutionNavbar'

function SolutionsSubMenuHero({ title, heroImage, video }) {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <SolutionNavbar />
        <div className={styles.heading}>{title}</div>
        <div className={styles.heroImage}>
          {video ? (
            <video autoPlay playsInline loop muted width="100%">
              <source src={video} type="video/png" />
            </video>
          ) : (
            <img className={styles.hero} src={heroImage} />
          )}
        </div>
      </div>
    </div>
  )
}

export default SolutionsSubMenuHero
