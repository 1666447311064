import React from 'react'
import styles from './MedicalProfessionals.module.scss'

function MedicalProfessionals() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>
        Increase knowledge transfer through more sophisticated didactics which facilitates quicker
        adoption of procedural advancements.
      </div>
    </div>
  )
}

export default MedicalProfessionals
