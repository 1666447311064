import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorCardPicture,
  getInstructorEvents,
} from '../../../utils/getAssetLink'
const data = [
  `Dr. Anthony Giuffrida is a board-certified physiatrist, fellowship-trained in interventional spine and sports medicine. He focuses on the treatment of spine and sports-related injuries. He seeks to advance the field of Physical Medicine and Rehabilitation while changing the face of pain medicine, using a mixture of conventional and cutting-edge wellness techniques. He brings to his practice the highest level of expertise in non-surgical clinical studies and research designed to alleviate pain.`,

  `Dr. Giuffrida’s deeply committed to research and development in the field of regenerative medicine. He’s at the forefront of significant advancements in the management of pain. He researches treatments highlighting the most progressive techniques comprising of, but not limited to, the Intracept® procedure, Steroid Injections, Kyphoplasty and Vertebroplasty, Ultrasound-Guided Injections, Spinal Cord Stimulators, Sympathetic Blocks, and Radiofrequency Ablation.`,

  `He is the first and only physician in South Florida to offer breakthrough treatments for patients with chronic low back pain. Dr. Giuffrida prides himself in always investigating previously unexplored treatment options for chronic pain. As a native New Yorker, Dr. Giuffrida embarked on his professional journey at SUNY Upstate Medical University in Syracuse. From there, he went on to complete his PM&R residency at Albany Medical College, followed by an Interventional Sports and Spine Fellowship at Alabama Orthopedic Spine & Sports Medicine Associates.`,
]
const specialty = specialties.interventionalSpine.name
const id = 'giuffrida'
export const giuffridaData = {
  firstName: 'Anthony',
  lastName: 'Giuffrida',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'ANTHONY GIUFFRIDA, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].name, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileLink: `/Instructor/${id}`,
  profileCover: getInstructorProfileCover(id),
  events: getInstructorEvents(id),
  shortVideoCard: getInstructorCardPicture(id),
}
