import React from 'react'
import styles from './Introduction.module.scss'
import ContainedButton from '../ContainedButton'
import getUrlForm from '../../utils/getFormUrl'
import ProgressiveImage from '../ProgressiveImage'
import { Link } from 'react-router-dom'
function Introduction() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.textContainer}>
          <div className={styles.heading}>Immertec’s Medical Advisory Board</div>
          <div className={styles.btnContainer}>
            <Link to="/request-a-demo">
              <ContainedButton
                customStyles={{
                  height: '2.9166666666666665vw',
                  width: '21.822916666666668vw',
                  backgroundColor: '#47D7AC',
                }}
                textStyles={{
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.0416666666666667vw',
                  fontWeight: '100',
                  alignItems: 'center',
                  textAlign: 'center',
                  // Textheight: '24px' ,
                  // "line-height": '24px',
                  color: '#243746',
                }}
                label={'Request a demo'}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <ProgressiveImage src={'/mabMain.jpeg'} placeholder={'/mabMainProgressive.jpeg'} alt="" />
      </div>

      <div className={styles.MbtnContainer}>
        <Link to="/request-a-demo">
          <ContainedButton
            customStyles={{
              width: '303px',
              height: '41px',
              backgroundColor: '#47D7AC',
            }}
            textStyles={{
              fontFamily: 'Gilroy-Bold',
              fontSize: '15px',
              fontWeight: '100',
              alignItems: 'center',
              textAlign: 'center',
              // Textheight: '24px' ,
              // "line-height": '24px',
              color: '#243746',
            }}
            label={'Request a demo'}
          />
        </Link>
      </div>
    </div>
  )
}

export default Introduction
