export const mloc497 = {
  id: 'MLOC-497',
  name: 'Andrews Institute for Orthopedics & Sports Medicine',
  description:
    'World–class athletes, weekend warriors, youth athletes and everyday people have access to state–of–the–art performance training and health care services conveniently located in Gulf Breeze, Florida at the Andrews Institute for Orthopaedics & Sports Medicine. Under the leadership and reputation of internationally renowned orthopaedic sports surgeon Dr. James Andrews, the Andrews Institute provides a spectrum of services in order for patients to quickly and accurately address their orthopaedic needs.\n\nEncompassing nearly 127,000 square feet, the Andrews Institute is filled with dedicated research laboratories as well as education and conference facilities plus its numerous multi–specialty clinical departments to compliment the services of its 30 affiliated physicians to provide an all–inclusive continuum of care. The Andrews Institute mission is to provide the best medical care for the musculoskeletal system, using the latest innovative clinical and surgical technologies, and to improve patient care through research and education with an emphasis on prevention.\n\n Specialties available on–site include:\n\nNeurosurgery\nOrthopaedics\nPain Management\nPhysical Medicine & Rehabilitation (Physiatry)\nNon-Surgical Sports Medicine\nRadiology\nTotal Joint Center\nVascular\n Institute departments:\n\nAndrews Institute Ambulatory Surgery Center\nDiagnostic Imaging and MRI\nAndrews Institute Rehabilitation (AIR)\nSports Medicine Outreach\nEXOS\nAndrews Research & Education Foundation',
  status: '07-Event Ready (ES)',
  address1: '1040 Gulf Breeze Pkwy',
  address2: null,
  city: 'Gulf Breeze',
  state: 'FL',
  zip: '32561',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '30.35904',
  longitude: '-87.16013',
  photo: 'mloc-497.png',
  speciality: 'Otolaryngology',
}
