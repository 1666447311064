export const mloc73 = {
  id: 'MLOC-73',
  name: 'Center for Advanced Medical Learning and Simulation (CAMLS)',
  description:
    'CAMLS is one of the world’s largest medical simulation facilities, fully dedicated to improving health care through lifelong education and training. CAMLS provides simulation experiences for educating students; training health care faculty in simulation education; and preparing health care professionals to acquire, maintain and enhance their clinical practice to meet today’s workforce needs and improve patient care in Tampa Bay, Florida and around the globe. <br /><br /> CAMLS specializes in providing a realistic training environment for healthcare providers and can replicate nearly any clinical/medical environment, including the use of virtual and human ‘patients’ (standardized patients) as well as testing resources.   <br /> CAMLS also has the unique ability to bring together scholarly experts across USF Health, USF, and communities, both locally and globally, to conduct interprofessional research and training, as well as implement innovative technologies. Headquartered in downtown Tampa, Florida, CAMLS is accredited by the American College of Surgeons and the Society for Simulation in Healthcare.  <br /><br /> CAMLS is conveniently located in the heart of downtown Tampa, Florida and is open seven days a week to accommodate the numerous demands that challenge today’s domestic and international healthcare providers and researchers.',
  status: '07-Event Ready (ES)',
  address1: '124 S. Franklin Street',
  address2: null,
  city: 'Tampa',
  state: 'FL',
  zip: '33602',
  category: 'Pilot',
  type: 'Bioskills Lab',
  latitude: '27.94442',
  longitude: '-82.45589',
  photo: 'mloc-73.png',
  speciality: 'Endovascular',
}
