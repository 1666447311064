import React from 'react'
import { Link } from 'react-router-dom'
import ContainedButton from '../../ContainedButton'
import styles from './hoverOverlay.module.scss'

function HoverOverlay({ onMuteClick, muted }) {
  return (
    <div className={styles.container} onClick={onMuteClick}>
      <img src={muted ? '/unmute.svg' : '/muteIcon.svg'} alt="" onClick={onMuteClick} />
    </div>
  )
}

export default HoverOverlay
