export const mloc595 = {
  id: 'MLOC-595',
  name: 'Elliot Hospital',
  description:
    "Elliot Health System (EHS) is the largest provider of comprehensive healthcare services in Southern New Hampshire. The cornerstone of EHS is Elliot Hospital, 296-bed acute care facility located in Manchester (New Hampshire's largest city). Established in 1890, Elliot Hospital offers Southern New Hampshire communities caring, compassionate and professional patient service regardless of race, religion, national origin, gender, age, disability, marital status, sexual preference, or ability to pay.\nEHS is home to Manchester’s designated Regional Trauma Center, Urgent Care Centers, a Level 3 Newborn Intensive Care Unit, Elliot Physician Network, Elliot Specialists, Elliot Regional Cancer Center, Elliot Senior Health Center, Visiting Nurse Association of Manchester and Southern New Hampshire, Elliot 1-Day Surgery & Endoscopy Center, and Elliot at River’s Edge.",
  status: '07-Event Ready (ES)',
  address1: '1 Elliot Way',
  address2: null,
  city: 'Manchester',
  state: 'NH',
  zip: '3103',
  category: 'Commercial',
  type: 'Hospital',
  latitude: '42.9837',
  longitude: '-71.43823',
  photo: 'mloc-595.png',
  speciality: 'Spine',
}
