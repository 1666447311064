import React from 'react'
import styles from './is.module.scss'
import OutlineButton from '../OutlineButton'
import ContainedButton from '../ContainedButton'
import './slider.css'
import { instructorsData } from '../../constants/data/instructorData'
import useCheckMobileScreen from '../../utils/getPlatform'
import getUrlForm from '../../utils/getFormUrl'
import { Link } from 'react-router-dom'

function InstructorSlider({ instructorId, sendToComponent }) {
  if (!instructorId) instructorId = 'default'
  const data = instructorsData[instructorId]
  // const name = `${data?.name}, ${data?.suffix}`
  // TODO: Remove the platform and just consume the desktop pictures.
  // const platform = useCheckMobileScreen()
  // TODO: Make this check if local path is present first. If not then use a link. if link is not there then use default.
  // const imagePath = `/InstructorHeroPictures/${platform}/${data.localHeroImage}`
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.leftSubContainer}>
          <div className={styles.textContainer}>
            <div className={styles.text}>{data?.title}</div>
          </div>
          <div className={styles.divider} />
          <div className={styles.highlightedText}>{data?.instructor_specialties}</div>
          <div className={styles.btnContainer}>
            <Link to="/request-a-demo">
              <ContainedButton
                customStyles={{
                  height: '2.8315085158150857vw',
                  width: '12.03125vw',
                  backgroundColor: '#47D7AC',
                }}
                textStyles={{
                  fontFamily: 'Gilroy-Medium',
                  fontSize: '0.9375vw',
                  color: '#243746',
                }}
                label={'Request a demo'}
              />
            </Link>
            <OutlineButton
              customStyles={{
                height: '2.8315085158150857vw',
                width: '15.416666666666666vw',
              }}
              textStyles={{
                fontFamily: 'Gilroy-Medium',
                fontSize: '1.0416666666666667vw',
                color: '#fff',
              }}
              label={'See Upcoming Events'}
              handleClick={sendToComponent}
            />
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.imageContainer}>
          <img src={data?.profileCover} alt="" />
        </div>
      </div>
      <div className={styles.MbtnContainer}>
        <Link to="/request-a-demo">
          <ContainedButton
            customStyles={{
              width: '12.03125vw',
              height: '2.998389694041868vw',
              backgroundColor: '#47D7AC',
            }}
            textStyles={{
              fontFamily: 'Gilroy-Bold',
              fontSize: '1.0416666666666667vw',
              fontWeight: '100',
              color: '#243746',
            }}
            label={'Request a Demo'}
          />
        </Link>

        <OutlineButton
          customStyles={{ height: '41px', width: '303px' }}
          textStyles={{
            fontFamily: 'Gilroy-Medium',
            fontSize: '14.46px',
            color: '#fff',
          }}
          label={'See Upcoming Events'}
          handleClick={sendToComponent}
        />
      </div>
      5
    </div>
  )
}

export default InstructorSlider
