export const mloc457 = {
  id: 'MLOC-457',
  name: 'Oregon Surgical Institute',
  description:
    'Oregon Surgical Institute is the first surgery center in the northwest United States designed specifically to treat complex cases and procedures including total joint replacement and spine disorders, as well as provide a comprehensive suite of interventional pain management therapies. Our specialists include orthopedic total joint replacement surgeons and neurosurgical and orthopedic spine specialists, as well as sports medicine and restorative medicine experts."',
  status: '07-Event Ready (ES)',
  address1: '9405 SW Nimbus Ave',
  address2: null,
  city: 'Beaverton',
  state: 'OR',
  zip: '97008',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '45.451291070303554',
  longitude: ' -122.79013761601077',
  photo: 'mloc-457.png',
  speciality: 'Otolaryngology',
}
