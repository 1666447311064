import React from 'react'
import styles from './benefits.module.scss'

function Benefits() {
  return (
    <div className={styles.container}>
      {/* <div className={styles.gradient}> </div> */}

      <div className={styles.subcontainer}>
        <div className={styles.benefitContainer}>
          <h2>Benefits</h2>
          <div className={styles.descriptionTopContainer}>
            <div className={styles.descriptionContainer}>
              <div className={styles.column}>
                <div className={styles.title}>Increase access</div>
                <div className={styles.para}>
                  Users can step inside an OR from anywhere without needing large time commitments
                  or travel budgets.
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.title}>Accelerate learning</div>
                <div className={styles.para}>
                  Learning in VR helps increase user engagement and improves learning outcomes.
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.title}>Empower teams</div>
                <div className={styles.para}>
                  Sales and marketing teams can be equipped with high impact demos to help them
                  capture more business.
                </div>
              </div>
            </div>
            <div className={styles.imageContainer}>
              <img src="/vr-benefit.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Benefits
