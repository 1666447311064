export const mloc686 = {
  id: 'MLOC-686',
  name: 'Pulse Cardiovascular Institute',
  description:
    'Pulse Cardiovascular Institute has emerged as the new paradigm in clinic-based cardiovascular care. The Board Certified Vascular Surgeons, Interventional Radiologists and Cardiologists who treat peripheral vascular and vein conditions at Pulse Cardiovascular Institute have the newest Imaging System in the Vascular Lab. We believe in the highest quality care delivered with the help of the latest cutting edge technology - in a brand new space. At Pulse Cardiovascular Institute we treat hip, leg and ankle conditions like tingling, numbness, pain, swelling, discomfort, redness, aching, itching, varicose veins, spider veins, DVTs, May-Thurner Syndrome, iliac vein disease, pelvic congestion, and many more such lower extremity conditions."',
  status: '06-Event Prep (ES)',
  address1: '7285 E Earll Dr. Building C',
  address2: 'Suite C',
  city: 'Scottsdale',
  state: 'AZ',
  zip: '85251',
  category: 'Commercial',
  type: 'Office Based Lab',
  latitude: '33.48343',
  longitude: '-111.92462',
  photo: 'mloc-686.png',
  speciality: 'Urology',
}
