import React from 'react'
import styles from './doctorSaying.module.scss'
import SliderCard from './sliderCard'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Slider from 'react-slick'

let merchData = [
  {
    id: 1,
    img: '/Home/DoctorSaying2.png',
    text: 'Stepped into the procedure suite in #NOLA with @mededcombine by way of virtual reality this afternoon.Amazing use of technology with @immertec to expand #interventionalpain education on some cutting edge procedures.Thanks for today’s event @drgsportsspine / @drchadstephens -very cool experience.',
    name: '@katzenpainmed',
  },
  {
    id: 2,
    img: '/Home/DoctorSaying3.png',
    text: 'My team and I are constantly evolving our methods and treatments with new technology advances. We use the latest technologies and quality resources to provide the best treatments possible for our patients.#southflorida #florida #backhealth #spinehealth #lovemypatients #patientcare #spinesurgery #spine #spinesurgeon #westpalmbeach',
    name: '@shufflespine',
  },
  {
    id: 3,
    img: '/Home/DoctorSaying4.png',
    text: 'This was one of the coolest learning experiences of my medical career. Cutting edge #interventionalpainmanagement procedures taught over virtual reality. It was great way to learn about procedures that I have never seen before. This will definitely be the future of medical education, and beyond. I am honored to have been invited to this! Thank you to all the companies involved that could make this happen.',
    name: '@dr.nvsportsandspine',
  },
  {
    id: 4,
    img: '/Home/DoctorSaying1.png',
    text: 'Why travel when you can sit back, relax, and get three hours of training in virtual reality',
    name: '@dr.alan.n',
  },
]

function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon style={{ fill: '#fff', fontSize: 30 }} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon style={{ fill: '#fff', fontSize: 30 }} />
    </div>
  )
}

function DoctorSaying() {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: false,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          See what participants are saying about their experience on social media
        </div>
      </div>
      <div className={styles.overlay1} />
      <div className={styles.overlay2} />
      <div className={styles.sliderStyle}>
        <div className={styles.sliderContainer}>
          <Slider {...settings}>
            {merchData.map((item, index) => (
              <SliderCard key={`${item.name}_${index}`} item={item} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default DoctorSaying
