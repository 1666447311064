import React, { useState, useEffect } from 'react'
import styles from './collaborate.module.scss'
import PausedOverlay from './components/pausedOverlay'
import { useInView } from 'react-intersection-observer'
import FullScreenVideo from '../Fullscreen/FullScreenVideo'

function CollaborateSection({ cover, isHoveredForMobile }) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const { ref, inView } = useInView({
    threshold: 1,
  })

  useEffect(() => {
    setIsVideoPlaying(inView)
  }, [inView])

  return (
    <div className={styles.container}>
      <div className={styles.sectionTitle}>Collaborate live with your peers in the metaverse</div>
      <div key={cover?.id} className={styles.imageContainer} ref={ref}>
        <FullScreenVideo
          video={cover?.longVideoLink}
          id={cover?.id}
          route={cover?.profileLink} 
          focused={isVideoPlaying && !isHoveredForMobile}
          PausedOverlay={
            <PausedOverlay
              title={'Interventional Cardiology and Cardiovascular Diseases'}
              image={cover?.videoCover}
              name={'M. Laiq Raja, M.D., F.A.C.C., F.S.C.A.I.'}
            />
          }
        />
      </div>
    </div>
  )
}

export default CollaborateSection
