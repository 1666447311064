import { specialties } from '../../../utils/specialty'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'

const data = [
  `James C. Ballard, MD is a board certified joint replacement specialist serving patients in Oregon City, Tualatin, and surrounding communities. Dr. Ballard specializes in both primary and revision hip and knee replacements using the latest techniques, including robotic assisted knee replacement and outpatient surgery options. In fact, he was the first surgeon in the Northwest to perform an outpatient total joint replacement. `,
  `Dr. Ballard has consistently been named a top orthopedic surgeon in his area for many years. In addition to keeping up with the latest advancements in orthopedic surgery, he also frequently teaches other surgeons about the latest techniques. Dr. Ballard’s ultimate goal is to provide patients with solutions that improve the quality of their lives using techniques with proven results. `,
]
const specialty = specialties.orthopedics.name
const id = 'ballard'

export const ballardData = {
  firstName: 'James C.',
  lastName: 'Ballard',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'JAMES C. Ballard, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  shortVideoCard: getInstructorCardPicture(id),

  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
