import React from 'react'
import styles from './freedoc.module.scss'
import OutlineButton from '../OutlineButton'
import ContainedButton from '../ContainedButton'
import { Link } from 'react-router-dom'
import getUrlForm from '../../utils/getFormUrl'

function FreeForDoc() {
  return (
    <div className={styles.bigContainer}>
      <div className={styles.container}>
        <div className={styles.innerLeftContainer}>
          <div className={styles.textContainer}>
            <div className={styles.textSubContainer}>
              <div className={styles.textHeader}>Collaborate on live cases from anywhere</div>

              <ul>
                <li>3D immersive experience</li>
                <li>Multi-way real-time communication </li>
                <li>
                  Observe up to 4 live medical imaging
                  <br /> &emsp; &nbsp;feeds
                </li>
                <li>
                  Customized experience for each <br /> &emsp; &nbsp;participant
                </li>
                <li>Available for iOS and Android</li>
              </ul>
            </div>
            <div className={styles.phoneImageContainer}>
              <img
                src={'/icons/phone.png'}
                alt=""
                style={{ height: '80%', objectFit: 'contain' }}
              />
            </div>
          </div>
          <div className={styles.MtextContainer}>
            <div className={styles.MtextSubContainer}>
              <ul>
                <li>Fully immerse into a live operating room </li>
                <li>Customize your learning expereince </li>
                <li>Available for iOS and Android</li>
              </ul>
            </div>
          </div>
          <div className={styles.btnContainer}>
            <Link to="/request-a-demo">
              <ContainedButton
                customStyles={{
                  width: '12.03125vw',
                  height: '2.998389694041868vw',
                  backgroundColor: '#47D7AC',
                }}
                textStyles={{
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.0416666666666667vw',
                  fontWeight: '100',
                  color: '#243746',
                }}
                label={'Request a Demo'}
              />
            </Link>
            <Link to="/science">
              <OutlineButton
                customStyles={{
                  height: '2.822020128824477vw',
                  width: '14.607864583333333vw',
                }}
                textStyles={{
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.0416666666666667vw',
                  fontWeight: '100',
                  color: '#47D7AC',
                  width: '15.520833333333334vw',
                  height: '2.998389694041868vw',
                }}
                label={'Learn About the Science'}
              />
            </Link>
          </div>
          <div className={styles.MbtnContainer}>
            <Link to="/request-a-demo">
              <ContainedButton
                customStyles={{
                  width: '303px',
                  height: '40px',
                  backgroundColor: '#47D7AC',
                }}
                textStyles={{
                  fontFamily: 'Gilroy-Medium',
                  fontSize: '16px',
                  color: '#243746',
                }}
                label={'Request a Demo'}
              />
            </Link>

            <Link to="/science">
              <OutlineButton
                customStyles={{
                  width: '303px',
                  height: '40px',
                }}
                textStyles={{
                  fontFamily: 'Gilroy-Medium',
                  fontSize: '16px',
                  color: '#47D7AC',
                }}
                label={'Learn About the Science'}
              />
            </Link>
          </div>
        </div>
        <div className={styles.innerRightContainer}>
          <div className={styles.mtextHeader}>Collaborate on live cases from anywhere</div>
          <div className={styles.rightScreenContainer}>
            <img src={'/icons/screen.png'} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.overlay}></div>
    </div>
  )
}

export default FreeForDoc
