import React, { useRef } from 'react'
import Footer from './components/Footer/Footer'
import JoinUs from './components/JoinUs/JoinUs'
import StartupHumbled from './components/StartupHumbled/StartupHumbled'
import Gallery from './components/Gallery/Gallery'
import ThreeItems from './components/ThreeItems/ThreeItems'
import AwesomeTeamSlider from './components/AwesomeTeamSlider/AwesomeTeamSlider'
import OpenPosition from './components/OpenPosition/OpenPosition'
import CoreValues from './components/CoreValues/CoreValues'
import ReadyToLearnMore from './components/ReadyToLearnMore/ReadyToLearnMore'
import BurgerMenu from './components/Header/burgerMenu'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import Header from './components/Header/Header'

function CareerPage() {
  const openRoles = useRef()
  const openRolesClick = () => {
    openRoles.current.scrollIntoView({ behavior: 'smooth' })
  }
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const [width] = useWindowSize()

  return (
    <div>
      <BurgerMenu />
      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}

      <JoinUs onClick={openRolesClick} />
      <StartupHumbled />
      <ReadyToLearnMore onClick={openRolesClick} />
      <CoreValues />
      <Gallery />
      <ThreeItems />
      <AwesomeTeamSlider />
      <OpenPosition reference={openRoles} />
      <Footer />
    </div>
  )
}

export default CareerPage
