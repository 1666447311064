import { getInstructorCardPicture, getShortInstructorVideoLink } from '../../utils/getAssetLink'

export const productTabsData = {
  0: [
    {
      id: 1,
      img: getInstructorCardPicture('walker'),
      name: 'Craig Walker, MD, FACC, FACA, FACP, FSCAI,FASCI, FCCP, FICA',
      subTitle: 'Endovascular',
      video: getShortInstructorVideoLink('walker'),
      path: '/Instructor/walker',
    },
    // {
    //   id: 2,
    //   img: getInstructorCardPicture('mustapha'),
    //   name: 'Jihad A. Mustapha, MD, FACC, FSCAI',
    //   subTitle: 'Endovascular',
    //   video: getShortInstructorVideoLink('mustapha'),
    //   path: '/Instructor/mustapha',
    // },
    {
      id: 3,
      img: getInstructorCardPicture('raja'),
      name: 'M. Laiq Raja, MD, FACC, FSCAI',
      subTitle: 'Endovascular',
      video: getShortInstructorVideoLink('raja'),
      path: '/Instructor/raja',
    },
    {
      id: 4,
      img: getInstructorCardPicture('carr'),
      name: 'Jeffrey G. Carr, MD, FACC, FSCAI',
      subTitle: 'Endovascular',
      video: getShortInstructorVideoLink('carr'),
      path: '/Instructor/carr',
    },
    {
      id: 5,
      img: getInstructorCardPicture('michael'),
      name: 'Paul Michael, MD, FSCAI',
      subTitle: 'Endovascualr',
      video: getShortInstructorVideoLink('michael'),
      path: '/Instructor/michael',
    },
  ],
  1: [
    {
      id: 6,
      img: getInstructorCardPicture('asghar'),
      name: 'Jahangir "John" Asghar, MD',
      subTitle: 'Spine',
      video: getShortInstructorVideoLink('asghar'),
      path: '/Instructor/asghar',
    },
    {
      id: 7,
      img: getInstructorCardPicture('shufflebarger'),
      name: 'Harry Shufflebarger, MD',
      subTitle: 'Spine',
      video: getShortInstructorVideoLink('shufflebarger'),
      path: '/Instructor/shufflebarger',
    },
    {
      id: 8,
      img: getInstructorCardPicture('yue'),
      name: 'James Yue, MD',
      subTitle: 'Spine',
      video: getShortInstructorVideoLink('yue'),
      path: '/Instructor/yue',
    },

    {
      id: 8,
      img: getInstructorCardPicture('smith'),
      name: 'Tyler Smith, MD',
      subTitle: 'Spine',
      video: getShortInstructorVideoLink('smith'),
      path: '/Instructor/smith',
    },
    {
      id: 8,
      img: getInstructorCardPicture('coric'),
      name: 'Domagoj Coric, MD',
      subTitle: 'Spine',
      video: getShortInstructorVideoLink('coric'),
      path: '/Instructor/coric',
    },
  ],
  2: [
    {
      id: 9,
      img: getInstructorCardPicture('khosla'),
      name: 'Ankur Khosla, MD',
      subTitle: 'Interventional Spine',
      video: getShortInstructorVideoLink('khosla'),
      path: '/Instructor/khosla',
    },
    {
      id: 10,
      img: getInstructorCardPicture('stephens'),
      name: 'Chad Stephens, DO',
      subTitle: 'Interventional Spine',
      video: getShortInstructorVideoLink('stephens'),
      path: '/Instructor/stephens',
    },
    {
      id: 11,
      img: getInstructorCardPicture('giuffrida'),
      name: 'Anthony Giuffrida, MD',
      subTitle: 'Interventional Spine',
      video: getShortInstructorVideoLink('giuffrida'),
      path: '/Instructor/giuffrida',
    },
    {
      id: 12,
      img: getInstructorCardPicture('rastogi'),
      name: 'AJ Rastogi, MD',
      subTitle: 'Interventional Spine',
      video: getShortInstructorVideoLink('rastogi'),
      path: '/Instructor/rastogi',
    },
  ],
  3: [
    {
      id: 13,
      img: getInstructorCardPicture('murthi'),
      name: 'Anand Murthi, MD',
      subTitle: 'Orthopedics',
      video: getShortInstructorVideoLink('murthi'),
      path: '/Instructor/murthi',
    },
    {
      id: 14,
      img: getInstructorCardPicture('cheung'),
      name: 'Emilie V. Cheung, MD, FAAOS',
      subTitle: 'Orthopedics',
      video: getShortInstructorVideoLink('cheung'),
      path: '/Instructor/cheung',
    },
    {
      id: 15,
      img: getInstructorCardPicture('abboud'),
      name: 'Joseph Abboud, MD',
      subTitle: 'Orthopedics',
      video: getShortInstructorVideoLink('abboud'),
      path: '/Instructor/abboud',
    },
    {
      id: 16,
      img: getInstructorCardPicture('grady'),
      name: "Chris O'Grady, MD",
      subTitle: 'Orthopedics',
      video: getShortInstructorVideoLink('grady'),
      path: '/Instructor/grady',
    },
  ],

  4: [
    {
      id: 17,
      img: getInstructorCardPicture('pandit'),
      name: 'Rajiv Pandit, MD, FACS',
      subTitle: 'ENT',
      video: getShortInstructorVideoLink('pandit'),
      path: '/Instructor/pandit',
    },
    {
      id: 18,
      img: getInstructorCardPicture('dean'),
      name: 'Marc Dean, MD',
      subTitle: 'ENT',
      video: getShortInstructorVideoLink('dean'),
      path: '/Instructor/murthi',
    },
  ],

  5: [
    {
      id: 19,
      img: getInstructorCardPicture('ramasamy'),
      name: 'Marc Dean, MD',
      subTitle: 'ENT',
      video: getShortInstructorVideoLink('ramasamy'),
      path: '/Instructor/ramasamy',
    },

    {
      id: 21,
      img: getInstructorCardPicture('carrion'),
      name: 'Rafael Carrion, MD',
      subTitle: 'Urology',
      video: getShortInstructorVideoLink('carrion'),
      path: '/Instructor/carrion',
    },
    {
      id: 22,
      img: getInstructorCardPicture('perito'),
      name: 'Paul Perito, MD, FACS',
      subTitle: 'Urology',
      video: getShortInstructorVideoLink('perito'),
      path: '/Instructor/perito',
    },
    {
      id: 23,
      img: getInstructorCardPicture('kow'),
      name: 'Nathan Kow, MD',
      subTitle: 'Urology',
      video: getShortInstructorVideoLink('kow'),
      path: '/Instructor/kow',
    },
  ],
}
