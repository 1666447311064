export const mloc145 = {
  id: 'MLOC-145',
  name: 'FIVE Labs',
  description:
    'The Foundation for Orthopaedic Research and Education (FORE) is proud to present Florida InnoVation and Education (FIVE) Labs. FIVE Labs Tampa is an innovative facility designed to educate the next generation of healthcare professionals and to foster creativity for the development of technologies for healthcare’s biggest challenges. The experienced team of professionals at FIVE Labs offer concierge-level service to ensure a seamless and successful event.    Since its inception in 2007, the surgical skills lab at FORE began as a small 4-station lab which later expanded to 20 stations as the need for larger events grew. As our educational offerings have continued to grow, our need for additional space and functionality also grew. Throughout our experience of conducting hundreds of events, we continuously sought our customers’ feedback in an effort to continuously improve. We took that experience and feedback and created a facility that allows us to expand our capabilities while at the same time improving the user experience for both healthcare and industry professionals. We strategically sought a more desirable location which included close proximity to the airport, the area’s best restaurants, and full-service hotels.',
  status: '10-Inactive (Sales/ES/SI)',
  address1: '4115 W. Spruce Street',
  address2: null,
  city: 'Tampa',
  state: 'FL',
  zip: '33607',
  category: 'Pilot',
  type: 'Bioskills Lab',
  latitude: '27.95995',
  longitude: '-82.51287',
  photo: 'mloc-145.png',
  speciality: 'Orthopedics',
}
