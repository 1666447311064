export const mloc127 = {
  id: 'MLOC-127',
  name: 'VIMA - Vitruvio Institute of Medical Advancement',
  description:
    'VIMA is a non-profit organization of leading doctors, researchers, and cross-industry experts working together to discover the future of medical care. VIMA members live and work at the forefront of scientific progress—connecting new technology to better treatments.   Founded by Marc Dean, MD, and Carolina Escobar, MD, VIMA was established to help leading medical minds work together by sharing cutting-edge research, exploring the latest technological advances, and collaborating across disciplines for better patient care.   VIMA doctors have led the way in integrating emerging technologies such as telemedicine into their practices. In doing so, they have brought better care to patients from Latin America to the Middle East—impacting thousands of lives around the globe.   VIMA doctors are pursuing new treatment possibilities by working directly with experts across industries. Past partnerships include NASA, where VIMA doctors researched and developed a robot for long-distance surgery. This groundbreaking research has opened the door for doctors to perform surgeries around the globe remotely.',
  status: '10-Inactive (Sales/ES/SI)',
  address1: '2100 Ross Avenue',
  address2: null,
  city: 'Dallas',
  state: 'TX',
  zip: '75201',
  category: 'Research & Development',
  type: 'Surgery Center',
  latitude: '32.7875',
  longitude: '-96.79756',
  photo: 'mloc-127.png',
  speciality: 'Endovascular',
}
