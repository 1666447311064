import { specialties } from '../../../utils/specialty'
import { cdnUrl } from '../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'
//Use this template to add more instructors
const data = [
  `Dr. Rajiv Pandit attended college at the University of Michigan in Ann Arbor as well as the University of Illinois in Urbana-Champaign. After obtaining his Bachelor’s degree he attended Rush Medical College in Chicago for four years to earn his M.D. This was followed by a two-year internship and residency program in General Surgery at Loyola University Medical Center in Maywood, Illinois. Dr. Pandit then spent another four years specializing in Otolaryngology – Head & Neck Surgery, before beginning his practice in Dallas in 2001 and becoming Board-Certified by the American Board of Otolaryngology.`,
  `He is also actively involved in teaching and administrative duties with the purpose of improving the patient care experience in both the clinic and hospital setting.`,
]
const specialty = specialties.otolaryngology.name
const id = 'pandit'
export const panditData = {
  firstName: 'Rajiv',
  lastName: 'Pandit',
  middleName: undefined,
  suffix: 'MD, FACS',
  id,
  title: 'RAJIV PANDIT, MD, FACS',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  shortVideoCard: getInstructorCardPicture(id),

  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
