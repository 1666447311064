import { React, useState } from 'react'
import BurgerMenu from './components/Header/burgerMenu'
import Header from './components/Header1'
import MobileHeader from './components/Header/MobileHeader'
import AwarenessPlatform from './components/AwarenessPlatform'
import Footer from './components/Footer/Footer'
import useWindowSize from './hooks/useWindowResize'
import SolutionHero from './components/SolutionHero'
import SolutionCard from './components/SolutionCard'
import InvertedSolutionCard from './components/InvertedSolutionCard'
import InvertedBgSolutionCard from './components/InvertedBgSolutionCard'
import Testimonial from './components/Testimonial'
import ImmersiveTraining from './components/immersiveTraining'
import MeetInstructor from './components/MeetInstructor/index'
import { useHomeCourselContext } from './providers/HomeCourselProvider'
import { cdnUrl } from './constants/enum'


function Solutions() {
  const [width] = useWindowSize()

  const [isHoveredForMobile, setIsHoveredForMobile] = useState(false)

  const { currentCover } = useHomeCourselContext()

  const handleOnMobileHoverEnter = () => {
    setIsHoveredForMobile(true)
  }

  const handleOnMobileHoverLeave = () => {
    setIsHoveredForMobile(false)
  }
  return (
    <div className="App">
      <BurgerMenu />
      {width > 900 ? <Header /> : <MobileHeader />}
      <SolutionHero />
      <SolutionCard
        title={'Step inside the OR from anywhere in real-time'}
        subtitle={'VR Experience'}
        Video={`${cdnUrl}/videos/solutions/SOLUTIONS_VR_EXPERIENCE.mp4`}
        description={
          'Our flagship product to give you the best possible experience for watching surgical procedures remotely.'
        }
        btnLink={'/vr-viewer'}
      />
      <InvertedSolutionCard />
      <SolutionCard
        title={'Watch recordings on-demand'}
        subtitle={'Recording Library'}
        Video={`${cdnUrl}/videos/solutions/SOLUTIONS_WATCH_RECORDINGS.mp4`}
        description={
          'Record, store, and replay surgical procedures on-demand in VR or Immersive 2D with our platform, ensuring optimal viewing experiences.'
        }
        btnLink={'/recording-library'}
      />
      <InvertedBgSolutionCard />
      <div style={{ background: '#E4E6ED', paddingTop: '64px' }}>
        <Testimonial />
      </div>
      <ImmersiveTraining img={'/immersiveTraining.png'} />
      <MeetInstructor
        instructors={currentCover}
        handleOnMobileHoverEnter={handleOnMobileHoverEnter}
        handleOnMobileHoverLeave={handleOnMobileHoverLeave}
      />
      <AwarenessPlatform />
      <Footer />
    </div>
  )
}

export default Solutions
