import React from 'react'
import styles from './useCases.module.scss'

function Usecase() {
  return (
    <div className={styles.container}>
      <div className={styles.subcontainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Use Cases</div>
          <div className={styles.details}>
            Immertec offers platform solutions to enhance professional education, sales, marketing,
            and R&D across organizations.
          </div>
        </div>

        <div className={styles.imageContainer}>
          <img src="/uc.png" alt="" />
          <div className={styles.textContainer}>
            <h2>Live case observations</h2>
            <p>
              Watch live surgeries remotely from anywhere using Immertec's VR and Immersive 2D
              solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Usecase
