import React, { useEffect, useRef } from 'react'
import styles from './customMobileTabs.module.scss'

import { customMobileTabsValue } from '../../../constants/data'

import { Swiper, SwiperSlide } from 'swiper/react'

function FocusOnSelect(props) {
  const mobileTabsRef = useRef(null)

  useEffect(() => {
    if (props.activeTab) {
      mobileTabsRef.current.swiper.slideToLoop(customMobileTabsValue[props.activeTab], 0)
    }
  }, [props.activeTab])

  return (
    <div id="homeCourselMTabs">
      <Swiper
        slidesPerView={'auto'}
        centeredSlides={true}
        loop={false}
        ref={mobileTabsRef}
        slideToClickedSlide={true}
        spaceBetween={10}
        freeMode={true}
      >
        {props.tabsForTeam?.map((item, index) => (
          <SwiperSlide key={`${item}_${index}`}>
            <div
              className={`${item === props.activeTab ? styles.activeTab : styles.tab}`}
              key={`${item}_${index}`}
              onClick={() => {
                props.onChangeTab(index, item)
              }}
            >
              {item}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default FocusOnSelect
