import React from 'react'
import styles from './customDropdown.module.scss'
import { ButtonBase } from '@mui/material'
import { Link } from 'react-router-dom'
import { ArrowDropDownSharp } from '@mui/icons-material'

function CustomDropDown({ optionsToMap, name = '', handleOnChange }) {
  return (
    <div className={styles.container}>
      <div className={styles.dropdown}>
        <div className={styles.DropdownContainer}>
          <div>
            <ButtonBase className={styles.btnShow}>{name}</ButtonBase>
          </div>
          <div>
            <ArrowDropDownSharp />
          </div>
        </div>
        <div className={styles.dropdownContent}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {optionsToMap.map((item, index) =>
              item.link ? (
                <Link to={`${item.link}`} key={`${item.title}_${index}`}>
                  <div className={styles.SubLinkContainer}>
                    <div className={styles.btn}>{item.title}</div>
                  </div>
                </Link>
              ) : (
                <div className={styles.SubLinkContainer} key={`${item.title}_${index}`}>
                  <Link to="/">
                    <div className={styles.btn} onClick={() => handleOnChange(index, item.value)}>
                      {item.title}
                    </div>
                  </Link>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomDropDown
