import React from 'react'
import styles from './AwesomeTeamSlider.module.scss'
import Card from './Card/Card'
import Slider from 'react-slick'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

let awesomeTeamData = [
  {
    id: 1,
    image: '/CareerPage/AwesomeTeamSlider1.png',
    mainText: 'Through 3 Stages of Growth, Startup Companies Leverage Tech to Thrive',
    link: 'https://biztechmagazine.com/article/2021/09/through-3-stages-growth-startup-companies-leverage-tech-thrive',
  },

  {
    id: 2,
    image: '/CareerPage/AwesomeTeamSlider2.png',
    mainText: 'Get to know Immertec, a 2021 Best Places to Work honoree',
    link: 'https://www.bizjournals.com/tampabay/news/2021/04/01/get-to-know-immertec-a-2021-best-places-to-work-h.html',
  },

  {
    id: 3,
    image: '/CareerPage/AwesomeTeamSlider3.png',
    mainText: 'After $6M raise, Immertec has assembled a leadership dream team...',
    link: 'https://www.bizjournals.com/tampabay/news/2020/08/10/after-6m-raise-immertec-has-assembled-a-leadersh.html',
  },

  {
    id: 4,
    image: '/CareerPage/AwesomeTeamSlider1.png',
    mainText: 'Through 3 Stages of Growth, Startup Companies Leverage Tech to Thrive',
    link: 'https://biztechmagazine.com/article/2021/09/through-3-stages-growth-startup-companies-leverage-tech-thrive',
  },

  {
    id: 5,
    image: '/CareerPage/AwesomeTeamSlider2.png',
    mainText: 'Get to know Immertec, a 2021 Best Places to Work honoree',
    link: 'https://www.bizjournals.com/tampabay/news/2021/04/01/get-to-know-immertec-a-2021-best-places-to-work-h.html',
  },

  {
    id: 6,
    image: '/CareerPage/AwesomeTeamSlider3.png',
    mainText: 'After $6M raise, Immertec has assembled a leadership dream team...',
    link: 'https://www.bizjournals.com/tampabay/news/2020/08/10/after-6m-raise-immertec-has-assembled-a-leadersh.html',
  },
]
function LeftArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerLeft} onClick={onClick}>
      <ChevronLeftIcon className={styles.ArrowFont} />
    </div>
  )
}

function RightArrow(props) {
  const { onClick } = props

  return (
    <div className={styles.ArrowContainerRight} onClick={onClick}>
      <ChevronRightIcon className={styles.ArrowFont} />
    </div>
  )
}

function AwesomeTeamSlider() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,

    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainText}>Our Team, Being Awesome</div>

      <div className={styles.mainSliderContainer}>
        <div className={styles.gradient1} />
        <div className={styles.gradient2} />
        <div className={styles.sliderContainer}>
          <Slider {...settings}>
            {awesomeTeamData.map((item, index) => (
              <Card key={`${item.mainText}_${index}`} item={item} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default AwesomeTeamSlider
