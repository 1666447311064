export const mloc458 = {
  id: 'MLOC-458',
  name: 'Legacy Meridian Park Medical Center',
  description:
    'Legacy Meridian Park Medical Center, formerly Legacy Meridian Park Hospital, is an acute care hospital in Tualatin, Oregon, United States. Opened in 1973, it is operated by Legacy Health. The facility is licensed for 150 beds, but operates only 128. Located in Clackamas County along the boundary with Washington County',
  status: '10-Inactive (Sales/ES/SI)',
  address1: '19300 Sw 65th Ave',
  address2: null,
  city: 'Tualatin',
  state: 'OR',
  zip: '97062',
  category: 'Commercial',
  type: 'Hospital',
  latitude: '45.37859',
  longitude: '-122.7407',
  photo: 'mloc-458.png',
  speciality: 'Interventional Spine',
}
