import { ArrowDropDownSharp } from '@mui/icons-material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom'
import './burgerMenu.css'
import styles from './BurgerMenu.module.scss'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  fontFamily: 'Gilroy-Medium',
  fontSize: '26px',
  color: '#ffffff',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: '#243746',
  flexDirection: 'row-reverse',
  fontFamily: 'Gilroy-Medium',
  fontSize: '26px',
  lineHeight: '0px',
  color: '#ffffff',
  padding: 0,
  minHeight: '30px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  background: '#243746',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: 20,
  paddingTop: 20,
}))

export default class BurgerMenu extends React.Component {
  state = {
    menuOpen: false,
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  render() {
    return (
      <Menu
        right={true}
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
      >
        <div className={styles.mainContainer}>
          <Link to="/">
            <div className={styles.logo}>
              <img src="/logoBM.png" />
            </div>
          </Link>
          <div className={styles.MenuItems}>
            <Link to="/solutions">
              <div className={styles.items}>Solutions</div>
            </Link>
            <div className={styles.divider}></div>
            <Accordion>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>About</div>
                  <ArrowDropDownSharp />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Link to="/science">
                  <div className={styles.subItems}>The Science</div>
                </Link>
                <Link to="/instructors">
                  <div className={styles.subItems}>Instructors</div>
                </Link>
                <Link to="/locations">
                  <div className={styles.subItems}>Locations</div>
                </Link>
                <Link to="/mab">
                  <div className={styles.subItems}>Medical Advisory Board</div>
                </Link>
                <Link to="/careers">
                  <div className={styles.subItems}>Careers</div>
                </Link>
                <Link to="/team">
                  <div className={styles.subItems}>Team</div>
                </Link>
              </AccordionDetails>
            </Accordion>
            <div className={styles.divider}></div>
          </div>
          <div className={styles.Mbutton}>
            <Link to="/request-a-demo">Request a Demo</Link>
          </div>
        </div>
      </Menu>
    )
  }
}
