import React from 'react'
import { Link } from 'react-router-dom'
import ContainedButton from '../ContainedButton'
import styles from './hoverOverlay.module.scss'

function HoverOverlay({
  onMuteClick,
  muted,
  path,
  onFullScreenClick,
  fullScreenSupport,
  visitProfile,
}) {
  return (
    <div className={styles.hoverContainer} onDoubleClick={onFullScreenClick}>
      <div className={styles.container}>
        {visitProfile && (
          <Link to={`${path}`}>
            {/* TODO: find the right CSS  */}
            <ContainedButton
              customStyles={{
                height: '41px',
                width: '150px',
                cursor: 'pointer',
                position: 'absolute',
                bottom: '19px',
                left: '20px',
                zIndex: 50,
              }}
              textStyles={{
                fontFamily: 'Gilroy-Medium',
                lineHeight: '24px',
                textAlign: 'center',
                color: ' #243746',
              }}
              label={'View Profile'}
            />
          </Link>
        )}
        {fullScreenSupport && (
          <img
            className={styles.fullScreenIcon}
            src={'/icons/fullScreenIcon.svg'}
            alt=""
            onClick={onFullScreenClick}
          />
        )}

        <img
          className={styles.muteIcon}
          src={muted ? '/unmute.svg' : '/muteIcon.svg'}
          alt=""
          onClick={onMuteClick}
        />
      </div>
    </div>
  )
}

export default HoverOverlay
