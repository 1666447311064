export const mloc1920 = {
  id: 'MLOC-1920',
  name: 'Abbott Neuromodulation HQ',
  description:
    'Abbott Neuromodulation has 21 principal operating and manufacturing facilities spanning 4 continents, with additional sales and distribution offices worldwide. They provide medical devices and technologies to people with chronic pain and movement disorders in more than 100 countries.',
  status: '05-Cart Install (SI)',
  address1: '8701 Bee Caves Road',
  address2: 'Building 2 West',
  city: 'Austin',
  state: 'TX',
  zip: '78746',
  category: 'Commercial',
  type: 'Company HQ Lab',
  latitude: 30.31681,
  longitude: -97.85972,
  photo: 'mloc-1920.png',
  speciality: 'Endovascular',
}
