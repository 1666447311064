import React from 'react'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import LeaderShipTeam from './components/LeaderShipTeam'
import MeetImmertecTeam from './components/MeetImmertecTeam/MeetImmertecTeam'
// import MemberDetails from './components/MemberDetails/MemberDetails'
import ImmensePower from './components/ImmensePower/ImmensePower'
import useWindowSize from './hooks/useWindowResize'
import MobileHeader from './components/Header/MobileHeader'
import BurgerMenu from './components/Header/burgerMenu'

function MemberPage({ children }) {
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const [width] = useWindowSize()

  return (
    <div>
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}
      <BurgerMenu />
      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      <MeetImmertecTeam />
      <LeaderShipTeam />
      {children}

      <ImmensePower />
      <Footer />
    </div>
  )
}

export default MemberPage
