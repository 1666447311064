export const mloc665 = {
  id: 'MLOC-665',
  name: 'Coral Gables Surgery Center',
  description:
    'Opened in 2003, equipped with the latest technology and experienced physicians and clinicians with over 17 years of experience.\nCoral Gables Surgery Center strives to be an extension of your physician practice. Contracted with over 50 managed care carriers, Medicare and Florida Medicaid.\nOur center is a high-quality, lower-cost alternative for outpatient multi-specialty surgical procedures. We service patients in Miami, Coral Gables, and surrounding areas. Our goal is to provide patients and physicians with unparalleled healthcare experience and positive outcomes.',
  status: '06-Event Prep (ES)',
  address1: '1097 S Le Jeune Rd',
  address2: '2nd floor',
  city: 'Coral Gables',
  state: 'FL',
  zip: '33134',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '25.76159',
  longitude: '-80.26282',
  photo: 'mloc-665.png',
  speciality: 'Otolaryngology',
}
