import { Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import styles from './customTabs.module.scss'

const AntTabs = styled(Tabs)({
  background: '#ffffff',
  //height: '4.6992481203007515vh',
  width: 'fit-content',
  'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.25)',
  'border-radius': '20px',
  display: 'flex',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  transition: 'all 0.2s ease',
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: ['-apple-system', 'Gilroy-Medium'].join(','),
  width: '10.885416666666666vw',
  height: '2.6041666666666665vw',
  transition: 'all 0.2s ease',

  cursor: 'pointer',
  'font-family': 'Gilroy-Bold',
  'font-size': '0.9375vw',
  'line-height': '1.09375vw',
  'text-transform': 'uppercase',
  color: '#000',
  border: 'none',
  background: '#fff',
  'min-width': '8.333333333333334vw',
  'text-align': 'center !important',

  '&.Mui-selected': {
    background: '#3B6CB3',
    borderRadius: '20px',
    color: '#fff',
    fontWeight: 'bold',
  },
  '&.Mui-focusVisible': {},

  '&.MuiTab-root:last-child': {
    borderRight: 'none',
  },
}))

function TabBar({ currentTab, tabs, onClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <AntTabs value={currentTab} onChange={onClick} aria-label="secondary tabs example">
          {tabs?.map((item, index) => (
            <AntTab key={`${item.label}_${index}`} value={item.value} label={item.label} />
          ))}
        </AntTabs>
      </div>
    </div>
  )
}

export default TabBar
