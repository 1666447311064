import React from 'react'
import styles from './PolicyText.module.scss'

function PolicyText() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.Heading}>Privacy Policy</div>
      <div className={styles.textContainer}>
        <div className={styles.mainText}>IMMERSIVE TECH INC</div>
        <div className={styles.mainText}>Last Updated: January 30, 2024</div>
        <p className={styles.subText}>
          This Privacy Policy explains how Immersive Tech Inc.
          <strong> (“Immertec“)</strong>
          &nbsp; collects, uses, and discloses information about you through its website, mobile
          applications, VR Applications and other online products and services (collectively, the{' '}
          <strong>“Services“</strong>). This coverage includes VR Applications published on the Meta 
          Quest App Store and on other platforms, ensuring comprehensive protection of user data across 
          all our services. Our commitment to privacy extends across all platforms where our services are 
          accessed, emphasizing our dedication to the security and privacy of our users' information in 
          accordance with the highest industry standards.
          <br />
          <br />
          We may change this Privacy Policy from time to time. If we make changes, we will notify
          you by revising the date at the top of the policy and, in some cases, we may provide you
          with additional notice (such as adding a statement to our homepage or sending you a
          notification). We encourage you to review the Privacy Policy whenever you access the
          Services or otherwise interact with us to stay informed about our information practices
          and the choices available to you.
        </p>

        <div className={styles.mainText}>Collection of Information</div>
        <div className={styles.mainText}>Information You Provide to Us</div>
        <p className={styles.subText}>
          When you open an account and register for the Services, we ask you to provide certain
          information such as your name, email address, phone number, and institution with which you
          are employed or affiliated. We also collect information when you participate in any
          interactive features of the Services, fill out a form, participate in a contest or
          promotion, make a purchase, apply for a job, request customer support, or otherwise
          communicate with us.
        </p>

        <div className={styles.mainText}>Information About Your Use of the Services</div>
        <div className={styles.mainText}>Automatically Collected Information</div>
        <p className={styles.subText}>
          When you access or use our Services, we automatically collect information about you,
          including:
        </p>
        <ul style={{ margin: '30px' }}>
          <li>
            <strong>Log Information: </strong> We collect log information about your use of the
            Services, including the type of browser you use, app version, access times, pages
            viewed, your IP address and the page you visited before navigating to our Services.
          </li>

          <li>
            <strong>Device Information: </strong> We collect information about the computer or
            mobile device you use to access our Services, including the hardware model, operating
            system and version, unique device identifiers, and mobile network information.
          </li>

          <li>
            <strong>Location Information: </strong> In accordance with your device permissions, we
            may collect information about the precise location of your device. For more details,
            please see “Your Choices” below.
          </li>

          <li>
            <strong>Information Collected by Cookies and Other Tracking Technologies:</strong>
            We use different technologies to collect information, including cookies and web beacons.
            Cookies are small data files stored on your hard drive or in device memory that help us
            improve our Services and your experience, see which areas and features of our Services
            are popular and count visits
          </li>

          <li>
            Web beacons are electronic images that may be used in our Services or emails and help
            deliver cookies, count visits and understand usage and campaign effectiveness. For more
            information about cookies and how to disable them, please see “Your Choices” below.
          </li>
        </ul>

        <div className={styles.mainText}>Information We Collect from Other Sources</div>
        <p className={styles.subText}>
          We may obtain information from other sources and combine that with information we collect
          through our Services. For example, we may collect information about you from third
          parties, including but not limited to IP address, email, names, and contact information.
        </p>

        <div className={styles.mainText}>Use of Information</div>

        <p className={styles.subText}>
          We use the information we collect to provide, maintain, and improve our Services. We may
          also use the information we collect to:
        </p>
        <ul style={{ margin: '30px' }}>
          <li>
            Send you technical notices, updates, security alerts, and support and administrative
            messages and to respond to your comments, questions and customer service requests;
            Communicate with you about products, services, offers, and events offered by Immertec
            and others, and provide news and information we think will be of interest to you;
          </li>
          <li>
            Communicate with you about products, services, offers, and events offered by Immertec
            and others, and provide news and information we think will be of interest to you;
          </li>

          <li>
            Monitor and analyze trends, usage, and activities in connection with our Services;
          </li>

          <li>
            Detect, investigate and prevent fraudulent transactions and other illegal activities and
            protect the rights and property of Immertec and others;
          </li>

          <li>
            Personalize and improve the Services and provide advertisements, content or features
            that match user profiles or interests;
          </li>

          <li>
            Facilitate contests, sweepstakes and promotions and process and deliver entries and
            rewards;
          </li>

          <li>Comply with our legal obligations; and</li>

          <li>
            Enforce, or otherwise use in connection with, any terms governing the use of our
            Services.
          </li>
        </ul>

        <div className={styles.mainText}>Sharing of Information</div>

        <p className={styles.subText}>
          We may share information about you as follows or as otherwise described in this Privacy
          Policy:
        </p>
        <ul style={{ margin: '30px' }}>
          <li>
            With vendors, consultants and other service providers who need access to such
            information to carry out work on our behalf;
          </li>

          <li>
            In response to a request for information if we believe disclosure is in accordance with
            or required by, any applicable law or legal process, including lawful requests by public
            authorities to meet national security or law enforcement requirements;
          </li>

          <li>
            If we believe your actions are inconsistent with our user agreements or policies, or to
            protect the rights, property, and safety of Immertec or others;
          </li>

          <li>With other users of our Services with your consent;</li>

          <li>
            In connection with, or during negotiations of, any merger, sale of company assets,
            financing or acquisition of all or a portion of our business by another company;
          </li>

          <li>
            Between and among Immertec and our current and future parents, affiliates, subsidiaries
            and other companies under common control and ownership; and
          </li>

          <li>With your consent or at your direction.</li>
        </ul>

        <p className={styles.subText}>
          We may also share aggregated or de-identified information, which cannot reasonably be used
          to identify you.
        </p>

        <div className={styles.mainText}>Services Provided by Others</div>
        <div className={styles.mainText}>Advertising And Analytics Services Provided By Others</div>
        <p className={styles.subText}>
          We use Google Analytics and may allow others to provide analytics services and serve
          advertisements on our behalf across the internet and in applications. These entities may
          use cookies, web beacons, device identifiers and other technologies to collect information
          about your use of the Services and other websites and applications, including your IP
          address, web browser, mobile network information, pages viewed, time spent on pages or in
          apps, links clicked and conversion information. This information may be used by Immertec
          and others to, among other things, analyze and track data, determine the popularity of
          certain content, deliver advertising and content targeted to your interests on our
          Services and other websites and better understand your online activity. For more
          information about how Google uses data when you use our Services, see
          <a href="www.google.com/policies/privacy/partners/">
            www.google.com/policies/privacy/partners/
          </a>
          . For more information about interest-based ads, or to opt out of having your web browsing
          information used for behavioral advertising purposes, please visit{' '}
          <a href="www.aboutads.info/choices">www.aboutads.info/choices </a>. Your device may also
          include a feature (“Limit Ad Tracking” on iOS or “Opt Out of Interest-Based Ads” or “Opt
          Out of Ads Personalization” on Android) that allows you to opt out of having certain
          information collected through apps used for behavioral advertising purposes.
        </p>

        <div className={styles.mainText}>Data Retention</div>
        <p className={styles.subText}>
          We store the information we collect about you for as long as is necessary for the
          purpose(s) for which we originally collected it. We may retain certain information for
          legitimate business purposes or as required by law.
        </p>

        <div className={styles.mainText}>Transfer of Information</div>
        <div className={styles.mainText}>
          Transfer Of Information To The U.S. And Other Countries
        </div>
        <p className={styles.subText}>
          Immertec is based in the United States and we process and store information in the U.S. As
          such, we and our service providers may transfer your information to, or store or access it
          in, jurisdictions that may not provide equivalent levels of data protection as your home
          jurisdiction. We will take steps to ensure that your personal data receives an adequate
          level of protection in the jurisdictions in which we process it.
        </p>

        <div className={styles.mainText}>EEA Residents</div>
        <div className={styles.mainText}>Residents Of The European Economic Area</div>
        <p className={styles.subText}>
          If you are a resident of the European Economic Area <strong>(“EEA”)</strong>, you have
          certain rights and protections under the law regarding the processing of your personal
          data
        </p>
        <div className={styles.mainText}>Legal Basis For Processing</div>
        <p className={styles.subText}>
          If you are a resident of the EEA, when we process your personal data we will only do so
          when we have a legitimate interest in processing your personal data. For example, we may
          process your personal data to send you marketing communications, to communicate with you
          about changes to our Services, and to provide, secure, and improve our Services.
        </p>

        <div className={styles.mainText}>Data Subject Requests</div>
        <p className={styles.subText}>
          If you are a resident of the EEA, you have the right to access personal data we hold about
          you and to ask that your personal data be corrected, erased, or transferred. You may also
          have the right to object to, or request that we restrict, certain processing. If you would
          like to exercise any of these rights, you can log into your Immertec account.
          Alternatively, you may contact us as indicated below.
        </p>

        <div className={styles.mainText}>Questions Or Complaints</div>
        <p className={styles.subText}>
          If you are a resident of the EEA and have a concern about our processing of personal data
          that we are not able to resolve, you have the right to lodge a complaint with the data
          privacy authority where you reside. For the contact details of your local Data Protection
          Authority,
          <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">
            please see Article 29 Working Party.
          </a>
        </p>

        <div className={styles.mainText}>Your Choices</div>
        <div className={styles.mainText}>Account Information</div>
        <p className={styles.subText}>
          You may update, correct or delete information about you at any time by logging into your
          online account or emailing us at support@immertec.com. If you wish to delete or deactivate
          your account, please email us at support@immertec.com, but note that we may retain certain
          information as required by law or for legitimate business purposes. We may also retain
          cached or archived copies of information about you for a certain period of time.
        </p>

        <div className={styles.mainText}>Location Information</div>
        <p className={styles.subText}>
          When you first launch any of our mobile applications that collect location information,
          you will be asked to consent to the application’s collection of this information. We
          currently require this location information to use our Services, so if you do not consent
          to this collection, you cannot use our Services. If you initially consent to our
          collection of location information, you can subsequently stop the collection of this
          information at any time by changing the preferences on your mobile device. If you do so,
          our mobile applications, or certain features thereof, will no longer function. You may
          also stop our collection of location information by following the standard uninstall
          process to remove all of our mobile applications from your device.
        </p>

        <div className={styles.mainText}>Cookies</div>
        <p className={styles.subText}>
          Most web browsers are set to accept cookies by default. If you prefer, you can usually
          choose to set your browser to remove or reject browser cookies. Please note that if you
          choose to remove or reject cookies, this could affect the availability and functionality
          of our Services.
        </p>

        <div className={styles.mainText}>Promotional Communications</div>
        <p className={styles.subText}>
          You may opt-out of receiving promotional emails from Immertec by following the
          instructions in those emails. If you opt-out, we may still send you non-promotional emails
          text messages, such as those about your account or our ongoing business relations.
        </p>

        <div className={styles.mainText}>Mobile Push Notifications/Alerts</div>
        <p className={styles.subText}>
          With your consent, we may send promotional and non-promotional push notifications or
          alerts to your mobile device. You can deactivate these messages at any time by changing
          the notification settings on your mobile device.
        </p>

        <div className={styles.mainText}>Do Not Track Notice</div>
        <p className={styles.subText}>
          Do Not Track <strong>(“DNT”) </strong> is a preference available in many web browsers
          which allows you to inform websites you visit that you do not want them collecting
          information about you. Immertec does not respond to DNT signals. For further details
          regarding DNT, visit donottrack.us.
        </p>

        <div className={styles.mainText}>Contact Us</div>
        <p className={styles.subText}>
          If you have any questions about this Privacy Policy, please contact us at:
          <a href="mailto:privacy@immertec.com?subject=Immertec%20Privacy%20Policy">
            {' '}
            privacy@immertec.com
          </a>
        </p>
      </div>
    </div>
  )
}

export default PolicyText
