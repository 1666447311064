export const mloc136 = {
  id: 'MLOC-136',
  name: 'INOV8 Orthopedics',
  description:
    'The goal of INOV8 Orthopedics is to create an innovative, advanced, and efficient surgical program that takes advantage of the latest medical technology and best practices in management to create superior outcomes for all stakeholders. In addition to driving superior outcomes, the program will also lead the industry in the efficient delivery of advanced medical care through the employment of best practices in medicine that reduce waste and associated costs. The opportunity is clear: the demand for clinical surgical care is increasing and there is a pressure to innovate to improve outcomes, patient experience, and maximize the utilization of current healthcare spend.',
  status: '10-Inactive (Sales/ES/SI)',
  address1: '10496 Katy Freeway',
  address2: null,
  city: 'Houston',
  state: 'TX',
  zip: '77043',
  category: 'Commercial',
  type: 'Surgery Center',
  latitude: '29.78798',
  longitude: '-95.55664',
  photo: 'mloc-136.png',
  speciality: 'Endovascular',
}
