import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorEvents,
  getInstructorCardPicture,
} from '../../../utils/getAssetLink'
const data = [
  ` Dr. Carrion's subspecialties are Sexual Medicine, Neurourology, Peyronie's Disease, Male Health, and General Urology. He is an active member in over 8 professional organizations, including the American Urological Association, Sexual Medicine Society of North America, and the Florida Urological Society. He has held and continues to hold important officer positions in several of these organizations. He has also been the recipient of several awards including the “AUA young leadership award.`,
  `Dr. Carrion has been very productive in his scientific research with more than 40 original peer-reviewed published manuscripts. He has over 75 abstracts to his credit which have been presented in local, regional, and international congresses. Dr. Carrion also has participated in Surgical Training symposiums both nationally and internationally. He has also hosted over 25 live surgery workshops at the University of South Florida with or without an adjunct cadaveric lab component.`,
]

const specialty = specialties.urology.name
const id = 'carrion'
export const carrionData = {
  firstName: 'Rafael',
  lastName: 'Carrion',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'Rafael Carrion, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileLink: `/Instructor/${id}`,
  shortVideoCard: getInstructorCardPicture(id),

  profileCover: getInstructorProfileCover(id),
  events: getInstructorEvents(id),
}
