import React from 'react'
import styles from './ResearchCard.module.scss'
function ResearchCard({ card }) {
  return (
    <div className={styles.bottomSec}>
      <div className={styles.leftContainer}>
        <img className={styles.image} src={card.image} />
        <div className={styles.leftContainer_imageText}>{card.imageText}</div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.rightContainerSubText}>{card.subText}</div>
      </div>
    </div>
  )
}

export default ResearchCard
