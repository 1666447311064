import React from 'react'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import ImmensePower from './components/ImmensePower/ImmensePower'
import LeaderShipTeam from './components/LeaderShipTeam'
import MeetImmertecTeam from './components/MeetImmertecTeam/MeetImmertecTeam'
import useCheckMobileScreen from './utils/getPlatform.js'
import useWindowSize from './hooks/useWindowResize'
import BurgerMenu from './components/Header/burgerMenu'
import MobileHeader from './components/Header/MobileHeader'

function TeamsPage() {
  const handleOnHeaderSpecialtyTabClick = (_event, title) => {
    window.localStorage.setItem('specialty', title)
  }
  const platform = useCheckMobileScreen()
  const [width] = useWindowSize()

  return (
    <div>
      <BurgerMenu />
      {width > 900 ? <Header disabled={true} /> : <MobileHeader />}

      {/* <Header handleOnChange={handleOnHeaderSpecialtyTabClick} /> */}
      <MeetImmertecTeam />
      <LeaderShipTeam />
      <ImmensePower />

      <Footer />
    </div>
  )
}

export default TeamsPage
