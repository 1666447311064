export const mloc55 = {
  id: 'MLOC-55',
  name: 'BAC-Labs',
  description:
    'The Broward Anatomical Center and Labs (BAC-Labs) located in Fort Lauderdale, FL is a state-of-the-art facility for virtual education, product development, research and testing. BAC-Labs cadaver training allows industry partners to study a life size model which allows hands-on experience and training. At BAC-Labs, we incubate new concepts and apply the latest technologies for healthcare professionals.',
  status: '07-Event Ready (ES)',
  address1: '3000 Bayview Drive',
  address2: 'Suite 200',
  city: 'Fort Lauderdale',
  state: 'FL',
  zip: '33306',
  category: 'Pilot',
  type: 'Bioskills Lab',
  latitude: '26.16608',
  longitude: '-80.10893',
  photo: 'mloc-55.png',
  speciality: 'Endovascular',
}
