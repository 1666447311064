import React, { useState } from 'react'
import styles from './grid.module.scss'

function grid() {
  const [displayDetails, setDisplayDetails] = useState(false)

  const handleOnHover = () => {
    setDisplayDetails(!displayDetails)
  }

  return (
    <div className={styles.container} onMouseEnter={handleOnHover} onMouseLeave={handleOnHover}>
      <div className={styles.pulseButton}>
        <img src={'/grid.png'} alt="" />
      </div>
      <div
        className={`${styles.detailsContainer} ${
          displayDetails ? styles.diplay : styles.displayNone
        }`}
      >
        <span className={styles.iconContainer}>
          <img src={'/grid.png'} alt="" />
        </span>
        <div className={styles.highlightText}>Immersive 2D</div>
        <div className={styles.detailsText}>Immersive 180-degree view of the Operating Room</div>
      </div>
    </div>
  )
}

export default grid
