import React from 'react'
import styles from './ContainedButton.module.scss'
import { ButtonBase } from '@mui/material'

function ContainedButton(props) {
  const { customStyles, textStyles, label, handleClick, background } = props
  return (
    <ButtonBase
      variant={'contained'}
      className={styles.container}
      onClick={handleClick}
      style={{
        ...customStyles,
        ...textStyles,
        background: background ?? '#47d7ac',
        borderRadius: '8px',
      }}
    >
      {label}
    </ButtonBase>
  )
}

export default ContainedButton
