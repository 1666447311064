import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstructorCardPicture,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorEvents,
} from '../../../utils/getAssetLink'
const data = [
  `Nathan Kow, MD, is an Urogynecologist who cares for women with complex pelvic floor issues in the Orlando, Florida area. As your physician, it's his goal to help end your discomfort and restore joy in your life.`,
  `He treats many conditions, including pelvic organ prolapse, urinary incontinence, fecal incontinence, vesicovaginal and rectovaginal fistulas, disorders of the lower urinary tract and mesh complications - and uses both non-surgical and surgical options including vaginal, laparoscopic and robotic pelvic reconstructive procedures.`,
  `After graduating from Emory University, Dr. Kow earned his medical degree from Mercer University School of Medicine in Georgia and completed an obstetrics and gynecology residency at Tufts Medical Center in Boston and a three-year fellowship in female pelvic medicine and reconstructive surgery at the Cleveland Clinic in Ohio.`,
]

const specialty = specialties.urology.name
const id = 'kow'
export const kowData = {
  firstName: 'Nathan',
  lastName: 'Kow',
  middleName: undefined,
  suffix: 'MD',
  // Not using actual web link. to be used when we dynamically pull instructors.
  id,
  title: 'Nathan Kow, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  profileLink: `/Instructor/${id}`,
  shortVideoCard: getInstructorCardPicture(id),

  events: getInstructorEvents(id),
}
