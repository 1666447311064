import { specialties } from '../../../utils/specialty'
import { cdnUrl } from '../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorProfileCover,
  getInstructorCardPicture,
  getInstructorEvents,
} from '../../../utils/getAssetLink'

const data = [
  `Dr. Tyler G. Smith is a fellowship-trained and board-certified orthopedic spine surgeon. He is graduated from the University of Nevada School of Medicine, Reno, NV. Dr. Tyler is the Founder of the Sierra Spine Institute. He focuses his practice exclusively on evaluating and treating conditions of the Spine in adults and adolescents. Dr. Tyler is committed to improving and maintaining your spinal health with a comprehensive understanding of your condition and leading-edge innovations in treatment.`,
]
const specialty = specialties.spine.name
const id = 'smith'
export const smithData = {
  firstName: 'Tyler',
  lastName: 'Smith',
  middleName: undefined,
  suffix: 'MD',
  id,
  title: 'TYLER SMITH, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  profileCover: getInstructorProfileCover(id),
  category: specialty,
  shortVideoCard: getInstructorCardPicture(id),

  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
