import { specialties } from './../../../utils/specialty'
import { cdnUrl } from './../../enum'
import {
  getShortInstructorVideoLink,
  getInstuctorHomePageVideoCover,
  getHeroImageOfInstructor,
  getInstuctorProgressiveImage,
  getLongInstructorVideoLink,
  getInstructorCardPicture,
  getInstructorProfileCover,
  getInstructorEvents,
} from '../../../utils/getAssetLink'

const data = [
  'Dr. Harry L. Shufflebarger has over 30 years of experience in pediatric spinal surgery.  He received his medical degree at Emory University, in Atlanta, Georgia and then completed his internship and residency in orthopedic surgery at Emory affiliated hospitals. He stays involved with innovation, both in the non-operative and operative treatment of pediatric spinal conditions. His contributions in the area of spinal instrumentation have been significant and continuous for over two decades. His notable accomplishments include Professor of Orthopedic Surgery and Neurosurgery at the University of Miami. He is board certified from the American Board of Orthopedic Surgery and is a member of the American Academy of Orthopedic Surgeons, the Pediatric Orthopedic Society of North America, and the Scoliosis Research Society. He is Past President of the Scoliosis Research Society, and received their Lifetime Achievement Award. Dr. Shufflebarger was a founding member of the very prestigious Harms Study Group, a national and international leader in research in pediatric spinal deformity, where he serves on the Executive Committee. Dr. Shufflebarger’s passion for research has led to his high level of influence in his field. He’s the author of 20 textbook chapters, over 50 peer-reviewed spinal publications, has been published by abstract in over 200 instances, acted as visiting professor at over 100 institutions around the world, and has participated in over 150 spinal courses and conferences. After leaving his position as Chief of Orthopedic Surgery and then Chief of Spinal Surgery at Nicklaus Children’s Hospital (previously Miami Children’s), he now practices at the prestigious Paley Orthopedic and Spine Institute.',
]
const specialty = specialties.spine.name
const id = 'shufflebarger'
export const shufflebargerData = {
  firstName: 'Harry',
  lastName: 'Shufflebarger',
  middleName: undefined,
  suffix: undefined,
  id,
  title: 'HARRY SHUFFLEBARGER, MD',
  heroImage: getHeroImageOfInstructor(specialty, id),
  instructor_specialties: specialties[specialty].label,
  description: data,
  quote: undefined,
  progressiveImage: getInstuctorProgressiveImage(specialties[specialty].label, id),
  shortVideoLink: getShortInstructorVideoLink(specialty, id),
  longVideoLink: getLongInstructorVideoLink(specialty, id),
  videoCover: getInstuctorHomePageVideoCover(id),
  category: specialty,
  profileCover: getInstructorProfileCover(id),
  shortVideoCard: getInstructorCardPicture(id),

  profileLink: `/Instructor/${id}`,
  events: getInstructorEvents(id),
}
