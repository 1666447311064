import React from 'react'
import styles from './GoldenStandard.module.scss'
function GoldenStandard() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.textContainer}>
          <div className={styles.mainText}>User Feedback & Qualitative Results</div>
          <div className={styles.subText}>
            User feedback is an integral component in understanding how Immertec’s technology can
            continue to provide an effective experience for surgeons.
            <br /> <br />
            Feedback from our users allows us to continually improve our product to provide
            customers with the learning outcomes they expect.
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <img src="GoldenStandard.png" />
      </div>
    </div>
  )
}

export default GoldenStandard
